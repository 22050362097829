<template>
  <!-- <div> -->
    <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名(屋号)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('companyName')},
                {'md-valid': !errors.has('companyName') && touched.companyName}]">
                <md-input
                  v-model="companyName"
                  data-vv-name="companyName"
                  type="text"
                  v-validate= "modelValidations.companyName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者(姓)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastName')},
                {'md-valid': !errors.has('lastName') && touched.lastName}]">
                <md-input
                  v-model="lastName"
                  data-vv-name="lastName"
                  type="text"
                  v-validate= "modelValidations.lastName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastName')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者(名)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstName')},
                {'md-valid': !errors.has('firstName') && touched.firstName}]">
                <md-input
                  v-model="firstName"
                  data-vv-name="firstName"
                  type="text"
                  v-validate= "modelValidations.firstName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstName')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者(セイ)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastKana')},
                {'md-valid': !errors.has('lastKana') && touched.lastKana}]">
                <md-input
                  v-model="lastKana"
                  data-vv-name="lastKana"
                  type="text"
                  v-validate= "modelValidations.lastKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastKana')">【必須】16文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastKana') && touched.lastKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者(メイ)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstKana')},
                {'md-valid': !errors.has('firstKana') && touched.firstKana}]">
                <md-input
                  v-model="firstKana"
                  data-vv-name="firstKana"
                  type="text"
                  v-validate= "modelValidations.firstKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstKana')">【必須】16文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstKana') && touched.firstKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              創業年度
            </label>
            <div class="md-layout-item md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="foundingYear"
                :language="ja"
                data-vv-name="foundingYear"
                v-validate= "modelValidations.foundingYear"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="foundingYear = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('foundingYear')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('foundingYear')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('foundingYear') && touched.foundingYear">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              郵便番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('companyZip')},
                {'md-valid': !errors.has('tel') && touched.companyZip}]">
                <md-input
                  v-model="companyZip"
                  data-vv-name="companyZip"
                  type="number"
                  v-validate= "modelValidations.companyZip"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyZip')">【必須】それぞれ7桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyZip')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyZip') && touched.companyZip" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="mt8 md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              都道府県
            </label>
            <div class="md-layout-item md-size-20">
              <md-field :class="[
                {'md-error': errors.has('companyPrefecture')},
                {'md-valid': !errors.has('companyPrefecture') && touched.companyPrefecture}]">
                <md-select
                  v-model="companyPrefecture"
                  data-vv-name="companyPrefecture"
                  v-validate= "modelValidations.companyPrefecture"
                  required>
                  <md-option value="北海道">北海道</md-option>
                  <md-option value="青森県">青森県</md-option>
                  <md-option value="岩手県">岩手県</md-option>
                  <md-option value="宮城県">宮城県</md-option>
                  <md-option value="秋田県">秋田県</md-option>
                  <md-option value="山形県">山形県</md-option>
                  <md-option value="福島県">福島県</md-option>
                  <md-option value="茨城県">茨城県</md-option>
                  <md-option value="栃木県">栃木県</md-option>
                  <md-option value="群馬県">群馬県</md-option>
                  <md-option value="埼玉県">埼玉県</md-option>
                  <md-option value="千葉県">千葉県</md-option>
                  <md-option value="東京都">東京都</md-option>
                  <md-option value="神奈川県">神奈川県</md-option>
                  <md-option value="新潟県">新潟県</md-option>
                  <md-option value="富山県">富山県</md-option>
                  <md-option value="石川県">石川県</md-option>
                  <md-option value="福井県">福井県</md-option>
                  <md-option value="山梨県">山梨県</md-option>
                  <md-option value="長野県">長野県</md-option>
                  <md-option value="岐阜県">岐阜県</md-option>
                  <md-option value="静岡県">静岡県</md-option>
                  <md-option value="愛知県">愛知県</md-option>
                  <md-option value="三重県">三重県</md-option>
                  <md-option value="滋賀県">滋賀県</md-option>
                  <md-option value="京都府">京都府</md-option>
                  <md-option value="大阪府">大阪府</md-option>
                  <md-option value="兵庫県">兵庫県</md-option>
                  <md-option value="奈良県">奈良県</md-option>
                  <md-option value="和歌山県">和歌山県</md-option>
                  <md-option value="鳥取県">鳥取県</md-option>
                  <md-option value="島根県">島根県</md-option>
                  <md-option value="岡山県">岡山県</md-option>
                  <md-option value="広島県">広島県</md-option>
                  <md-option value="山口県">山口県</md-option>
                  <md-option value="徳島県">徳島県</md-option>
                  <md-option value="香川県">香川県</md-option>
                  <md-option value="愛媛県">愛媛県</md-option>
                  <md-option value="高知県">高知県</md-option>
                  <md-option value="福岡県">福岡県</md-option>
                  <md-option value="佐賀県">佐賀県</md-option>
                  <md-option value="長崎県">長崎県</md-option>
                  <md-option value="熊本県">熊本県</md-option>
                  <md-option value="大分県">大分県</md-option>
                  <md-option value="宮崎県">宮崎県</md-option>
                  <md-option value="鹿児島県">鹿児島県</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyPrefecture')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyPrefecture')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyPrefecture') && touched.companyPrefecture" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              住所
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('companyAddress')},
                {'md-valid': !errors.has('companyAddress') && touched.companyAddress}]">
                <md-input
                  v-model="companyAddress"
                  data-vv-name="companyAddress"
                  type="text"
                  v-validate= "modelValidations.companyAddress"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyAddress')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyAddress')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyAddress') && touched.companyAddress">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          </div>

          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              営業内容
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('businessContent')},
                {'md-valid': !errors.has('businessContent') && touched.businessContent}]">
                <md-input
                  v-model="businessContent"
                  data-vv-name="businessContent"
                  type="text"
                  v-validate= "modelValidations.businessContent"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('businessContent')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('businessContent')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('businessContent') && touched.businessContent">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          </md-card-actions>

        </md-card-content>
        
      </div>
    </transition>
    </md-card>
  <!-- </div> -->
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        companyName: '',
        lastName: '',
        firstName: '',
        lastKana: '',
        firstKana: '',
        foundingYear: '',
        companyZip: '',
        companyPrefecture: '',
        companyAddress: '',
        businessContent: '',
        touched: {
          companyName: false,
          lastName: false,
          firstName: false,
          lastKana: false,
          firstKana: false,
          foundingYear: false,
          companyZip: false,
          companyPrefecture: false,
          companyAddress: false,
          businessContent: false
        },
        modelValidations: {
          companyName: {
            max: 255,
            required: true
          },
          lastName:{
            max: 16,
            required: true,
          },
          firstName:{
            max: 16,
            required: true,
          },
          lastKana:{
            max: 16,
            required: true,
          },
          firstKana:{
            max: 16,
            required: true,
          },
          companyZip:{
            max: 7,
            required: true,
          },
          companyPrefecture:{
            required: true,
          },
          companyAddress:{
            max: 50,
            required: true,
          },
          businessContent:{
            max: 255,
            required: true,
          }
        },
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    methods: {
      getUserDetails () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.companyName = response.data.company_name
          this.lastName = response.data.last_name
          this.firstName = response.data.first_name
          this.lastKana = response.data.last_name_kana
          this.firstKana = response.data.first_name_kana 
          if (response.data.founding_year !== null) {
            this.foundingYear = this.dateDivider(response.data.founding_year)[0]
          }
          this.companyZip = response.data.company_zip
          this.companyPrefecture = response.data.company_prefecture
          this.companyAddress = response.data.company_address
          this.businessContent = response.data.business_content
          this.detailBgFlag = true
          this.detailFlag = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          user: {
            id: this.id,
            company_name: this.companyName,
            last_name: this.lastName,
            first_name: this.firstName,
            last_name_kana: this.lastKana,
            first_name_kana: this.firstKana,
            founding_year: this.foundingYear,
            company_zip: this.companyZip,
            company_prefecture: this.companyPrefecture,
            company_address: this.companyAddress,
            business_content: this.businessContent,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/users/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`会員情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の更新に失敗しました')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    },
    watch: {
      companyName(){
        this.touched.companyName = true
      },
      lastName(){
        this.touched.lastName = true
      },
      firstName(){
        this.touched.firstName = true
      },
      lastKana(){
        this.touched.lastKana = true
      },
      firstKana(){
        this.touched.firstKana = true
      },
      foundingYear(){
        this.touched.foundingYear = true
      },
      companyZip(){
        this.touched.companyZip = true
      },
      companyPrefecture(){
        this.touched.companyPrefecture = true
      },
      companyAddress(){
        this.touched.companyAddress = true
      },
      businessContent(){
        this.touched.businessContent = true
      }
    }
  }
</script>
<style scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.formLayout{
  flex-basis: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 12px !important;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.md-form-label {
  padding: 10px 5px 0 0 !important;
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.md-select-value {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.mL80 {
  margin-left: 110px !important;
}

.mt60{
  margin-top: 60px;
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>