<template>
  <time-line type="timeline-inverted" ref="messages">
      <time-line-item v-for="message in messages" :key="message.id"
      :inverted="message.message_type == 'AdminUser'">
        <template v-slot:header>　</template>
        <template v-slot:content>
          <p v-html="message.content"></p>
          <template v-if="message.file.thumb.url">
            <hr>
            <object class="chat-file-object" :data="message.file.thumb.url"></object>
            <a :href="message.file.url" target="_blank" rel="noopener noreferrer">
              ファイルを確認
            </a>
          </template>
        </template>
        <template v-slot:footer>
          <span v-if="message.message_type == 'AdminUser'" class="read">{{ message.read == 'active' ? "既読" : "未読" }}</span>
          <span :class="[{'post-datetime': message.message_type == 'AdminUser'}, {'inverted-post-datetime': !(message.message_type == 'AdminUser')}]">{{ formatCreatedAt(message.created_at) }}</span>
          <span :class="[{'post-user-name': message.message_type == 'AdminUser'}, {'inverted-post-user-name': !(message.message_type == 'AdminUser')}]">{{ message.post_user_name }}</span>
        </template>
        <template v-slot:button>
          <div class="card-icon">
            <md-icon v-if="message.message_type == 'AdminUser'" @click.native="childDeleteMessage(message.id)" class="delete-button fa fa-trash-o"></md-icon>
          </div>
        </template>
      </time-line-item>
  </time-line>
</template>

<script>
import TimeLine from '../Timeline/TimeLine.vue'
import TimeLineItem from '../Timeline/TimeLineItem.vue'
export default {
  components: {
   TimeLine,
   TimeLineItem,
  },
  props: {
    messages : {
      type: Array,
    },
     deleteMessage: {
       type: Function,
       required: true,
     }
  },
  methods: {
    childDeleteMessage (messageId) {
      this.deleteMessage(messageId)
    },
    formatCreatedAt (obj) {
      const d = new Date(obj)
      return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 ${d.getHours()}時${d.getMinutes()}分${d.getSeconds()}秒`
    },
  }
}
</script>

<style>
.read {
  position: absolute;
  bottom: 0em;
  left: -2.5em;
}
.delete-button {
  position: absolute;
  bottom: -1.1em;
  right: 1em;
  cursor: pointer;
  transition: 0.5s;
}
.delete-button:hover {
  opacity: 0.5;
}
.post-datetime {
  position: absolute;
  bottom: -2em;
  left: 1em;
}
.inverted-post-datetime {
  position: absolute;
  bottom: -2em;
  right: 1em;
}
.post-user-name {
  position: absolute;
  bottom: -2em;
  right: 5em;
}
.inverted-post-user-name {
  position: absolute;
  bottom: -2em;
}
.fa-trash-o:before {
  font-size: 20px;
}
.ps {
  height: 400px;
  border: 2px solid #000;
}
.chat-file-object {
  width: 50%;
  display: block;
  margin: 0 auto;
}
.timeline:before {
  display: none;
}
.timeline-panel {
  clear:both;
  width: 60% !important;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  display: none;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  display: none;
}
.timeline > li > .timeline-panel:after {
  display: none;
}
.timeline > li > .timeline-panel:before {
  display: none;
}
</style>