<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">リース料設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              リース期間
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                  {'md-error': errors.has('leaseMonth')},
                  {'md-valid': !errors.has('leaseMonth') && touched.leaseMonth}]">
                <md-input v-model="leaseMonth" data-vv-name="leaseMonth" type="text" v-validate="modelValidations.leaseMonth"> </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('leaseMonth')">3文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('leaseMonth')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('leaseMonth') && touched.leaseMonth">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">ヶ月</div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              手数料
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                  {'md-error': errors.has('leaseCommission')},
                  {'md-valid': !errors.has('leaseCommission') && touched.leaseCommission}]">
                <md-input v-model="leaseCommission" data-vv-name="leaseCommission" type="text" v-validate="modelValidations.leaseCommission"> </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('leaseCommission')">8文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('leaseCommission')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('leaseCommission') && touched.leaseCommission">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">%</div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-30">
              <md-field :class="[
                  {'md-error': errors.has('status')},
                  {'md-valid': !errors.has('status') && touched.status}]">
                <md-select v-model="status" name="status" v-validate="modelValidations.status">
                  <md-option value="not_active">無効</md-option>
                  <md-option value="active">有効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" :disabled="(!(this.leaseMonth && this.leaseCommission && this.status && !errors.has('leaseMonth') && !errors.has('leaseCommission') && !errors.has('status')))" @click.native="createLease(leaseMonth, leaseCommission, status)"> 登録 </md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">リース料一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable">
                <div class="tHead">
                  <div class="tHead_th">リース期間</div>
                  <div class="tHead_th">手数料</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :key="item.id" class="tItem">
                  <div class="tItem_td commentBody">
                    <md-field class="z-index-0">
                      <md-input v-model="item.month"></md-input>
                      <span class="md-suffix">ヶ月</span>
                    </md-field>
                  </div>
                  <div class="tItem_td commentBody">
                    <md-field>
                      <md-input v-model="item.commission"></md-input>
                      <span class="md-suffix">%</span>
                    </md-field>
                  </div>
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="not_active">無効</md-option>
                        <md-option value="active">有効</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button class="md-just-icon md-success mg0" @click.native="updateStatus(item.id, item.month, item.commission, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import swal from 'sweetalert2'

  export default {
    mixins: [ role ],
    data () {
      return {
        leaseMonth: '',
        leaseCommission: '',
        status: '',
        resultFlag: false,
        tableData:[],
        touched: {
          leaseCommission: false,
          leaseMonth: false,
          status: false,
        },
        modelValidations: {
          leaseCommission: {
            max: 8,
          },
          leaseMonth: {
            max: 3,
          },
          status: {
            required: true
          }
        }
      }
    },
    methods: {
      getLease () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/leases',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('リース情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            month: res[i].attributes.month,
            commission: res[i].attributes.commission,
            status: res[i].attributes.status
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateStatus (id, month, commission, status) {
        var condition = {
          lease: {
            'month': month,
            'commission': commission,
            'status': status
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/leases/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`リース料を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('リース料の更新に失敗しました')
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }
        })
      },
      createLease (month, commission, status) {
        var condition = {
          lease: {
            'month': month,
            'commission': commission,
            'status': status
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/leases',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`リース情報を登録しました`)
          this.leaseMonth = ''
          this.leaseCommission = ''
          this.status = ''
          this.getLease()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('リース情報の登録に失敗しました')
          }
        })
      },
      handleDelete (item) {
        swal({
          title: 'このリース情報を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        })
        .then((result) => {
          if(result.value){
            this.deleteLease(item)
          }
        });
      },
      deleteLease (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/leases/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        })
        .then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`リース情報を削除しました`)
          // this.getCampaigns(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('リース情報を正しく削除できませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
    },
    mounted () {
      // role check & search items
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      } else {
        this.getLease()
      }
    },
    watch: {
      leaseCommission() {
        this.touched.leaseCommission = true
      },
      leaseMonth() {
        this.touched.leaseMonth = true
      },
      status() {
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.md-table-row:first-child .dropup .dropdown-toggle + .dropdown-menu{
  bottom: -100%;
}

.z-index-0 {
  z-index: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

}
</style>
