import Vue from 'vue'
import Admin from '../views/Admin.vue'
import Rails from 'rails-ujs'
import DashboardPlugin from './material-dashboard'
import VeeValidate, { Validator } from'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import axios from 'axios'
import VueAxios from '../plugins/vue-axios'
import Cookies from 'js-cookie';
import router from './router.js'
import VueRouter from 'vue-router'
import store from './store.js'
import format from '../mixins/format.js'
import alert from '../mixins/alert.js'
import constants from '../mixins/constants.js'
import VueFroalaConfig from '../plugins/vue-froala-config'
import VueJsDatePickerConfig from '../plugins/vuejs-datepicker-config'
import Vue2TransitionsConfig from '../plugins/vue2-transitions-config'
import JsonCSV from 'vue-json-csv'
import ActionCable from 'actioncable';
import Room from '../views/Room.vue';

Rails.start()
Vue.prototype.$Cookies = Cookies;
Vue.use(VueFroalaConfig)
Vue.use(VueJsDatePickerConfig)
Vue.use(Vue2TransitionsConfig)
Vue.use(DashboardPlugin)
Vue.use(VueAxios, {axios: axios , csrfToken: Rails.csrfToken})
// vee-validateの日本語
Validator.localize('ja', ja);
Vue.use(VeeValidate, { locale: ja , inject: false});
Vue.use(VueRouter)
Vue.use(require('vue-chartist'))
Vue.mixin(format)
Vue.mixin(alert)
Vue.mixin(constants)
Vue.component('downloadCsv', JsonCSV)

// 管理画面用の設定
const cable = ActionCable.createConsumer();
Vue.prototype.$cable = cable;

document.addEventListener('DOMContentLoaded', () => {
  const chat = document.getElementById('chat')
  if (chat) {
    // フロントで該当の要素(chat)があればWebSocket通信
    const cable = ActionCable.createConsumer();
    Vue.prototype.$cable = cable;

    new Vue({
      router,
      render: h => h(Room)
    }).$mount('#chat')
  } else {
    new Vue({
      el: '#app',
      router,
      store,
      render: h => h(Admin)
    })
  }
})
