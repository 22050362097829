<template>
  <!-- <div> -->
    <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productCode')},
                {'md-valid': !errors.has('productCode') && touched.productCode}]">
                <md-input
                  v-model="productCode"
                  data-vv-name="productCode"
                  type="text"
                  v-validate= "modelValidations.productCode"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productCode')">【必須】半角英数字8文字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productCode') && touched.productCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品URL
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productUrl')},
                {'md-valid': !errors.has('productUrl') && touched.productUrl}]">
                <md-input
                  v-model="productUrl"
                  data-vv-name="productUrl"
                  type="text"
                  v-validate= "modelValidations.productUrl"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productUrl')">【必須】2083文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productUrl') && touched.productUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productName')},
                {'md-valid': !errors.has('productName') && touched.productName}]">
                <md-input
                  v-model="productName"
                  data-vv-name="productName"
                  type="text"
                  v-validate= "modelValidations.productName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productName')">【必須255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productName') && touched.productName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              型式
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productSku')},
                {'md-valid': !errors.has('productSku') && touched.productSku}]">
                <md-input
                  v-model="productSku"
                  data-vv-name="productSku"
                  type="text"
                  v-validate= "modelValidations.productSku"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productSku')">【必須】20文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productSku')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productSku') && touched.productSku">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メーカー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('makerName')},
                {'md-valid': !errors.has('makerName') && touched.makerName}]">
                <md-input
                  v-model="makerName"
                  data-vv-name="makerName"
                  type="text"
                  v-validate= "modelValidations.makerName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('makerName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('makerName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('makerName') && touched.makerName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注先
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productSupplierName')},
                {'md-valid': !errors.has('productSupplierName') && touched.productSupplierName}]">
                <md-input
                  v-model="productSupplierName"
                  data-vv-name="productSupplierName"
                  type="text"
                  v-validate= "modelValidations.productSupplierName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productSupplierName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productSupplierName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productSupplierName') && touched.productSupplierName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="no_order">未発注</md-option>
                  <md-option value="order">発注</md-option>
                  <md-option value="complete_del">納品済み</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注数
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productNum')},
                {'md-valid': !errors.has('productNum') && touched.productNum}]">
                <md-input
                  v-model="productNum"
                  data-vv-name="productNum"
                  type="number"
                  v-validate= "modelValidations.productNum"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productNum')">【必須】必ず入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productNum')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productNum') && touched.productNum" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">個</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesPrice')},
                {'md-valid': !errors.has('salesPrice') && touched.salesPrice}]">
                <md-input
                  v-model="salesPrice"
                  data-vv-name="salesPrice"
                  type="number"
                  v-validate= "modelValidations.salesPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesPrice') && touched.salesPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販<br>タイムセール価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesTimePrice')},
                {'md-valid': !errors.has('salesTimePrice') && touched.salesTimePrice}]">
                <md-input
                  v-model="salesTimePrice"
                  data-vv-name="salesTimePrice"
                  type="number"
                  v-validate= "modelValidations.salesTimePrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesTimePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesTimePrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesTimePrice') && touched.salesTimePrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販保守<br>オプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesMaintenancePrice')},
                {'md-valid': !errors.has('salesMaintenancePrice') && touched.salesMaintenancePrice}]">
                <md-input
                  v-model="salesMaintenancePrice"
                  data-vv-name="salesMaintenancePrice"
                  type="number"
                  v-validate= "modelValidations.salesMaintenancePrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesMaintenancePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesMaintenancePrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesMaintenancePrice') && touched.salesMaintenancePrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販取り付け<br>オプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesAttachmentPrice')},
                {'md-valid': !errors.has('salesAttachmentPrice') && touched.salesAttachmentPrice}]">
                <md-input
                  v-model="salesAttachmentPrice"
                  data-vv-name="salesAttachmentPrice"
                  type="number"
                  v-validate= "modelValidations.salesAttachmentPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesAttachmentPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesAttachmentPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesAttachmentPrice') && touched.salesAttachmentPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販オプション名1
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName1')},
                {'md-valid': !errors.has('salesOptionName1') && touched.salesOptionName1}]">
                <md-input
                  v-model="salesOptionName1"
                  data-vv-name="salesOptionName1"
                  type="text"
                  v-validate= "modelValidations.salesOptionName1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName1')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName1') && touched.salesOptionName1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-40 md-form-label md-xsmall-size-100">
              通販オプション価格1
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice1')},
                {'md-valid': !errors.has('salesOptionPrice1') && touched.salesOptionPrice1}]">
                <md-input
                  v-model="salesOptionPrice1"
                  data-vv-name="salesOptionPrice1"
                  type="number"
                  v-validate= "modelValidations.salesOptionPrice1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice1')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice1') && touched.salesOptionPrice1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販オプション名2
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName2')},
                {'md-valid': !errors.has('salesOptionName2') && touched.salesOptionName2}]">
                <md-input
                  v-model="salesOptionName2"
                  data-vv-name="salesOptionName2"
                  type="text"
                  v-validate= "modelValidations.salesOptionName2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName2')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName2') && touched.salesOptionName2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-40 md-form-label md-xsmall-size-100">
              通販オプション価格2
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice2')},
                {'md-valid': !errors.has('salesOptionPrice2') && touched.salesOptionPrice2}]">
                <md-input
                  v-model="salesOptionPrice2"
                  data-vv-name="salesOptionPrice2"
                  type="number"
                  v-validate= "modelValidations.salesOptionPrice2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice2')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice2') && touched.salesOptionPrice2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販オプション名3
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName3')},
                {'md-valid': !errors.has('salesOptionName3') && touched.salesOptionName3}]">
                <md-input
                  v-model="salesOptionName3"
                  data-vv-name="salesOptionName3"
                  type="text"
                  v-validate= "modelValidations.salesOptionName3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName3')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName3')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName3') && touched.salesOptionName3">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-40 md-form-label md-xsmall-size-100">
              通販オプション価格3
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice3')},
                {'md-valid': !errors.has('salesOptionPrice3') && touched.salesOptionPrice3}]">
                <md-input
                  v-model="salesOptionPrice3"
                  data-vv-name="salesOptionPrice3"
                  type="number"
                  v-validate= "modelValidations.salesOptionPrice3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice3')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice3')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice3') && touched.salesOptionPrice3" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              値引率
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('discountRate')},
                {'md-valid': !errors.has('discountRate') && touched.discountRate}]">
                <md-input
                  v-model="discountRate"
                  data-vv-name="discountRate"
                  type="number"
                  v-validate= "modelValidations.discountRate"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('discountRate')">【必須】100以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('discountRate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('discountRate') && touched.discountRate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">%</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販明細<br>実価格(税込)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesProductPrice')},
                {'md-valid': !errors.has('salesProductPrice') && touched.salesProductPrice}]">
                <md-input
                  v-model="salesProductPrice"
                  data-vv-name="salesProductPrice"
                  type="number"
                  v-validate= "modelValidations.salesProductPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesProductPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesProductPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesProductPrice') && touched.salesProductPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          </md-card-actions>

        </md-card-content>
        
      </div>
    </transition>
    </md-card>
  <!-- </div> -->
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        productCode: '',
        productUrl: '',
        productName: '',
        productSku: '',
        makerName: '',
        productSupplierName: '',
        status: '',
        productNum: '',
        salesPrice: '',
        salesTimePrice: '',
        salesMaintenancePrice: '',
        salesAttachmentPrice: '',
        salesOptionName1: '',
        salesOptionPrice1: '',
        salesOptionName2: '',
        salesOptionPrice2: '',
        salesOptionName3: '',
        salesOptionPrice3: '',
        discountRate: '',
        salesProductPrice: '',
        touched: {
          productCode: false,
          productUrl: false,
          productName: false,
          productSku: false,
          makerName: false,
          productSupplierName: false,
          status: false,
          productNum: false,
          salesPrice: false,
          salesTimePrice: false,
          salesMaintenancePrice: false,
          salesAttachmentPrice: false,
          salesOptionName1: false,
          salesOptionPrice1: false,
          salesOptionName2: false,
          salesOptionPrice2: false,
          salesOptionName3: false,
          salesOptionPrice3: false,
          discountRate: false,
          salesProductPrice: false
        },
        modelValidations: {
          productCode: {
            max: 8,
            required: true
          },
          productUrl:{
            max: 2083,
            required: true
          },
          productName:{
            max: 255,
            required: true
          },
          productSku:{
            max: 200,
            required: true
          },
          makerName:{
            max: 255,
            required: true
          },
          productSupplierName:{
            max: 255,
            required: true
          },
          status:{
            required: true
          },
          productNum:{
            required: true
          },
          salesPrice:{
            max: 8,
            required: true,
            numeric: true
          },
          salesTimePrice:{
            max: 8,
            numeric: true
          },
          salesMaintenancePrice:{
            max: 8,
            numeric: true
          },
          salesAttachmentPrice:{
            max: 8,
            numeric: true
          },
          salesOptionName1:{
            max: 255
          },
          salesOptionPrice1:{
            max: 8,
            numeric: true
          },
          salesOptionName2:{
            max: 255
          },
          salesOptionPrice2:{
            max: 8,
            numeric: true
          },
          salesOptionName3:{
            max: 255
          },
          salesOptionPrice3:{
            max: 8,
            numeric: true
          },
          discountRate:{
            max: 3
          },
          salesProductPrice:{
            max: 8,
            required: true,
            numeric: true
          }
        },
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    methods: {
      getEcOrderMoreDetails (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/ec_order_details/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id                   = response.data.id
          this.productCode          = response.data.product_code
          this.productUrl           = response.data.product_url
          this.productName          = response.data.product_name
          this.productSku           = response.data.product_sku
          this.makerName            = response.data.maker_name
          this.productSupplierName  = response.data.product_supplier_name
          this.status               = response.data.status
          this.productNum           = response.data.product_num
          this.salesPrice           = Math.floor(response.data.sales_price)
          this.salesTimePrice       = Math.floor(response.data.sales_time_price)
          this.salesMaintenancePrice     = Math.floor(response.data.sales_maintenance_price)
          this.salesAttachmentPrice      = Math.floor(response.data.sales_attachment_price)
          this.salesOptionName1          = response.data.sales_option_name1
          this.salesOptionPrice1         = Math.floor(response.data.sales_option_price1)
          this.salesOptionName2          = response.data.sales_option_name2
          this.salesOptionPrice2         = Math.floor(response.data.sales_option_price2)
          this.salesOptionName3          = response.data.sales_option_name3
          this.salesOptionPrice3         = Math.floor(response.data.sales_option_price3)
          this.discountRate         = response.data.discount_rate
          this.salesProductPrice   = Math.floor(response.data.sales_product_price)
          this.detailBgFlag         = true
          this.detailFlag           = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文明細情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          ec_order_detail: {
            id:                      this.id,
            product_code:            this.productCode,
            product_url:             this.productUrl,
            product_name:            this.productName,
            product_sku:             this.productSku,
            maker_name:              this.makerName,
            product_supplier_name:   this.productSupplierName,
            status:                  this.status,
            product_num:             this.productNum,
            sales_price:             this.salesPrice,
            sales_time_price:        this.salesTimePrice,
            sales_maintenance_price: this.salesMaintenancePrice,
            sales_attachment_price:  this.salesAttachmentPrice,
            sales_option_name1:      this.salesOptionName1,
            sales_option_price1:     this.salesOptionPrice1,
            sales_option_name2:      this.salesOptionName2,
            sales_option_price2:     this.salesOptionPrice2,
            sales_option_name3:      this.salesOptionName3,
            sales_option_price3:     this.salesOptionPrice3,
            discount_rate:           this.discountRate,
            sales_product_price:     this.salesProductPrice,
            site_kind:               'sales',
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/ec_order_details/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`注文明細情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文明細情報の更新に失敗しました')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    },
    watch: {
      productCode(){
        this.touched.productCode = true
      },
      productUrl(){
        this.touched.productUrl = true
      },
      productName(){
        this.touched.productName = true
      },
      productSku(){
        this.touched.productSku = true
      },
      makerName(){
        this.touched.makerName = true
      },
      productSupplierName(){
        this.touched.productSupplierName = true
      },
      status(){
        this.touched.status = true
      },
      productNum(){
        this.touched.productNum = true
      },
      salesPrice(){
        this.touched.salesPrice = true
      },
      salesTimePrice(){
        this.touched.salesTimePrice = true
      },
      salesMaintenancePrice(){
        this.touched.salesMaintenancePrice = true
      },
      salesAttachmentPrice(){
        this.touched.salesAttachmentPrice = true
      },
      salesOptionName1(){
        this.touched.salesOptionName1 = true
      },
      salesOptionPrice1(){
        this.touched.salesOptionPrice1 = true
      },
      salesOptionName2(){
        this.touched.salesOptionName2 = true
      },
      salesOptionPrice2(){
        this.touched.salesOptionPrice2 = true
      },
      salesOptionName3(){
        this.touched.salesOptionName3 = true
      },
      salesOptionPrice3(){
        this.touched.salesOptionPrice3 = true
      },
      discountRate(){
        this.touched.discountRate = true
      },
      salesProductPrice(){
        this.touched.salesProductPrice = true
      }
    }
  }
</script>
<style scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.formLayout{
  flex-basis: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 12px !important;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.md-form-label {
  padding: 10px 5px 0 0 !important;
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.md-select-value {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.mL80 {
  margin-left: 110px !important;
}

.mt60{
  margin-top: 60px;
}

.betweenTime{
  line-height: 59px;
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>