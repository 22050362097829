<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">キャンペーン検索</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャンペーン種別
            </label>
            <div class="md-layout-item">
              <md-radio v-model="kind" value="discount" >値引き</md-radio>
              <md-radio v-model="kind" value="cashback" >キャッシュバック</md-radio>
              <md-radio v-model="kind" value="discount_rate" >値引率</md-radio>
              <md-radio v-model="kind" value="present" >プレゼント</md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              タイトル
            </label>
            <div class="md-layout-item md-size-80 md-xsmall-size-100">
              <md-field>
                <md-input v-model="title"></md-input>
              </md-field>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field class="CampaignStatus">
                <md-select v-model="status" name="status">
                  <md-option value="not_active">無効</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="選択しない">選択しない</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              適用サイト
            </label>
            <div class="md-layout-item">
              <md-radio v-model="campaign_public" value="subscription_site" >サブスク用サイト</md-radio>
              <md-radio v-model="campaign_public" value="mail_order_site" >通販用サイト</md-radio>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click.native="getCampaigns">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">キャンペーン一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">キャンペーン種別</div>
                  <div class="tHead_th">タイトル</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">適用サイト</div>
                  <div class="tHead_th">キャンペーン期間</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :key="item.id" class="tItem">
                  <div class="tItem_td">{{ item.kinds }}</div>
                  <div class="tItem_td">{{ item.title }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td">{{ item.public }}</div>
                  <div class="tItem_td">{{ item.campaignSpan }}</div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getCampaigns">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>
</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import Draggable from 'vuedraggable'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      SubmitButton,
      Draggable
    },
    computed: {
      to () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + this.tableData.length
        }
      },
      from () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + 1
        }
      }
    },
    data () {
      return {
        kind: '',
        title: '',
        status: '選択しない',
        campaign_public: '',
        tableData: [],
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        resultFlag: false
      }
    },
    methods: {
      getCampaigns(page) {
        var condition = {
          page: page,
          kind: this.kind,
          title: this.title,
          status: this.status === '選択しない' ? '' : this.status,
          public: this.campaign_public
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/campaigns',
          timeout : 50000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params  : condition
        })
        .then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('キャンペーン情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var kind = ""
          if (res[i].attributes.kind === "discount") {
            kind = "値引き"
          } else if (res[i].attributes.kind === "cashback") {
            kind = "キャッシュバック"
          } else if (res[i].attributes.kind === "discount_rate") {
            kind = "値引率"
          } else if (res[i].attributes.kind === "present") {
            kind = "プレゼント"
          }
          var campaign_status = ""
          if (res[i].attributes.status === "not_active") {
            campaign_status = "無効"
          } else if (res[i].attributes.status === "active") {
            campaign_status = "有効"
          }
          var campaign_public = ""
          if (res[i].attributes.public === "subscription_site") {
            campaign_public = "サブスク"
          } else if (res[i].attributes.public === "mail_order_site") {
            campaign_public = "通販"
          }
          var item = {
            id: res[i].id,
            kinds: kind,
            title: res[i].attributes.name,
            status: campaign_status,
            public: campaign_public,
            campaignSpan: res[i].attributes['campaign-span']
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleDelete (item) {
        swal({
          title: 'このキャンペーンを削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteCampaign(item)
          }
        });
      },
      deleteCampaign (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/campaigns/' + item.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        })
        .then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`キャンペーン情報を削除しました`)
          this.getCampaigns(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('キャンペーン情報を正しく削除できませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      jumpDetail (item) {
        const url = '/admin/campaign/edit/'+ item.id
        window.open(url, '_blank')
      }
    },
    mounted () {
      // if (this.shopFlag) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
    },
    
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}


.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.errorMsg{
  bottom: -15px !important;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
