<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">ユーザー詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" >
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              法人区分
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="companyClassification" value="individual">個人事業主</md-radio>
              <md-radio v-model="companyClassification" value="corporation">法人</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              URL
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('url')},
                {'md-valid': !errors.has('url') && touched.url}]">
                <md-input
                  v-model="url"
                  data-vv-name="url"
                  type="text"
                  v-validate= "modelValidations.url"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('url')">【必須】2083文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('url')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('url') && touched.url">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('memberStatus')},
                {'md-valid': !errors.has('memberStatus') && touched.memberStatus}]">
                <md-select v-model="memberStatus"
                  data-vv-name="memberStatus"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="guest">非会員</md-option>
                  <md-option value="member">会員</md-option>
                  <md-option value="unsubscribed">退会</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('memberStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('memberStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('memberStatus') && touched.memberStatus" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              登録日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{admissionDay | dateFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              種別
            </label>
            <div class="md-layout-item md-size-25 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('kind')},
                {'md-valid': !errors.has('kind') && touched.kind}]">
                <md-select v-model="kind"
                  data-vv-name="kind"
                  v-validate= "modelValidations.kind"
                  required>
                  <md-option value="circle">◯</md-option>
                  <md-option value="triangle">△</md-option>
                  <md-option value="square">□</md-option>
                  <md-option value="close">×</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('kind')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('kind')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('kind') && touched.kind" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{email}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('tel')},
                {'md-valid': !errors.has('tel') && touched.tel}]">
                <md-input
                  v-model="tel"
                  data-vv-name="tel"
                  type="number"
                  v-validate= "modelValidations.tel"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel')">【必須】それぞれ11桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel') && touched.tel" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              管理者用メモ
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="memo" md-counter="255" maxlength="255"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              自由欄
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="freeMemo" md-counter="255" maxlength="255"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              設置場所(配送先)
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static" v-for="item in shopList" :data-id="item.id">
                <a href="#" @click.stop.prevent="handleShopEdit(item.id)"><u>{{item.name}}</u></a>
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              <a href="#"><u @click.prevent="getUserDetails">さらに詳しく表示</u></a>
            </label>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          <md-button class="md-rose md-lg mL40" @click="jumpOrderIndex(id)">取引一覧</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <user-detail
      ref="userDetail"
    ></user-detail>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import UserDetail from '../components/additional/UserDetail.vue'
  import EventBus from '../mixins/event-bus.js';

  export default {
    mixins: [ role ],
    components: {
      UserDetail
    },
    computed: {
    },
    data () {
      return {
        id: '',
        companyClassification: '',
        tel: '',
        memberStatus: '',
        admissionDay: '',
        email: '',
        url: '',
        memo: '',
        freeMemo: '',
        kind: '',
        shopList: [],
        touched: {
          url: false,
          tel: false,
          memberStatus: false,
          kind: false,
        },
        modelValidations: {
          url: {
            max: 2083
          },
          tel:{
            required: true,
            max: 11,
            numeric: true
          },
          memberStatus:{
            required: true
          },
        },
      }
    },
    methods: {
      handleShopEdit (id) {
        router.push({ path: '/admin/shop/edit/' + id })
      },
      jumpOrderIndex (id) {
        router.push({ path: '/admin/order_list/', query: { user_id: id }})
      },
      returnBack () {
        router.push({ path: '/admin/user_list/' })
      },
      getUsers () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.companyClassification = response.data.company_classification
          this.tel = response.data.tel
          this.memberStatus = response.data.status
          this.admissionDay = response.data.created_at
          this.email = response.data.email
          this.url = response.data.company_url
          this.memo = response.data.memo
          this.freeMemo = response.data.free_memo
          this.kind = response.data.kind
          for (var i in response.data.shops) {
            var item = {
              id: response.data.shops[i].id,
              name: response.data.shops[i].name
            }
            this.shopList.push(item)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ユーザー情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          user: {
            company_classification: this.companyClassification,
            company_url: this.url,
            tel: this.productName,
            status: this.memberStatus,
            kind: this.kind,
            memo: this.memo,
            free_memo: this.freeMemo,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/users/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ユーザー情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ユーザー情報の更新に失敗しました')
          }
        })
      },
      getUserDetails() {
        this.$refs.userDetail.getUserDetails()
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getUsers()
    },
    watch: {
      url(){
        this.touched.url = true
      },
      tel(){
        this.touched.tel = true
      },
      memberStatus(){
        this.touched.memberStatus = true
      },
      kind(){
        this.touched.kind = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

.pt5{
  padding-top: 5px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
