<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">企業配信情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              宛先<br>メールアドレス
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('email')},
                {'md-valid': !errors.has('email') && touched.email}]">
                <md-input
                  v-model="email"
                  data-vv-name="email"
                  type="email"
                  v-validate= "modelValidations.email">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('email')">半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メールフッター<br>(HTML)
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="footerHtml"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メールフッター<br>(テキスト)
            </label>
            <div class="md-layout-item pt20">
              <md-field>
                <md-textarea v-model="footerText"></md-textarea>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>

      <div class="linkCont">
        <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
        <div class="bottomLink" @click="goService">サービス情報を編集する</div>
        <div class="bottomLink" @click="goApp">アプリ用画像情報を編集する</div>
      </div>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        email: '',
        footerHtml: '',
        buttonWord: '登録',
        footerText: '',
        basicLock: false,
        touched: {
          email: false,
        },
        modelValidations: {
          email: {
            max: 255,
            email: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateEmail()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      goSetting () {
        router.push({ path: '/admin/company/edit/' + this.$route.params.id })
      },
      goService () {
        router.push({ path: '/admin/company/service/edit/' + this.$route.params.id })
      },
      goApp () {
        router.push({ path: '/admin/company/application/edit/' + this.$route.params.id })
      },
      getEmail () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/email_settings/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.email = response.data.from_address
          this.footerHtml = response.data.footer_html
          this.footerText = response.data.footer_text
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('配信設定情報を正しく取得できませんでした')
          }
        })
      },
      updateEmail(){
        var condition = {
          email_setting: {
            company_id: this.$route.params.id,
            from_address : this.email,
            footer_html : this.footerHtml,
            footer_text : this.footerText
          }
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/email_settings/' : this.AdminApiUrl + '/email_settings/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`配信設定情報を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('配信設定情報を正しく登録できませんでした')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.companyFlag) {
        this.basicLock = true
        var pt = this.$route.path
        pt = pt.split('/edit/')
        if (pt[1] !== this.$Cookies.get('companyId')) {
          router.push({ path: '/admin/login'})
          return false
        }
      } else if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getEmail()
    },
    watch: {
      email(){
        this.touched.email = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.pt20{
  padding-top: 20px;
}

.is-l-none{
  display: none;
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
