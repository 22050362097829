<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{ rateData.title }}倍率変更</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('campaignName')},
                {'md-valid': !errors.has('campaignName') && touched.campaignName}]">
                <md-input
                  v-model="campaignName"
                  data-vv-name="campaignName"
                  type="text"
                  v-validate= "modelValidations.campaignName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('campaignName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('campaignName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('campaignName') && touched.campaignName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              倍率
            </label>
            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('rate')},
                {'md-valid': !errors.has('rate') && touched.rate}]">
                <md-input
                  v-model="rate"
                  data-vv-name="rate"
                  v-validate= "modelValidations.rate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('rate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rate') && touched.rate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              適用範囲
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="scale" value="shop">店舗</md-radio>
              <md-radio v-model="scale" value="user_rank">会員ランク</md-radio>
              <md-radio v-model="scale" value="all_scale">全て</md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='shop'">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item">
              <shop-select v-model="shopId" :touchedFlag="true" :mutlipleFlag="true"></shop-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='user_rank'">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item">
              <user-rank-select v-model="userRankId" :touchedFlag="true" :mutlipleFlag="true"></user-rank-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import router from '../../packs/router.js'
  import EventBus from '../../mixins/event-bus.js';
  import role from "../../mixins/role.js"
  import ShopSelect from './ShopSelect.vue'
  import TimeSelect from './TimeSelect.vue'
  import SubmitButton from './SubmitButton.vue'
  import UserRankSelect from './UserRankSelect.vue'
  import qs from 'qs'

  export default {
    components: {
      SubmitButton,
      ShopSelect,
      TimeSelect,
      UserRankSelect
    },
    computed: {
    },
    props: {
      rateData: Object
    },
    data () {
      return {
        buttonWord: '更新',
        campaignName: '',
        rate: '',
        scale: 'all_scale',
        shopId: [],
        userRankId: [],
        selectedStart: '',
        selectedEnd: '',
        startTime: '',
        endTime: '',
        status: '',
        newFlag: false,
        requiredFlag: true,
        touched: {
          campaignName: false,
          rate: false,
          scale: false,
          selectedStart: false,
          selectedEnd: false,
          status: false
        },
        modelValidations: {
          campaignName: {
            required: true,
            max: 255
          },
          rate: {
            required: true,
            max: 2,
            numeric: true
          },
          scale: {
            required: true
          },
          shopId: {
            required: true
          },
          status: {
            required: true
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: this.rateData.returnBack })
      },
      getRate () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + this.rateData.apiUrl + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.campaignName = response.data.name
          this.rate = response.data.rate
          this.scale = response.data.scale
          response.data.shop_ids === null ? this.shopId= [] : this.shopId = response.data.shop_ids
          response.data.user_rank_ids === null ? this.userRankId= [] : this.userRankId = response.data.user_rank_ids
          this.status = response.data.status
          if (response.data.public_from !== null) {
            this.selectedStart = this.dateDivider(response.data.public_from)[0]
            this.startTime = this.dateDivider(response.data.public_from)[1]
            this.$refs.startTimer.unlock()
          }
          if (response.data.public_to !== null) {
            this.selectedEnd = this.dateDivider(response.data.public_to)[0]
            this.endTime = this.dateDivider(response.data.public_to)[1]
            this.$refs.endTimer.unlock()
          }

        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.rateData.title+'倍率設定を正しく取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateRate()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateRate(){
        var condition = {
          rate: {
            name: this.campaignName,
            rate: this.rate,
            scale: this.scale,
            shop_ids: this.scale === "shop" ? this.shopId === '' ? '' : this.shopId.join(',') : '',
            user_rank_ids: this.scale === "user_rank" ? this.userRankId === '' ? '' : this.userRankId.join(',') : '',
            status:this.status,
            public_from: this.initDate(this.selectedStart) + ' ' + this.startTime,
            public_to: this.initDate(this.selectedEnd) + ' ' + this.endTime
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + this.rateData.apiUrl + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(this.rateData.title+`倍率設定を`+this.buttonWord+`しました`, this.rateData.returnBack)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.rateData.title+`倍率設定を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      resetData () {
        this.campaignName = ''
        this.rate = ''
        this.scale = ''
        this.shopId = []
        this.userRankId = []
        this.status = ''
        this.selectedStart = null
        this.startTime = '00:00'
        this.$refs.startTimer.lock()
        this.selectedEnd = null
        this.endTime = '00:00'
        this.$refs.endTimer.lock()
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getRate()
      if (this.companyFlag) {
        this.companyId = this.$Cookies.get('companyId')
      } else if (this.shopFlag) {
        this.shopId = this.$Cookies.get('shopId')
      }
    },
    watch: {
      campaignName () {
        this.touched.campaignName = true
      },
      rate () {
        this.touched.rate = true
      },
      scale(){
        this.touched.scale = true
        if (this.scale==='shop') {
          this.requiredFlag=false
        } else {
          this.requiredFlag=true
        }
      },
      status () {
        this.touched.status = true
      },
      selectedStart () {
        this.$refs.startTimer.unlock()
      },
      selectedEnd () {
        this.$refs.endTimer.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.rateStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
