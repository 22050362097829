<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">注文詳細</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">
                <a href="#" @click.stop.prevent="handleUserEdit(userId)"><u>{{fullName}}</u></a>
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{code}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{orderEmail}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{companyName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文日
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{orderDate}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取り付け
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{attachment == 'attachment_on' ? '希望する' : '希望しない'}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('orderSalesStatus')},
                {'md-valid': !errors.has('orderSalesStatus') && touched.orderSalesStatus}]">
                <md-select v-model="orderSalesStatus"
                  data-vv-name="orderSalesStatus"
                  v-validate= "modelValidations.orderSalesStatus"
                  required>
                  <md-option value="cancel">キャンセル</md-option>
                  <md-option value="ordered">注文済</md-option>
                  <md-option value="delivery_preparation">配送準備中</md-option>
                  <md-option value="delivery_completed">配送完了</md-option>
                  <md-option value="returned">返品</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('orderSalesStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('orderSalesStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('orderSalesStatus') && touched.orderSalesStatus" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-show="canceleable">
              <md-field :class="[
                {'md-error': errors.has('payStatus')},
                {'md-valid': !errors.has('payStatus') && touched.payStatus}]">
                <md-select v-model="payStatus"
                  data-vv-name="payStatus"
                  v-validate= "modelValidations.payStatus"
                  required>
                  <md-option value="unprocessed">未処理</md-option>
                  <md-option value="paid">決済済み</md-option>
                  <md-option value="processing">処理中</md-option>
                  <md-option value="refund">返金</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('payStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('payStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('payStatus') && touched.payStatus" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-xsmall-size-100" v-show="!canceleable">
              <p class="form-control-static">{{payStatus}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              日中連絡がつく<br />電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{guestTel}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              固定電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{guestFixTel}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送先郵便番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{guestDeliveryZip}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送先都道府県
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{guestDeliveryPref}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送先住所
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{guestDeliveryAddress}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文種別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{orderType}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払方法
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{switchPaymentType(paymentType)}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              振込用コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{transferCode}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{deliveryFee | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              値引き額
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{discount | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              合計注文価格(税込)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{salesTotalPrice | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              消費税
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{tax | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メモ・備考
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="memo" md-counter="255" maxlength="255"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              <a href="#"><u @click.prevent="getEcOrderDetails">さらに詳しく表示</u></a>
            </label>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">注文明細一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">商品名</div>
                  <div class="tHead_th">型式</div>
                  <div class="tHead_th">発注ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" :key="item.id" class="tItem">
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.productName }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.productSku }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.orderDetailStatus }}</md-label></div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.prevent="getEcOrderMoreDetails(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0 qr-purple"
                      @click.prevent="getEcOrderQrImage(item.id)">
                      <md-icon>qr_code</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </transition>
    <ec-order-detail
      ref="EcOrderDetail"
    ></ec-order-detail>
    <ec-order-more-detail
      ref="EcOrderMoreDetail"
    ></ec-order-more-detail>
    <ec-order-qr-image
      ref="EcOrderQrImage"
    ></ec-order-qr-image>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import EcOrderDetail from '../components/additional/EcOrderDetail.vue'
  import EcOrderMoreDetail from '../components/additional/EcOrderMoreDetail.vue'
  import EcOrderQrImage from '../components/additional/EcOrderQrImage.vue'
  import EventBus from '../mixins/event-bus.js'
  import swal from 'sweetalert2';

  export default {
    mixins: [ role ],
    components: {
      EcOrderDetail,
      EcOrderMoreDetail,
      EcOrderQrImage,
    },
    computed: {
    },
    data () {
      return {
        id: '',
        fullName: '',
        orderEmail: '',
        companyName: '',
        orderSalesStatus: '',
        orderDate: '',
        discount: '',
        deliveryFee: '',
        tax: '',
        attachment: '',
        payStatus: '',
        guestFixTel: '',
        guestTel: '',
        guestDeliveryZip: '',
        guestDeliveryPref: '',
        guestDeliveryAddress: '',
        paymentType: '',
        salesTotalPrice: '',
        memo: '',
        transferCode: '',
        orderType: '',
        canceleable: '',
        touched: {
          payStatus: false,
          orderSalesStatus: false,
        },
        modelValidations: {
          payStatus: {
            required: true
          },
          orderSalesStatus:{
            required: true
          },
        },
        tableData: []
      }
    },
    methods: {
      handleUserEdit (id) {
        router.push({ path: '/admin/user/edit/' + id })
      },
      returnBack () {
        router.push({ path: '/admin/ec_order_list/' })
      },
      getEcOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/ec_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id                   = response.data.id
          this.fullName             = response.data.full_name
          this.code                 = response.data.code
          this.orderEmail           = response.data.order_email
          this.companyName          = response.data.company_name
          this.orderSalesStatus     = response.data.order_sales_status
          this.orderDate            = response.data.order_date
          this.discount             = response.data.discount
          this.deliveryFee          = response.data.delivery_fee
          this.tax                  = response.data.tax
          this.attachment           = response.data.attachment
          this.payStatus            = response.data.pay_status
          this.guestTel             = response.data.guest_tel
          this.guestFixTel          = response.data.guest_fix_tel
          this.guestDeliveryZip     = response.data.guest_delivery_zip
          this.guestDeliveryPref    = response.data.guest_delivery_pref
          this.guestDeliveryAddress = response.data.guest_delivery_address
          this.paymentType          = response.data.payment_type
          this.salesTotalPrice      = response.data.sales_total_price
          this.memo                 = response.data.memo
          this.userId               = response.data.user_id
          this.transferCode         = response.data.transfer_code
          this.canceleable          = response.data.canceleable
          if (this.canceleable) {
            this.payStatus = response.data.pay_status
          } else {
            switch (response.data.pay_status) {
            case 'unprocessed':
              this.payStatus = '未処理'
              break;
            case 'paid':
              this.payStatus = '決済済み'
              break;
            case 'processing':
              this.payStatus = '処理中'
              break;
            case 'refund':
              this.payStatus = '返金'
              break;
            default:
              this.payStatus = '識別不明'
            }
          }
          switch (response.data.order_type) {
            case 'normal':
              this.orderType = '通販注文'
              break;
            case 'guest':
              this.orderType = '通販ゲスト注文'
              break;
            default:
              this.orderType = '識別不明'
          }
          var res = response.data.order_details
          for (var i in res) {
            var item = {
              id:          res[i].id,
              productName: res[i].product_name,
              productSku:  res[i].product_sku
            }
            switch (res[i].status) {
              case 'no_order':
                item.orderDetailStatus = '未発注'
                break;
              case 'order':
                item.orderDetailStatus = '発注'
                break;
              case 'complete_del':
                item.orderDetailStatus = '納品済み'
                break;
              default:
                item.orderDetailStatus = '識別不明'
            }
            this.tableData.push(item)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報の取得に失敗しました')
          }
        })
      },
      switchPaymentType(paymentType){
        switch (paymentType) {
          case 'credit_card':
            return 'クレジットカード'
          case 'bank':
            return '銀行振込'
          case 'b_to_b':
            return 'B2B売掛決済'
          default:
            return '識別不明'
        }
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.handleUpdate()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      handleUpdate () {
        if (this.paymentType == 'credit_card' && this.payStatus == 'refund') {
          swal({
            title: 'この注文を更新してもよろしいですか?',
            text: `決済ステータスを返金にすると、クレジットカードの返金処理が動作いたします`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'md-button md-success btn-fill',
            cancelButtonClass: 'md-button md-danger btn-fill',
            confirmButtonText: 'OK',
            buttonsStyling: false
          }).then((result) => {
            if(result.value){
              this.putItem()
            }
          });
        } else {
          this.putItem()
        }
      },
      putItem () {
        var condition = {
          ec_order: {
            pay_status:           this.payStatus,
            order_sales_status:   this.orderSalesStatus,
            memo:                 this.memo,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/ec_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`注文情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報の更新に失敗しました')
          }
        })
      },
      getEcOrderDetails() {
        this.$refs.EcOrderDetail.getEcOrderDetails()
      },
      getEcOrderMoreDetails(id) {
        this.$refs.EcOrderMoreDetail.getEcOrderMoreDetails(id)
      },
      getEcOrderQrImage(id) {
        this.$refs.EcOrderQrImage.getEcOrderQrImage(id)
      },
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getEcOrders()
      this.getEcOrderDetailLists(this.$route.params.id)
    },
    watch: {
      payStatus(){
        this.touched.payStatus = true
      },
      orderSalesStatus(){
        this.touched.orderSalesStatus = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

.pt5{
  padding-top: 5px;
}

.qr-purple {
  background: #AA09B6 !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>