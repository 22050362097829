<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">取引検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="code"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引タイトル
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="orderTtl"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(姓)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(名)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名(屋号)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="companyName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="shopName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所都道府県
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="shopPref"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所住所
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="shopAddress"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="md-alignment-top-center mx-auto">
          <md-button class="md-success md-lg" @click="getOrders(null, null, null, true, null, false, true)">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="getOrders(null, null, null, null, null, true)">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">取引一覧</h4>
          </md-card-header>
          <md-card-actions md-alignment="left" class="pT30">
            <md-button class="md-info md-lg" @click="getOrders(null, null, null, true, null)">全て</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, null, null, true, false)">未読</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'ng')">キャンセル</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'estimate_mid')">見積中</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'estimate_after')">見積後</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'estimate_finish')">見積終了</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'application')">正式申込</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'delivery_preparation')">配送準備中</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'contract')">契約中</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, 'contract_finish')">契約終了</md-button>
            <md-button class="md-info md-lg mL20" @click="getOrders(null, null, true)">お気に入り</md-button>
          </md-card-actions>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th"></div>
                  <div class="tHead_th">メモ</div>
                  <div class="tHead_th">代表者氏名</div>
                  <div class="tHead_th">設置場所名</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">取引タイトル</div>
                  <div class="tHead_th">金額 / 月</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">
                    <md-label style="word-break:break-all;">
                      <md-icon v-if="item.favorite" @click.native="updateFavorite(item.id, false)">star</md-icon>
                      <md-icon v-else @click.native="updateFavorite(item.id, true)">star_border</md-icon>
                    </md-label>
                  </div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.memo }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.fullName }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.shopName }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.orderStatus }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.orderTtl }}</md-label></div>
                  <div class="tItem_td">
                    <md-label style="word-break:break-all;">
                      {{ item.contractOrEstimatePrice | numberWithDelimiter }}円
                    </md-label>
                  </div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-linkedin mg0"
                      @click.native="jumpRoom(item.roomId, item.companyName, item.shopName, item.orderStatus, item.id)">
                      <md-icon class="fa fa-comments-o"></md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getOrders">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        favorite: '',
        code: '',
        orderTtl: '',
        lastName: '',
        firstName: '',
        fullName: '',
        shopName: '',
        companyName: '',
        shopPref: '',
        shopAddress: '',
        tel: '',
        orderStatus: '',
        estimatePrice: '',
        contractPrice: '',
        contractOrEstimatePrice: '',
        roomId: '',
        read: '',
        userId: '',
        plain: '-------',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getOrders (page = 1, order_status = '', favorite = '', is_reset = false, read = '', is_csv = false, is_user_reset = false) {
        if (order_status) {
          this.orderStatus = order_status
        }
        if (favorite) {
          this.favorite = favorite
        }
        if (is_reset) {
          this.orderStatus = ''
          this.favorite = ''
          this.read = ''
        }
        if (read == false) {
          this.read = read
        }
        if (is_user_reset) {
          this.userId = ''
        }
         var condition = {
          page:           page,
          user_id:        this.userId,
          favorite:       this.favorite,
          code:           this.code,
          order_ttl:      this.orderTtl,
          last_name:      this.lastName,
          first_name:     this.firstName,
          full_name:      this.fullName,
          shop_name:      this.shopName,
          company_name:   this.companyName,
          shop_pref:      this.shopPref,
          shop_address:   this.shopAddress,
          tel:            this.tel,
          order_status:   this.orderStatus,
          estimate_price: this.estimatePrice,
          contract_price: this.contractPrice,
          room_id:        this.roomId,
          read:           this.read
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/orders/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage   = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount  = response.data.meta['total-count']
            if (is_csv) {
              this.handleCsvDownload()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('取引情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id:            res[i].id,
            memo:      res[i].attributes.memo,
            favorite:      res[i].attributes.favorite,
            fullName:      res[i].attributes['full-name'],
            companyName:   res[i].attributes['company-name'],
            shopName:      res[i].attributes['shop-name'],
            orderTtl:      res[i].attributes['order-ttl'],
            estimatePrice: res[i].attributes['estimate-price'],
            contractPrice: res[i].attributes['contract-price'],
            contractOrEstimatePrice: res[i].attributes['contract-or-estimate-price'],
            roomId:        res[i].attributes['room-id']
          }
          switch (res[i].attributes['order-status']) {
            case 'ng':
              item.orderStatus = 'キャンセル'
              break;
            case 'estimate_mid':
              item.orderStatus = '見積中'
              break;
            case 'estimate_after':
              item.orderStatus = '見積後'
              break;
            case 'estimate_finish':
              item.orderStatus = '見積終了'
              break;
            case 'application':
              item.orderStatus = '正式申込'
              break;
            case 'contract':
              item.orderStatus = '契約中'
              break;
            case 'contract_finish':
              item.orderStatus = '契約終了'
              break;
            case 'delivery_preparation':
              item.orderStatus = '配送準備中'
              break;
            default:
              item.orderStatus = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateFavorite (id, favorite) {
        var condition = {
          order: {
            favorite: favorite,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/orders/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.getOrders(null, null, null, true, null)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お気に入りの更新に失敗しました')
          }
        })
      },
      jumpRoom (id, companyName, shopName, orderStatus, orderId) {
        const url = `/admin/orders/${id}/chat/?room_id=${id}&company_name=${companyName}&shop_name=${shopName}&order_status=${orderStatus}&order_id=${orderId}`
        window.open(url, '_blank')
      },
      jumpDetail (item) {
        const url = '/admin/order/edit/'+ item.id
        window.open(url, '_blank')
      },
      handleCsvDownload () {
        swal({
          title: `対象の${this.pageData.totalCount}件をCSV出力しますか？`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          favorite:       this.favorite,
          code:           this.code,
          order_ttl:      this.orderTtl,
          last_name:      this.lastName,
          first_name:     this.firstName,
          full_name:      this.fullName,
          shop_name:      this.shopName,
          company_name:   this.companyName,
          shop_pref:      this.shopPref,
          shop_address:   this.shopAddress,
          tel:            this.tel,
          order_status:   this.orderStatus,
          estimate_price: this.estimatePrice,
          contract_price: this.contractPrice,
          room_id:        this.roomId,
          read:           this.read,
          user_id:        this.userId
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/orders/export_csv',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "AdminUser not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (this.$route.query.user_id) {
        this.userId = this.$route.query.user_id
        this.getOrders(1)
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.chat-blue {
  background: #0068FF !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
