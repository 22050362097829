const AdminApiUrl = '/admin/api/v1'
const TextAreaMaxLength = 140
const ResolveTime = 2000
const TextIcons = [
  '😀',
  '😁',
  '😂',
  '😎',
  '🤔',
  '😴',
  '💪',
  '👈',
  '👆',
  '✋',
  '👌',
  '👍',
  '🤚',
  '👐',
  '🙏'
]

export default {
  data () {
    return {
      AdminApiUrl: AdminApiUrl,
      TextAreaMaxLength: TextAreaMaxLength,
      TextIcons: TextIcons,
      ResolveTime: ResolveTime,
      highlighted: {
        dates: [ 
          new Date()
        ]
      }
    }
  }
}