<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">設置場所(配送先)検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="shopName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="email"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所(日中連絡がつく)電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              適用サイト
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="siteKind">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="subsc">サブスクキッチン</md-option>
                  <md-option value="sales">スタートキッチン</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getShops(1, true)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">設置場所(配送先)一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">設置場所名</div>
                  <div class="tHead_th">氏名</div>
                  <div class="tHead_th">メールアドレス</div>
                  <div class="tHead_th">設置場所(日中連絡がつく)電話番号</div>
                  <div class="tHead_th">適用サイト</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.shopName }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.email }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.tel }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.site_kind }}</md-label></div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getShops">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        lastName: '',
        firstName: '',
        shopName: '',
        email: '',
        tel: '',
        status: '',
        kind: '',
        userId: '',
        siteKind: '選択しない',
        plain: '-------',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getShops (page, is_user_reset = false) {
        if (is_user_reset) {
          this.userId = ''
        }
        var condition = {
          page: page,
          user_id: this.userId,
          last_name: this.lastName,
          first_name: this.firstName,
          tel: this.tel,
          name: this.shopName,
          email: this.email,
          site_kind: this.siteKind === '選択しない' ? '' : this.siteKind
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('設置場所(配送先)情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes['user-name'],
            shopName: res[i].attributes['name'],
            email: res[i].attributes['user-email'],
            tel: res[i].attributes.tel
          }
          switch (res[i].attributes['site-kind']) {
            case 'subsc':
              item.site_kind = 'サブスクキッチン'
              break;
            case 'sales':
              item.site_kind = 'スタートキッチン'
              break;
            default:
              item.site_kind = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleLike (item) {
        this.successAndBackPage(`ステータスを更新しました`)
      },
      jumpDetail (item) {
        const url = '/admin/shop/edit/'+ item.id
        window.open(url, '_blank')
      },
      getShop (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createShopSelect(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()

          } else {
            this.dangerNotify('設置場所(配送先)リストを取得できませんでした')
          }
        })
      },
      createShopSelect (obj) {
        var res = obj.data
        this.shopSelect.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name
          }
          this.shopSelect.push(item)
        }
      },
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (this.$route.query.user_id) {
        this.userId = this.$route.query.user_id
        this.getShops(1)
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
