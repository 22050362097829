<template>
  <div class="md-layout">  
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">(サブスク)TOP特集スライド</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="specialTop"></froala>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>

    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import FroalaImageUpload from "../mixins/froala-image-upload.js"

  export default {
    mixins: [ role , FroalaImageUpload ],
    components: {
      SubmitButton
    },
    data () {
      return {
        buttonWord: '更新',
        specialTop: ''
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      getService () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.specialTop = response.data.special_top
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('TOP特集スライド情報を正しく取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateService()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateService(){
        var condition = {
          service_setting: {
            company_id: this.$route.params.id,
            special_top: this.specialTop
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`TOP特集スライド情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('TOP特集スライド情報を正しく更新できませんでした')
          }
        })
      },
    },
    mounted () {
      // TODO 管理画面の権限要件が決まり次第、権限確認追加
      this.getService()
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.mt60{
  margin-top: 60px;
}

.pt20{
  padding-top: 20px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>