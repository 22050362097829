import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

const accessToken = {
  namespaced: true,
  state: {
    token: null,
    tokenExpireDate: null,
  },
  getters: {
    token(state){
      if(state.token != null && new Date() <= state.tokenExpireDate){
        return state.token
      }
      return null
    }
  },
  mutations: {
    setAccessToken(state, data){
      state.token = data.access_token
      state.tokenExpireDate = new Date(data.access_token_expire_date)
    }
  }
}

const store = new Vuex.Store({
  modules: {
    accessToken,
  }
})

export default store