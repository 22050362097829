<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">スペック表編集</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
            <div v-for="(item, index) in tableData" :data-id="item.id" class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
              <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
                {{ item.name }}
              </label>
              <div class="md-layout-item md-xsmall-size-100">
                <md-field :class="[
                  {'md-error': errors.has(`tableData[${index}].value`)},
                  {'md-valid': !errors.has(`tableData[${index}].value`) && touched[item.id]}]">
                  <md-input
                    v-model="tableData[index].value"
                    :name="`tableData[${index}].value`"
                    type="text"
                    v-validate= "modelValidations.value"
                    required>
                  </md-input>
                  <slide-y-up-transition>
                    <div class="vErrorText" v-show="errors.has(`tableData[${index}].value`)">【必須】255文字以内で入力してください</div>
                  </slide-y-up-transition>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="errors.has(`tableData[${index}].value`)">close</md-icon>
                  </slide-y-down-transition>
                  <!-- <slide-y-down-transition>
                    <md-icon class="success" v-show="!errors.has(`tableData[${index}].value`) && touched[item.id]">done</md-icon>
                  </slide-y-down-transition> -->
                </md-field>
              </div>
            </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    data () {
      return {
        buttonWord: "",
        tableData: [],
        buttonFlag: true,
        touched: {
        },
        modelValidations: {
          value: {
            max: 255
          }
        },
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/product/edit/' + this.$route.params.id })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getItem () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/products/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック表ラベルが取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.spec_values
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].name,
            value: res[i].value
          }
          this.touched[res[i].id] = false
          this.tableData.push(item)
        }
        for (var i in this.tableData) {
          this.$watch(`tableData[${i}].value`, this.touched[this.tableData[i].id] = true )
        }
        this.resultFlag = true
      },
      putItem () {
        var condition = {
          product: {
            spec_values: this.tableData
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl+'/products/'+this.$route.params.id+'/spec_values_update',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スペック表を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スペック表を正しく更新できませんでした`)
          }
        })
      },
    },
    mounted () {
      if (this.makerFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      var url = location.href
      url = url.split('/')
      this.buttonWord = '更新'
      this.getItem()
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}
.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>