<template>
  <csv-download :className="className"></csv-download>
</template>

<script>
import CsvDownload from '../components/additional/CsvDownload.vue'

export default {
  components: {
    CsvDownload,
  },
  data () {
    return { 
      className: 'Maker'
    }
  }
}
</script>