<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">特集ページ登録</h4>
        </md-card-header>

        <md-card-content>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              特集名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              適用サイト
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('siteKind')},
                {'md-valid': !errors.has('siteKind') && touched.siteKind}]">
                <md-select
                  v-model="siteKind"
                  data-vv-name="siteKind"
                  v-validate= "modelValidations.siteKind"
                  required>
                  <md-option value="subsc">サブスクキッチン</md-option>
                  <md-option value="sales">スタートキッチン</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('siteKind')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('siteKind')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('siteKind') && touched.siteKind" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特集内容
            </label>
            <div class="md-layout-item pt20 froala">
              <md-field>                
                <froala :tag="'textarea'" :config="config" v-model="specialPage"
                    data-vv-name="specialPage"
                    type="text"
                    v-validate= "modelValidations.specialPage"
                    required>
                </froala>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specialPage')">【必須】入力してください</div>
                </slide-y-up-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack" v-show="superFlag">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import FroalaImageUpload from "../mixins/froala-image-upload.js"

  export default {
    mixins: [ role , FroalaImageUpload ],
    components: {
      SubmitButton
    },
    data () {
      return {
        buttonWord: '登録',
        name: '',
        status: '',
        siteKind: '',
        specialPage: '',
        newFlag: false,
        touched: {
          name: false,
          status: false,
          siteKind: false,
          specialPage: false
        },
        modelValidations: {
          name: {
            required: true,
            max: 255
          },
          status: {
            required: true
          },
          siteKind: {
            required: true
          },
          specialPage: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/special_page_list/' })
      },
      getSpecial () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/special_pages/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.name = response.data.name
          this.status = response.data.status
          this.siteKind = response.data.site_kind
          this.specialPage = response.data.special_page
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('特集ページ情報を正しく取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateSpecial()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateSpecial(){
        var condition = {
          special_page: {
            name: this.name,
            status:this.status,
            site_kind:this.siteKind,
            special_page: this.specialPage
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/special_pages/' : this.AdminApiUrl + '/special_pages/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`特集ページ情報を`+this.buttonWord+`しました`,'/admin/special_page_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`特集ページ情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.name = ''
        this.status = 'active'
        this.siteKind = 'subsc'
        this.specialPage = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      // TODO 管理画面の権限要件が決まり次第、権限確認追加
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getSpecial()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getSpecial()
        }
      },
      name(){
        this.touched.name = true
      },
      status(){
        this.touched.status = true
      },
      siteKind(){
        this.touched.siteKind = true
      },
      specialPage () {
        this.touched.specialPage = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.mt60{
  margin-top: 60px;
}

.pt20{
  padding-top: 20px;
}

.froala{
  max-height: 600px;
  overflow-y: auto;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
