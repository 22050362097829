<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">カテゴリー検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              カテゴリー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="name"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              大カテゴリーコード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="bigCategoryCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              中カテゴリーコード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="middleCategoryCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              カテゴリーコード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="code"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="md-alignment-top-center mx-auto">
          <md-button class="md-success md-lg" @click="getMakers">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="getMakers(1, true)">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">カテゴリー一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">カテゴリー名</div>
                  <div class="tHead_th">カテゴリーコード</div>
                  <div class="tHead_th">ステータス</div>
                </div>

                <div v-for="item in tableData" :key="item.id" class="tItem">
                  <div class="tItem_td">{{ item.name }}</div>
                  <div class="tItem_td">{{ item.code }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getMakers">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
    },
    data () {
      return {
        resultFlag: false,
        code: '',
        name: '',
        status: '選択しない',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getMakers(page = 1, is_csv = false) {
        var condition = {
          page: page,
          name: this.name,
          status: this.status === '選択しない' ? '' : this.status,
          big_category_code: this.bigCategoryCode,
          middle_category_code: this.middleCategoryCode,
          code: this.code,
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/index_category',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage   = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount  = response.data.meta['total-count']
            if (is_csv) {
              this.handleCsvDownload()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('メーカー情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            code: res[i].attributes["code"],
            name: res[i].attributes["name"],
            status: res[i].attributes["status"] === 'active' ? '有効' : '無効',
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleCsvDownload () {
        swal({
          title: `対象の${this.pageData.totalCount}件をCSV出力しますか？`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          code: this.code,
          name: this.name,
          status: this.status === '選択しない' ? '' : this.status,
          big_category_code: this.bigCategoryCode,
          middle_category_code: this.middleCategoryCode
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/product_categories/export_categories_csv',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "AdminUser not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      }
    },
    mounted () {
      // role check
      // if (this.shopFlag) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
      if (this.$route.query.user_id) {
        this.userId = this.$route.query.user_id
        this.getMakers()
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.formLayout{
  flex-basis: auto;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
