<template>
  <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <md-card-header class="md-card-header-icon md-card-header-green center">
          <h3 class="title">修理依頼用QRコード</h3>
          <h5 class="title">商品名: {{productName}}</h5>
          <h5 class="title">顧客名: {{fullName}}</h5>
          <h5 class="title">型式: {{productSku}}</h5>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          
          <div class="md-layout md-layout-item md-size-100 center-img">
            <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!qrCode">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="qrCode" />
                </div>
              </div>
            </div>
          </div>

          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <a :href="qrCode" download><md-button class="qr-purple md-lg">PNGダウンロード</md-button></a>
          </md-card-actions>

        </md-card-content>
        
      </div>
    </transition>
  </md-card>
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        qrCode: '',
        productName: '',
        productSku: '',
        fullName: '',
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    methods: {
      getEcOrderQrImage (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/ec_order_details/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.qrCode       = response.data.qr_code
          this.productName  = response.data.product_name
          this.productSku   = response.data.product_sku
          this.fullName     = response.data.full_name
          this.detailBgFlag = true
          this.detailFlag   = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('修理依頼用QRコードを取得できませんでした')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    },
    watch: {
    }
  }
</script>
<style scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.formLayout{
  flex-basis: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 12px !important;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.center {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.mL80 {
  margin-left: 110px !important;
}

.mt60{
  margin-top: 60px;
}

.betweenTime{
  line-height: 59px;
}

.qr-purple {
  background: #AA09B6 !important;
}

.center-img {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.image-container{
  max-width: none;
}

.md-lg {
  padding: 9px 36px !important;
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>