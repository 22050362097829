import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LoginRemind from '../views/LoginRemind.vue'
import LoginRemindPassword from '../views/LoginRemindPassword.vue'
import DashboardLayout from '../views/layouts/DashboardLayout.vue'
import Top from '../views/Top.vue'
import ProductList from '../views/ProductList.vue'
import Product from '../views/Product.vue'
import SpecValuesEdit from '../views/SpecValuesEdit.vue'
import SpecLabelEdit from '../views/SpecLabelEdit.vue'
import SpecSearchSettings from '../views/SpecSearchSettings.vue'
import SpecSearchSettingsEdit from '../views/SpecSearchSettingsEdit.vue'
import OrderList from '../views/OrderList.vue'
import Order from '../views/Order.vue'
import OrderCsvDownload from '../views/OrderCsvDownload.vue'
import OrderDetailList from '../views/OrderDetailList.vue'
import EcOrderList from '../views/EcOrderList.vue'
import EcOrder from '../views/EcOrder.vue'
import EcOrderCsvDownload from '../views/EcOrderCsvDownload.vue'
import EcOrderDetailList from '../views/EcOrderDetailList.vue'
import Room from '../views/Room.vue'
import CategoryList from '../views/CategoryList.vue'
import CategorySearch from '../views/CategorySearch.vue'
import CategoryCsvDownload from '../views/CategoryCsvDownload.vue'
import HouseMoney from '../views/HouseMoney.vue'
import HouseMoneyPayment from '../views/HouseMoneyPayment.vue'
import HouseMoneyCharge from '../views/HouseMoneyCharge.vue'
import UserList from '../views/UserList.vue'
import User from '../views/User.vue'
import ManufacturerList from '../views/ManufacturerList.vue'
import Manufacturer from '../views/Manufacturer.vue'
import ManufacturerCsvDownload from '../views/ManufacturerCsvDownload.vue'
import ProductBulkRegistration from '../views/ProductBulkRegistration.vue'
import ImageBulkRegistration from '../views/ImageBulkRegistration.vue'
import ProductCsvDownload from '../views/ProductCsvDownload.vue'
import ZipCodeRegistration from '../views/ZipCodeRegistration.vue'
import PreOrder from '../views/PreOrder.vue'
import ShopOrder from '../views/ShopOrder.vue'
import ReserveOrder from '../views/ReserveOrder.vue'
import CompanyService from '../views/CompanyService.vue'
import CompanyDelivery from '../views/CompanyDelivery.vue'
import CompanyApplication from '../views/CompanyApplication.vue'
import Notification from '../views/Notification.vue'
import MailMagazine from '../views/MailMagazine.vue'
import InformationList from '../views/InformationList.vue'
import Information from '../views/Information.vue'
import CampaignList from '../views/CampaignList.vue'
import Campaign from '../views/Campaign.vue'
import ShopList from '../views/ShopList.vue'
import Shop from '../views/Shop.vue'
import Calendar from '../views/Calendar.vue'
import AdminUserList from '../views/AdminUserList.vue'
import AdminUser from '../views/AdminUser.vue'
import AccessHistory from '../views/AccessHistory.vue'
import UserRankList from '../views/UserRankList.vue'
import UserRank from '../views/UserRank.vue'
import PointRate from '../views/PointRate.vue'
import StampRate from '../views/StampRate.vue'
import BillingStatement from '../views/BillingStatement.vue'
import QaCategoryList from '../views/QaCategoryList.vue'
import QaCategory from '../views/QaCategory.vue'
import QaInformationList from '../views/QaInformationList.vue'
import QaInformation from '../views/QaInformation.vue'
import ContactCategoryList from '../views/ContactCategoryList.vue'
import ContactCategory from '../views/ContactCategory.vue'
import ContactList from '../views/ContactList.vue'
import Contact from '../views/Contact.vue'
import Lease from '../views/Lease.vue'
import Delivery from '../views/Delivery.vue'
import Cookies from 'js-cookie';
import CategoryRegistration from '../views/CategoryRegistration.vue'
import SpecialPageList from '../views/SpecialPageList.vue'
import SpecialPage from '../views/SpecialPage.vue'
import SpecialTop from '../views/SpecialTop.vue'

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/admin/login' , component: Login },
    { path: '/admin/login_remind' , component: LoginRemind },
    { path: '/admin/login_remind_password' , component: LoginRemindPassword },
    { path: '/admin/sessions/new' , component: Login },
    {
      path: '/admin/',
      component: DashboardLayout,
      redirect: '/admin/top',
      name: 'dashbord',
      children: [
        {
          path: 'top',
          name: 'top',
          components: {default: Top}
        },
        {
          path: 'product_list',
          name: 'product_list',
          components: {default: ProductList},
        },
        {
          path: 'product/edit/:id',
          name: 'product',
          components: {default: Product}
        },
        {
          path: 'product/new',
          name: 'product',
          components: {default: Product}
        },
        {
          path: 'product/spec_values_edit/:id',
          name: 'product',
          components: {default: SpecValuesEdit}
        },
        {
          path: 'spec_label_edit',
          name: 'spec_label_edit',
          components: {default: SpecLabelEdit}
        },
        {
          path: 'spec_search_settings/:id',
          name: 'spec_search_settings',
          components: {default: SpecSearchSettings}
        },
        {
          path: 'spec_search_settings/:spec_search_setting_id/edit/:id',
          name: 'spec_search_settings',
          components: {default: SpecSearchSettingsEdit}
        },
        {
          path: 'order_list',
          name: 'order_list',
          components: {default: OrderList}
        },
        {
          path: 'order/edit/:id',
          name: 'order',
          components: {default: Order}
        },
        {
          path: 'orders/:id/chat',
          name: 'room',
          components: {default: Room}
        },
        {
          path: 'order_csv_download',
          name: 'order_csv_download',
          components: {default: OrderCsvDownload}
        },
        {
          path: 'order_details',
          name: 'order_details',
          components: {default: OrderDetailList}
        },
        {
          path: 'ec_order_list',
          name: 'ec_order_list',
          components: {default: EcOrderList}
        },
        {
          path: 'ec_order/edit/:id',
          name: 'ec_order',
          components: {default: EcOrder}
        },
        {
          path: 'ec_order_csv_download',
          name: 'ec_order_csv_download',
          components: {default: EcOrderCsvDownload}
        },
        {
          path: 'ec_order_details',
          name: 'ec_order_details',
          components: {default: EcOrderDetailList}
        },
        {
          path: 'category_list',
          name: 'category_list',
          components: {default: CategoryList}
        },
        {
          path: 'category_search',
          name: 'category_search',
          components: {default: CategorySearch}
        },
        {
          path: 'category_registration',
          name: 'category_registration',
          components: {default: CategoryRegistration}
        },
        {
          path: 'category_csv_download',
          name: 'category_csv_download',
          components: {default: CategoryCsvDownload}
        },
        {
          path: 'house_money/:type/:id',
          name: 'house_money',
          components: {default: HouseMoney}
        },
        {
          path: 'house_money_detail/charge/:id',
          name: 'house_money_detail_charge',
          components: {default: HouseMoneyCharge}
        },
        {
          path: 'house_money_detail/payment/:id',
          name: 'house_money_detail_payment',
          components: {default: HouseMoneyPayment}
        },
        {
          path: 'user_list',
          name: 'user_list',
          components: {default: UserList}
        },
        {
          path: 'user/edit/:id',
          name: 'user',
          components: {default: User}
        },
 
        {
          path: 'user_rank_list',
          name: 'user_rank_list',
          components: {default: UserRankList}
        },
        {
          path: 'user_rank/edit/:id',
          name: 'user_rank_edit',
          components: {default: UserRank}
        },
        {
          path: 'manufacturer_list',
          name: 'manufacturer_list',
          components: {default: ManufacturerList}
        },
        {
          path: 'manufacturer',
          name: 'manufacturer',
          components: {default: Manufacturer}
        },
        {
          path: 'manufacturer_csv_download',
          name: 'manufacturer_csv_download',
          components: {default: ManufacturerCsvDownload}
        },
        {
          path: 'product_bulk_registration',
          name: 'product_bulk_registration',
          components: {default: ProductBulkRegistration}
        },
        {
          path: 'product_csv_download',
          name: 'product_csv_download',
          components: {default: ProductCsvDownload}
        },
        {
          path: 'zip_code_registration',
          name: 'zip_code_registration',
          components: {default: ZipCodeRegistration}
        },
        {
          path: 'image_bulk_registration',
          name: 'image_bulk_registration',
          components: {default: ImageBulkRegistration}
        },
        {
          path: 'pre_order/edit/:id',
          name: 'pre_order_edit',
          components: {default: PreOrder}
        },
        {
          path: 'reserve_order/edit/:id',
          name: 'reserve_order',
          components: {default: ReserveOrder}
        },
        {
          path: 'shop_order/edit/:id',
          name: 'shop_order',
          components: {default: ShopOrder}
        },
        {
          path: 'company/service/edit/:id',
          name: 'company_service_edit',
          components: {default: CompanyService}
        },
        {
          path: 'company/delivery/edit/:id',
          name: 'company_delivery_edit',
          components: {default: CompanyDelivery}
        },
        {
          path: 'company/application/edit/:id',
          name: 'company_application_edit',
          components: {default: CompanyApplication}
        },
        {
          path: 'notification/edit/:id',
          name: 'notification',
          components: {default: Notification}
        },
        {
          path: 'mail_magazine/edit/:id',
          name: 'mail_magazine',
          components: {default: MailMagazine}
        },
        {
          path: 'information_list',
          name: 'information_List',
          components: {default: InformationList}
        },
        {
          path: 'information/edit/:id',
          name: 'information',
          components: {default: Information}
        },
        {
          path: 'information/new',
          name: 'information',
          components: {default: Information}
        },
        {
          path: 'campaign_list',
          name: 'campaign_List',
          components: {default: CampaignList}
        },
        {
          path: 'campaign/new',
          name: 'campaign',
          components: {default: Campaign}
        },
        {
          path: 'campaign/edit/:id',
          name: 'campaign_edit',
          components: {default: Campaign}
        },
        {
          path: 'point_rate/edit/:id',
          name: 'point_rate',
          components: {default: PointRate}
        },
        {
          path: 'stamp_rate/edit/:id',
          name: 'stamp_rate',
          components: {default: StampRate}
        },
        {
          path: 'shop_list',
          name: 'shop_list',
          components: {default: ShopList}
        },
        {
          path: 'shop/new',
          name: 'shop',
          components: {default: Shop}
        },
        {
          path: 'shop/edit/:id',
          name: 'shop',
          components: {default: Shop}
        },
        {
          path: 'shop/calendar/edit/:id',
          name: 'calendar',
          components: {default: Calendar}
        },
        {
          path: 'staff/calendar/edit/:id',
          name: 'calendar',
          components: {default: Calendar}
        },
        {
          path: 'admin_user_list',
          name: 'admin_user_list',
          components: {default: AdminUserList}
        },
        {
          path: 'admin_user/history/:id',
          name: 'admin_user_history',
          components: {default: AccessHistory}
        },
        {
          path: 'admin_user/edit/:id',
          name: 'admin_user_edit',
          components: {default: AdminUser}
        },
        {
          path: 'admin_user/new',
          name: 'admin_user_new',
          components: {default: AdminUser}
        },
        {
          path: 'qa_category_list',
          name: 'qa_category_list',
          components: {default: QaCategoryList}
        },
        {
          path: 'qa_category/new',
          name: 'qa_category',
          components: {default: QaCategory}
        },
        {
          path: 'qa_category/edit/:id',
          name: 'qa_category',
          components: {default: QaCategory}
        },
        {
          path: 'qa_information_list',
          name: 'qa_information_list',
          components: {default: QaInformationList}
        },
        {
          path: 'qa_information/edit/:id',
          name: 'qa_information',
          components: {default: QaInformation}
        },
        {
          path: 'qa_information/new',
          name: 'qa_information',
          components: {default: QaInformation}
        },
        {
          path: 'contact_category_list',
          name: 'contact_category_list',
          components: {default: ContactCategoryList}
        },
        {
          path: 'contact_category/new',
          name: 'contact_category',
          components: {default: ContactCategory}
        },
        {
          path: 'contact_category/edit/:id',
          name: 'contact_category',
          components: {default: ContactCategory}
        },
        {
          path: 'contact_list',
          name: 'contact_list',
          components: {default: ContactList}
        },
        {
          path: 'contact/edit/:id',
          name: 'contact',
          components: {default: Contact}
        },
        {
          path: 'lease',
          name: 'lease',
          components: {default: Lease}
        },
        {
          path: 'delivery',
          name: 'delivery',
          components: {default: Delivery}
        },
        {
          path: 'special_page_list',
          name: 'special_page_list',
          components: {default: SpecialPageList}
        },
        {
          path: 'special_page/new',
          name: 'special_page',
          components: {default: SpecialPage}
        },
        {
          path: 'special_top/:id',
          name: 'special_top',
          components: {default: SpecialTop}
        },
        {
          path: 'special_page/edit/:id',
          name: 'special_page',
          components: {default: SpecialPage}
        },
        // {
        //   path: 'billing_statement_list',
        //   name: 'billing_statement_list',
        //   components: {default: BillingStatementList}
        // },
        {
          path: 'billing_statement/:id',
          name: 'billing_statement',
          components: {default: BillingStatement}
        }
      ],beforeEnter( to, from, next){
        if (Cookies.get('accessToken') != null && new Date() <= new Date(Cookies.get('accessTokenExpireDate'))){
          next()
        } else{
          router.push({ path: '/admin/login' })
        }
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
export default router
