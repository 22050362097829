<template>
  <!-- <div> -->
    <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{ productCode }}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品URL
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productUrl')},
                {'md-valid': !errors.has('productUrl') && touched.productUrl}]">
                <md-input
                  v-model="productUrl"
                  data-vv-name="productUrl"
                  type="text"
                  v-validate= "modelValidations.productUrl"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productUrl')">【必須】2083文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productUrl') && touched.productUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productName')},
                {'md-valid': !errors.has('productName') && touched.productName}]">
                <md-input
                  v-model="productName"
                  data-vv-name="productName"
                  type="text"
                  v-validate= "modelValidations.productName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productName')">【必須255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productName') && touched.productName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              型式
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productSku')},
                {'md-valid': !errors.has('productSku') && touched.productSku}]">
                <md-input
                  v-model="productSku"
                  data-vv-name="productSku"
                  type="text"
                  v-validate= "modelValidations.productSku"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productSku')">【必須】20文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productSku')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productSku') && touched.productSku">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メーカー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('makerName')},
                {'md-valid': !errors.has('makerName') && touched.makerName}]">
                <md-input
                  v-model="makerName"
                  data-vv-name="makerName"
                  type="text"
                  v-validate= "modelValidations.makerName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('makerName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('makerName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('makerName') && touched.makerName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注先
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productSupplierName')},
                {'md-valid': !errors.has('productSupplierName') && touched.productSupplierName}]">
                <md-input
                  v-model="productSupplierName"
                  data-vv-name="productSupplierName"
                  type="text"
                  v-validate= "modelValidations.productSupplierName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productSupplierName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productSupplierName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productSupplierName') && touched.productSupplierName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="no_order">未発注</md-option>
                  <md-option value="order">発注</md-option>
                  <md-option value="complete_del">納品済み</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注数
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productNum')},
                {'md-valid': !errors.has('productNum') && touched.productNum}]">
                <md-input
                  v-model="productNum"
                  data-vv-name="productNum"
                  type="number"
                  v-validate= "modelValidations.productNum"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productNum')">【必須】必ず入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productNum')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productNum') && touched.productNum" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">個</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              サブスク本体価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subscPrice')},
                {'md-valid': !errors.has('subscPrice') && touched.subscPrice}]">
                <md-input
                  v-model="subscPrice"
                  data-vv-name="subscPrice"
                  type="number"
                  v-validate= "modelValidations.subscPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subscPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subscPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subscPrice') && touched.subscPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              サブスク<br>タイムセール価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subscTimePrice')},
                {'md-valid': !errors.has('subscTimePrice') && touched.subscTimePrice}]">
                <md-input
                  v-model="subscTimePrice"
                  data-vv-name="subscTimePrice"
                  type="number"
                  v-validate= "modelValidations.subscTimePrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subscTimePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subscTimePrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subscTimePrice') && touched.subscTimePrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              保守<br>オプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('maintenancePrice')},
                {'md-valid': !errors.has('maintenancePrice') && touched.maintenancePrice}]">
                <md-input
                  v-model="maintenancePrice"
                  data-vv-name="maintenancePrice"
                  type="number"
                  v-validate= "modelValidations.maintenancePrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('maintenancePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('maintenancePrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('maintenancePrice') && touched.maintenancePrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取り付け<br>オプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('attachmentPrice')},
                {'md-valid': !errors.has('attachmentPrice') && touched.attachmentPrice}]">
                <md-input
                  v-model="attachmentPrice"
                  data-vv-name="attachmentPrice"
                  type="number"
                  v-validate= "modelValidations.attachmentPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('attachmentPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('attachmentPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('attachmentPrice') && touched.attachmentPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション名1
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionName1')},
                {'md-valid': !errors.has('optionName1') && touched.optionName1}]">
                <md-input
                  v-model="optionName1"
                  data-vv-name="optionName1"
                  type="text"
                  v-validate= "modelValidations.optionName1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName1')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName1') && touched.optionName1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション価格1
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionPrice1')},
                {'md-valid': !errors.has('optionPrice1') && touched.optionPrice1}]">
                <md-input
                  v-model="optionPrice1"
                  data-vv-name="optionPrice1"
                  type="number"
                  v-validate= "modelValidations.optionPrice1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice1')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice1') && touched.optionPrice1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション名2
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionName2')},
                {'md-valid': !errors.has('optionName2') && touched.optionName2}]">
                <md-input
                  v-model="optionName2"
                  data-vv-name="optionName2"
                  type="text"
                  v-validate= "modelValidations.optionName2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName2')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName2') && touched.optionName2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション価格2
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionPrice2')},
                {'md-valid': !errors.has('optionPrice2') && touched.optionPrice2}]">
                <md-input
                  v-model="optionPrice2"
                  data-vv-name="optionPrice2"
                  type="number"
                  v-validate= "modelValidations.optionPrice2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice2')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice2') && touched.optionPrice2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション名3
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionName3')},
                {'md-valid': !errors.has('optionName3') && touched.optionName3}]">
                <md-input
                  v-model="optionName3"
                  data-vv-name="optionName3"
                  type="text"
                  v-validate= "modelValidations.optionName3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName3')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName3')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName3') && touched.optionName3">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              オプション価格3
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('optionPrice3')},
                {'md-valid': !errors.has('optionPrice3') && touched.optionPrice3}]">
                <md-input
                  v-model="optionPrice3"
                  data-vv-name="optionPrice3"
                  type="number"
                  v-validate= "modelValidations.optionPrice3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice3')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice3')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice3') && touched.optionPrice3" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              値引率
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('discountRate')},
                {'md-valid': !errors.has('discountRate') && touched.discountRate}]">
                <md-input
                  v-model="discountRate"
                  data-vv-name="discountRate"
                  type="number"
                  v-validate= "modelValidations.discountRate"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('discountRate')">【必須】100以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('discountRate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('discountRate') && touched.discountRate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">%</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              明細リース<br>価格合計(税込)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('totalOrderPriceTax')},
                {'md-valid': !errors.has('totalOrderPriceTax') && touched.totalOrderPriceTax}]">
                <md-input
                  v-model="totalOrderPriceTax"
                  data-vv-name="totalOrderPriceTax"
                  type="number"
                  v-validate= "modelValidations.totalOrderPriceTax"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('totalOrderPriceTax')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('totalOrderPriceTax')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('totalOrderPriceTax') && touched.totalOrderPriceTax" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          </md-card-actions>

        </md-card-content>
        
      </div>
    </transition>
    </md-card>
  <!-- </div> -->
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        productCode: '',
        productUrl: '',
        productName: '',
        productSku: '',
        makerName: '',
        productSupplierName: '',
        status: '',
        productNum: '',
        subscPrice: '',
        subscTimePrice: '',
        maintenancePrice: '',
        attachmentPrice: '',
        optionName1: '',
        optionPrice1: '',
        optionName2: '',
        optionPrice2: '',
        optionName3: '',
        optionPrice3: '',
        discountRate: '',
        totalOrderPriceTax: '',
        touched: {
          productUrl: false,
          productName: false,
          productSku: false,
          makerName: false,
          productSupplierName: false,
          status: false,
          productNum: false,
          subscPrice: false,
          subscTimePrice: false,
          maintenancePrice: false,
          attachmentPrice: false,
          optionName1: false,
          optionPrice1: false,
          optionName2: false,
          optionPrice2: false,
          optionName3: false,
          optionPrice3: false,
          discountRate: false,
          totalOrderPriceTax: false
        },
        modelValidations: {
          productUrl:{
            max: 2083
          },
          productName:{
            max: 255,
            required: true
          },
          productSku:{
            max: 200,
            required: true
          },
          makerName:{
            max: 255,
            required: true
          },
          productSupplierName:{
            max: 255
          },
          status:{
            required: true
          },
          productNum:{
            required: true
          },
          subscPrice:{
            max: 8,
            required: true,
            numeric: true
          },
          subscTimePrice:{
            max: 8,
            numeric: true
          },
          maintenancePrice:{
            max: 8,
            numeric: true
          },
          attachmentPrice:{
            max: 8,
            numeric: true
          },
          optionName1:{
            max: 255
          },
          optionPrice1:{
            max: 8,
            numeric: true
          },
          optionName2:{
            max: 255
          },
          optionPrice2:{
            max: 8,
            numeric: true
          },
          optionName3:{
            max: 255
          },
          optionPrice3:{
            max: 8,
            numeric: true
          },
          discountRate:{
            max: 3
          },
          totalOrderPriceTax:{
            max: 8,
            required: true,
            numeric: true
          }
        },
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    methods: {
      getOrderMoreDetails (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/order_details/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id                   = response.data.id
          this.productCode          = response.data.product_code
          this.productUrl           = response.data.product_url
          this.productName          = response.data.product_name
          this.productSku           = response.data.product_sku
          this.makerName            = response.data.maker_name
          this.productSupplierName  = response.data.product_supplier_name
          this.status               = response.data.status
          this.productNum           = response.data.product_num
          this.subscPrice           = Math.floor(response.data.subsc_price)
          this.subscTimePrice       = Math.floor(response.data.subsc_time_price)
          this.maintenancePrice     = Math.floor(response.data.maintenance_price)
          this.attachmentPrice      = Math.floor(response.data.attachment_price)
          this.optionName1          = response.data.option_name1
          this.optionPrice1         = Math.floor(response.data.option_price1)
          this.optionName2          = response.data.option_name2
          this.optionPrice2         = Math.floor(response.data.option_price2)
          this.optionName3          = response.data.option_name3
          this.optionPrice3         = Math.floor(response.data.option_price3)
          this.discountRate         = response.data.discount_rate
          this.totalOrderPriceTax   = Math.floor(response.data.total_order_price_tax)
          this.detailBgFlag         = true
          this.detailFlag           = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('取引明細情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          order_detail: {
            id:                     this.id,
            product_code:           this.productCode,
            product_url:            this.productUrl,
            product_name:           this.productName,
            product_sku:            this.productSku,
            maker_name:             this.makerName,
            product_supplier_name:  this.productSupplierName,
            status:                 this.status,
            product_num:            this.productNum,
            subsc_price:            this.subscPrice,
            subsc_time_price:       this.subscTimePrice,
            maintenance_price:      this.maintenancePrice,
            attachment_price:       this.attachmentPrice,
            option_name1:           this.optionName1,
            option_price1:          this.optionPrice1,
            option_name2:           this.optionName2,
            option_price2:          this.optionPrice2,
            option_name3:           this.optionName3,
            option_price3:          this.optionPrice3,
            discount_rate:          this.discountRate,
            total_order_price_tax:  this.totalOrderPriceTax,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/order_details/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`取引明細情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('取引明細情報の更新に失敗しました')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    },
    watch: {
      productUrl(){
        this.touched.productUrl = true
      },
      productName(){
        this.touched.productName = true
      },
      productSku(){
        this.touched.productSku = true
      },
      makerName(){
        this.touched.makerName = true
      },
      productSupplierName(){
        this.touched.productSupplierName = true
      },
      status(){
        this.touched.status = true
      },
      productNum(){
        this.touched.productNum = true
      },
      subscPrice(){
        this.touched.subscPrice = true
      },
      subscTimePrice(){
        this.touched.subscTimePrice = true
      },
      maintenancePrice(){
        this.touched.maintenancePrice = true
      },
      attachmentPrice(){
        this.touched.attachmentPrice = true
      },
      optionName1(){
        this.touched.optionName1 = true
      },
      optionPrice1(){
        this.touched.optionPrice1 = true
      },
      optionName2(){
        this.touched.optionName2 = true
      },
      optionPrice2(){
        this.touched.optionPrice2 = true
      },
      optionName3(){
        this.touched.optionName3 = true
      },
      optionPrice3(){
        this.touched.optionPrice3 = true
      },
      discountRate(){
        this.touched.discountRate = true
      },
      totalOrderPriceTax(){
        this.touched.totalOrderPriceTax = true
      }
    }
  }
</script>
<style scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.formLayout{
  flex-basis: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 12px !important;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.md-form-label {
  padding: 10px 5px 0 0 !important;
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.md-select-value {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.mL80 {
  margin-left: 110px !important;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.betweenTime{
  line-height: 59px;
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>