<template>
  <rate-detail :rateData="rateData"></rate-detail>
</template>
<script>
import RateDetail from '../components/additional/RateDetail.vue'

export default {
  components: {
    RateDetail,
  },
  data () {
    return { 
      rateData: {
        title: "ポイント",
        returnBack: '/admin/point_rate_list/',
        apiUrl: '/point_rates/'
      }}
  }
}
</script>
