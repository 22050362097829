<template>
  <div class="md-layout">

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">商品一括登録</h4>
        </md-card-header>


        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
              <div>
                <div class="csv-align">
                  <label class="md-form-label" >{{productsCsv.file_name}}</label>
                  <md-button md-alignment="left" class="md-success md-round md-fileinput">
                    <template>ファイル選択</template>
                    <input type="file" @change="selectedFile" data-id="productsCsv">
                  </md-button>
                </div>
              </div>
              <ul class="imgAttention">
                <li>ファイル選択後にアップロードファイルを押してください</li>
                <li>完了後にメールが届きます。正しくアップロードされない場合ファイルの修正をしてください</li>
                <li>対応ファイル：csv</li>
                <li>対応エンコード：utf-8</li>
              </ul>
              <download-csv :data = "json_data" name = "products.csv">
                <p class="text-info">ファイルレイアウトを確認する</p>
              </download-csv>
              <p><a href="https://docs.google.com/document/d/19J2rh-RVzBpvCi_UpugF9YOHI2j3jQVatQs3GkXmziM/edit" target="_blank">CSVの説明書を確認する</a></p>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" @click.native="uploadCsv('productsCsv')" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>

    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {

    },
    data () {
      return {
        buttonWord: 'アップロード',
        productsCsv: {
          path: '/products',
          file: '',
          file_name: ''
        },
        json_data: [
            {
              '処理区分': '追加',
              'メーカーコード': 'hosizaki',
              '商品コード': 'SAMURAI-14',
              '商品名': 'サムライ冷蔵庫',
              '型式': 'SAMURAI-14',
              '商品説明': 'こちらは冷蔵庫です',
              '検索文章': '冷蔵庫, ホシザキ, サムライ, hosizaki, samurai',
              '商品URL': 'https://example.com',
              '公開先': 2,
              'ステータス': 1,
              '希望小売価格': 400000,
              '仕切率': 0.5,
              '仕入値': 100000,
              'サブ掛率': 0.65,
              'サブスク本体価格': 300000,
              '通販掛率': 0.85,
              '通販価格' : 300000,
              '保守オプション価格': 20000,
              '取り付けオプション価格': 10000,
              'オプション名1': 'サブスクオプション1',
              'オプション価格1': 10000,
              'オプション名2': 'サブスクオプション2',
              'オプション価格2': 20000,
              'オプション名3': 'サブスクオプション3',
              'オプション価格3': 30000,
              'サブスクタイムセール本体価格': 280000,
              'サブスクタイムセール開始': '2023-08-30 15:00:00',
              'サブスクタイムセール終了': '2023-12-30 16:00:00',
              '大カテゴリーコード': '1',
              '中カテゴリーコード': '101',
              '小カテゴリーコード': '10101',
              'スペック表1': '100φ',
              'スペック表2': '100',
              'スペック表3': '300',
              'スペック表4': '400',
              'スペック表5': '500',
              'スペック表6': '600',
              'スペック表7': '700',
              'スペック表8': '800',
              'スペック表9': '900',
              'スペック表10': '1000',
              'スペック表11': '1100',
              'スペック表12': '1200',
              'スペック表13': '1300',
              'スペック表14': '1400',
              'スペック表15': '1500',
              'スペック表16': '1600',
              'スペック表17': '1700',
              'スペック表18': '1800',
              'スペック表19': '1900',
              'スペック表20': '2000',
              'スペック表21': '2100',
              'スペック表22': '2200',
              'スペック表23': '2300',
              'スペック表24': '2400',
              'スペック表25': '2500',
              '発注先': 'テスト発注先',
              'FAX/TEL': '="09022223333"',
              '郵便番号': '1112222',
              '住所': '東京都渋谷区123',
              '通販タイムセール価格' : 280000,
              '通販タイムセール開始日' : '2023-08-30 03:00:00',
              '通販タイムセール終了日' : '2023-12-30 04:00:00',
              '通販保守オプション価格' : 20000,
              '通販取り付けオプション価格' : 10000,
              '通販オプション名1' : '通販オプション1',
              '通販オプション価格1' : 10000,
              '通販オプション名2' : '通販オプション2',
              '通販オプション価格2' : 20000,
              '通販オプション名3' : '通販オプション3',
              '通販オプション価格3' : 30000,
              '通販オプション価格3' : 30000,
              '通販オプション価格3' : 30000,
              '優先表示順' : 1,
              '優先表示開始' : '2023-08-30 00:00:00',
              '優先表示終了' : '2023-12-30 00:00:00',
            },
        ],
      }
    },
    methods: {
      selectedFile(e){
        e.preventDefault();
        let files = e.target.files;
        let data_id = e.currentTarget.getAttribute('data-id');
        this[data_id].file = files[0];
        this[data_id].file_name = files[0].name;
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateService()
          } else {
            this.validateError()
          }
        })
      },
      uploadCsv(csv_conf){
        let formData = new FormData();
        if(this[csv_conf].file_name.split('.').pop() !== 'csv'){
          this.dangerNotify("CSVファイルを選択してください")
          return 
        }
        formData.append('file', this[csv_conf].file);
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + this[csv_conf].path + '/import_csv',
          timeout : 30000,
          data: formData,
          headers : {'content-type': 'multipart/form-data' , 'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage("CSVのアップロードが完了しました")
          this[csv_conf].file == ''
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify("CSVのアップロードが失敗しました")
          }
        })
      }
    },
    mounted () {
      // if (!this.superFlag) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
    },
    watch: {

    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.formLayout{
  flex-basis: auto;
}

.labels{
  color: #666666;
  font-weight: 400;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  padding-bottom: 10px;
}

.imageWidth{
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto
}

.csv-align {
  align-items: left;
}

.image-container{
  max-width: none;
}



.BtnAdd,
.BtnRemove{
  color: #666666;
  padding: 3px 0;
  line-height: 24px;
  cursor: pointer;
}


.md-layout-item.md-size-walk{
  min-width: 33%;
  max-width: 33%;
  flex: 0 1 33%;
}

.w200{
  width: 200px;
}

.imgAttention{
  font-size: 12px;
  color: #333333;
  padding-left: 10px;
  list-style: none;
}

.imgAttention li{
  padding-left: 10px;
  position: relative;
  line-height: 18px;
}

.imgAttention li:before{
  content: '*';
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .md-layout-item.md-size-walk{
    min-width: 100%;
    max-width: 100%;
    flex: 0 1 100%;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .imageWidth{
    width: auto;
  }

  .imageWalk{
    width: auto
  }

  .image-container{
    margin: 0 auto 10px;
  }
}

</style>
