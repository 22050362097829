<template>
  <div class="wrapper" :class="[{'nav-open': $sidebar.showSidebar}, {'rtl': $route.meta.rtlActive}]">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <template slot="links">
        <sidebar-item :link="{name: '商品管理', icon: 'local_grocery_store'}">
          <sidebar-item :link="{name: '商品一覧', path: '/admin/product_list'}"></sidebar-item>
          <sidebar-item :link="{name: '商品登録', path: '/admin/product/new'}"></sidebar-item>
          <sidebar-item :link="{name: 'スペック表ラベル編集', path: '/admin/spec_label_edit'}"></sidebar-item>
          <sidebar-item :link="{name: '商品一括登録', path: '/admin/product_bulk_registration'}"></sidebar-item>
          <sidebar-item :link="{name: '画像一括登録', path: '/admin/image_bulk_registration'}"></sidebar-item>
          <sidebar-item :link="{name: '商品CSVダウンロード', path: '/admin/product_csv_download'}"></sidebar-item>
          <sidebar-item :link="{name: 'メーカー一覧', path: '/admin/manufacturer_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'メーカー一括登録', path: '/admin/manufacturer'}"></sidebar-item>
          <sidebar-item :link="{name: 'メーカーCSVダウンロード', path: '/admin/manufacturer_csv_download'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '取引管理', icon: 'event'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: '取引一覧', path: '/admin/order_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'CSVダウンロード', path: '/admin/order_csv_download'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '注文管理', icon: 'event'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: '注文一覧', path: '/admin/ec_order_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'CSVダウンロード', path: '/admin/ec_order_csv_download'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '発注管理', icon: 'message'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: '取引(注文)明細一覧', path: '/admin/order_details'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '商品カテゴリー', icon: 'dns'}">
          <sidebar-item :link="{name: '商品カテゴリー管理', path: '/admin/category_list'}" ></sidebar-item>
          <sidebar-item :link="{name: 'カテゴリー一覧', path: '/admin/category_search'}" ></sidebar-item>
          <sidebar-item :link="{name: 'カテゴリー一括登録', path: '/admin/category_registration'}" ></sidebar-item>
          <sidebar-item :link="{name: 'CSVダウンロード', path: '/admin/category_csv_download'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'ユーザー管理', icon: 'group'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: 'ユーザー一覧', path: '/admin/user_list'}"></sidebar-item>
          <sidebar-item :link="{name: '設置場所(配送先)一覧', path: '/admin/shop_list'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: '販促管理', icon: 'message'}">
          <sidebar-item :link="{name: 'お知らせ一覧', path: '/admin/information_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'お知らせ新規登録', path: '/admin/information/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'キャンペーン管理', icon: 'message'}">
          <sidebar-item :link="{name: 'キャンペーン一覧', path: '/admin/campaign_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'キャンペーン登録', path: '/admin/campaign/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'QA管理', icon: 'question_answer'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: 'QAカテゴリ一覧', path: '/admin/qa_category_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'QA情報一覧', path: '/admin/qa_information_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'QA情報登録', path: '/admin/qa_information/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'お問合せ管理', icon: 'contact_mail'}" v-if="superFlag || companyFlag">
          <sidebar-item :link="{name: 'お問合せカテゴリ一覧', path: '/admin/contact_category_list'}"></sidebar-item>
          <sidebar-item :link="{name: 'お問合せ一覧', path: '/admin/contact_list'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="superFlag || companyFlag"
          :link="{name: 'サービス管理', icon: 'business', path: '/widgets'}">
          <sidebar-item v-if="superFlag" :link="{name: '基本情報設定', path: '/admin/company/service/edit/' + this.$Cookies.get('companyId')}"></sidebar-item>
          <sidebar-item v-if="superFlag" :link="{name: 'リース料設定', path: '/admin/lease'}"></sidebar-item>
          <sidebar-item v-if="superFlag" :link="{name: '配送料設定', path: '/admin/delivery'}"></sidebar-item>
          <sidebar-item :link="{name: '郵便番号一括登録', path: '/admin/zip_code_registration'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: '特集管理', icon: 'note'}">
          <sidebar-item :link="{name: '特集ページ一覧', path: '/admin/special_page_list'}"></sidebar-item>
          <sidebar-item :link="{name: '特集ページ登録', path: '/admin/special_page/new'}"></sidebar-item>
          <sidebar-item :link="{name: 'TOP特集スライド', path: '/admin/special_top/' + this.$Cookies.get('companyId')}"></sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-if="((superFlag || companyFlag) && opt_adminUser)"
          :link="{name: 'システム管理', icon: 'widgets', path: '/widgets'}">
          <sidebar-item :link="{name: '管理ユーザー一覧', path: '/admin/admin_user_list'}"></sidebar-item>
          <sidebar-item :link="{name: '管理ユーザー新規登録', path: '/admin/admin_user/new'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'ログアウト', icon: 'exit_to_app', path: '/admin/login' }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div :class="{content: !$route.meta.hideContent}" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar'
  import 'perfect-scrollbar/css/perfect-scrollbar.css'

  function hasElement (className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar (className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from './extra/UserMenu.vue'
  import {SlideYDownTransition, ZoomCenterTransition} from 'vue2-transitions'
  import role from "../../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      TopNavbar,
      ContentFooter,
      UserMenu,
      DashboardContent,
      SlideYDownTransition,
      ZoomCenterTransition
    },
    data () {
      return {}
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
    },
    mounted () {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');
        initScrollbar('main-panel');

        docClasses.add('perfect-scrollbar-on')
      } else {
        docClasses.add('perfect-scrollbar-off')
      }
    }
  }

</script>
<style lang="scss">
  $scaleSize: .95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
  .sidebar {
    z-index: 9999 !important;
  }
</style>
