<template>
  <li :class="[{'timeline-inverted': inverted}, {'timeline-simple': !inverted}]">
    <!-- <slot name="badge">
      <div class="timeline-badge" :class="badgeType">
        <md-icon class="fa" :class="[{'fa-building': adminIcon}, {'fa-user': !adminIcon}]"></md-icon>
      </div>
    </slot> -->
    <div class="timeline-panel">
      <div class="timeline-heading">
        <slot name="header"></slot>
      </div>
      <div class="timeline-body" v-if="$slots.content">
        <slot name="content"></slot>
      </div>
      <h6 v-if="$slots.footer">
        <slot name="footer"></slot>
      </h6>
      <template v-if="$slots.button">
        <slot name="button"></slot>
      </template>
    </div>
  </li>
</template>
<script>
  export default {
    name: 'time-line-item',
    props: {
      inverted: Boolean,
      adminIcon: Boolean,
      // badgeType: {
      //   type: String,
      //   default: 'success'
      // },
      // badgeIcon: {
      //   type: String,
      //   default: ''
      // }
    }
  }
</script>
<style>
</style>
