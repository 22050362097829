<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">管理ユーザー一覧</h4>
        </md-card-header>
        <md-card-content class="tipsVisible">
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">ログイン名</div>
                <div class="tHead_th">氏名</div>
                <div class="tHead_th">店舗名</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th">登録日</div>
                <div class="tHead_th">最終ログイン日</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.login }}</md-label></div>
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.shopName }}</md-label></div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td">{{ item.contractDay | dateFormat }}</div>
                <div class="tItem_td">{{ item.lastDay | dateFormat }}</div>
                <div class="tItem_td taR td_actionTw">
                  <drop-down direction="up" class="dropDown">
                    <md-button slot="title" class="md-just-icon md-warning mg2 dropdown-toggle" data-toggle="dropdown">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="editUser(item.id)">編集</a></li>
                      <li><a @click="accessHistory(item.id)">情報・履歴</a></li>
                    </ul>
                  </drop-down>
                  <md-button
                    class="md-just-icon md-danger mg2"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter}}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getUsers">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + this.tableData.length
        }
      },
      from () {
        if (this.pageData.totalCount === 0) {
          return 0
        } else {
          return (this.pageData.currentPage - 1) * 25 + 1
        }
      }
    },
    data () {
      return {
        companyName: '',
        selectedStart: null,
        selectedEnd: null,
        currentSort: 'tel',
        currentSortOrder: 'asc',
        status: '',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      editUser (param) {
        const url = '/admin/admin_user/edit/' + param
        window.open(url, '_blank')
      },
      accessHistory (param) {
        const url = '/admin/admin_user/history/' + param
        window.open(url, '_blank')
      },
      getUsers (page) {
        var condition = {
          page: page
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/admin_users',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('管理ユーザー情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            login: res[i].attributes.login,
            name: res[i].attributes['last-name'] + ' ' + res[i].attributes['first-name'],
            shopName: res[i].attributes.shop !== null ? res[i].attributes.shop.name : '',
            status: res[i].attributes.status === 'approved' ? '有効' : '無効',
            contractDay: res[i].attributes['created-at'],
            lastDay: res[i].attributes['last-sign-in-at']
          }
          this.tableData.push(item)
        }
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleDelete (item) {
        swal({
          title: 'この管理ユーザーを削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteUser(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      deleteUser (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/admin_users/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            order_status : status
          }
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`管理ユーザー情報を削除しました`)
          this.getUsers()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('管理ユーザー情報を正しく削除できませんでした')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getUsers()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg2{
  margin: 0 2px;
}

.btnSpace{
  margin: 0 2px;
}
</style>
