<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">取引詳細</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">
                <a href="#" @click.stop.prevent="handleUserEdit(userId)"><u>{{fullName}}</u></a>
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{code}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="orderEmail"
                  data-vv-name="orderEmail"
                  type="text"
                  v-validate= "modelValidations.orderEmail"
                  required>
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引タイトル
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('orderTtl')},
                {'md-valid': !errors.has('orderTtl') && touched.orderTtl}]">
                <md-input
                  v-model="orderTtl"
                  data-vv-name="orderTtl"
                  type="text"
                  v-validate= "modelValidations.orderTtl"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('orderTtl')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('orderTtl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('orderTtl') && touched.orderTtl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{companyName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{shopName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('orderStatus')},
                {'md-valid': !errors.has('orderStatus') && touched.orderStatus}]">
                <md-select v-model="orderStatus"
                  data-vv-name="orderStatus"
                  v-validate= "modelValidations.orderStatus"
                  required>
                  <md-option value="estimate_mid">見積中</md-option>
                  <md-option value="estimate_after">見積後</md-option>
                  <md-option value="estimate_finish">見積終了</md-option>
                  <md-option value="application">正式申込</md-option>
                  <md-option value="delivery_preparation">配送準備中</md-option>
                  <md-option value="contract">契約中</md-option>
                  <md-option value="contract_finish">契約終了</md-option>
                  <md-option value="ng">キャンセル</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('orderStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('orderStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('orderStatus') && touched.orderStatus" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              固定電話(店舗電話番号)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{shopFixTel}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              見積もり依頼日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{orderDate}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              取引価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{totalPrice | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              値引き額
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{discount | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{deliveryFee | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              消費税
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{tax | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              見積もりリース価格/月(税込)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('estimatePrice')},
                {'md-valid': !errors.has('estimatePrice') && touched.estimatePrice}]">
                <md-input
                  v-model="estimatePrice"
                  data-vv-name="estimatePrice"
                  v-validate= "modelValidations.estimatePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('estimatePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('estimatePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('estimatePrice') && touched.estimatePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              正式申込日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{applicationDate}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              契約価格/月
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{contractPrice | numberWithDelimiter}} 円</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メモ・備考
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="memo" md-counter="255" maxlength="255"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              <a href="#"><u @click.prevent="getOrderDetails">さらに詳しく表示</u></a>
            </label>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          <md-button class="md-linkedin md-lg mL40" @click.native="jumpRoom(roomId, companyName, shopName, orderStatus, id)">チャット</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">取引明細一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">商品名</div>
                  <div class="tHead_th">型式</div>
                  <div class="tHead_th">発注ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.productName }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.productSku }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.orderDetailStatus }}</md-label></div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.prevent="getOrderMoreDetails(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0 qr-purple"
                      @click.prevent="getOrderQrImage(item.id)">
                      <md-icon>qr_code</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </transition>
    <md-card-actions md-alignment="left" class="mt60 mx-auto">
      <md-button class="md-warning md-lg" @click.prevent="getOrderPdfTmp(id)">見積もり審査用情報出力</md-button>
    </md-card-actions>
    <order-detail
      ref="orderDetail"
    ></order-detail>
    <order-more-detail
      ref="orderMoreDetail"
    ></order-more-detail>
    <order-qr-image
      ref="orderQrImage"
    ></order-qr-image>
    <order-pdf-tmp
      ref="orderPdfTmp"
    ></order-pdf-tmp>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import OrderDetail from '../components/additional/OrderDetail.vue'
  import OrderMoreDetail from '../components/additional/OrderMoreDetail.vue'
  import OrderQrImage from '../components/additional/OrderQrImage.vue'
  import OrderPdfTmp from '../components/additional/OrderPdfTmp.vue'
  import EventBus from '../mixins/event-bus.js';

  export default {
    mixins: [ role ],
    components: {
      OrderDetail,
      OrderMoreDetail,
      OrderQrImage,
      OrderPdfTmp
    },
    computed: {
    },
    data () {
      return {
        id: '',
        fullName: '',
        orderCode: '',
        orderEmail: '',
        orderTtl: '',
        shopName: '',
        shopFixTel: '',
        companyName: '',
        orderStatus: '',
        orderDate: '',
        totalPrice: '',
        discount: '',
        deliveryFee: '',
        tax: '',
        estimatePrice: '',
        applicationDate: '',
        contractPrice: '',
        memo: '',
        roomId: '',
        touched: {
          orderTtl: false,
          orderStatus: false,
        },
        modelValidations: {
          orderTtl: {
            max: 16
          },
          orderStatus:{
            required: true
          },
          estimatePrice:{
            required: true,
            max: 8
          },
        },
        tableData: []
      }
    },
    methods: {
      handleUserEdit (id) {
        router.push({ path: '/admin/user/edit/' + id })
      },
      returnBack () {
        router.push({ path: '/admin/order_list/' })
      },
      getOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id                = response.data.id
          this.fullName          = response.data.full_name
          this.code              = response.data.code
          this.orderEmail        = response.data.order_email
          this.orderTtl          = response.data.order_ttl
          this.shopName          = response.data.shop_name
          this.shopFixTel        = response.data.shop_fix_tel
          this.companyName       = response.data.company_name
          this.orderStatus       = response.data.order_status
          this.orderDate         = response.data.order_date
          this.totalPrice        = response.data.total_price
          this.discount          = response.data.discount
          this.deliveryFee       = response.data.delivery_fee
          this.tax               = response.data.tax
          this.estimatePrice     = response.data.estimate_price
          this.applicationDate   = response.data.application_date
          this.contractPrice     = response.data.contract_price
          this.memo              = response.data.memo
          this.userId            = response.data.user_id
          this.roomId            = response.data.room_id
          var res = response.data.order_details
          for (var i in res) {
            var item = {
              id:          res[i].id,
              productName: res[i].product_name,
              productSku:  res[i].product_sku
            }
            switch (res[i].status) {
              case 'no_order':
                item.orderDetailStatus = '未発注'
                break;
              case 'order':
                item.orderDetailStatus = '発注'
                break;
              case 'complete_del':
                item.orderDetailStatus = '納品済み'
                break;
              default:
                item.orderDetailStatus = '識別不明'
            }
            this.tableData.push(item)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('取引情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          order: {
            order_ttl:      this.orderTtl,
            order_status:   this.orderStatus,
            estimate_price: this.estimatePrice,
            memo:           this.memo,
            order_email:    this.orderEmail,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`取引情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('取引情報の更新に失敗しました')
          }
        })
      },
      getOrderDetails() {
        this.$refs.orderDetail.getOrderDetails()
      },
      getOrderMoreDetails(id) {
        this.$refs.orderMoreDetail.getOrderMoreDetails(id)
      },
      getOrderQrImage(id) {
        this.$refs.orderQrImage.getOrderQrImage(id)
      },
      getOrderPdfTmp(id) {
        this.$refs.orderPdfTmp.getOrderPdfTmp(id)
      },
      jumpRoom (id, companyName, shopName, orderStatus, orderId) {
        router.push({ path: '/admin/orders/' + id + '/chat', query: {room_id: id, company_name: companyName, shop_name: shopName, order_status: orderStatus, order_id: orderId}})
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getOrders()
      this.getOrderDetailLists(this.$route.params.id)
    },
    watch: {
      orderTtl(){
        this.touched.orderTtl = true
      },
      orderStatus(){
        this.touched.orderStatus = true
      },
      estimatePrice(){
        this.touched.estimatePrice = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

.pt5{
  padding-top: 5px;
}

.qr-purple {
  background: #AA09B6 !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>