<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">商品情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productNumber')},
                {'md-valid': !errors.has('productNumber') && touched.productNumber}]">
                <md-input
                  v-model="productNumber"
                  data-vv-name="productNumber"
                  type="text"
                  v-validate= "modelValidations.productNumber"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productNumber')">【必須】25文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productNumber')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productNumber') && touched.productNumber">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('productName')},
                {'md-valid': !errors.has('productName') && touched.productName}]">
                <md-input
                  v-model="productName"
                  data-vv-name="productName"
                  type="text"
                  v-validate= "modelValidations.productName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('productName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('productName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('productName') && touched.productName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              型式
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('sku')},
                {'md-valid': !errors.has('sku') && touched.sku}]">
                <md-input
                  v-model="sku"
                  data-vv-name="sku"
                  type="text"
                  v-validate= "modelValidations.sku"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('sku')">【必須】25文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('sku')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('sku') && touched.sku">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メーカー名
            </label>

            <!-- <div v-if="parentId !== null" class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{makerName}}</p>
            </div> -->
            <div class="md-layout-item md-xsmall-size-100">
              <maker-select ref="maker" :touchedFlag="true"></maker-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品URL
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('url')},
                {'md-valid': !errors.has('url') && touched.url}]">
                <md-input
                  v-model="url"
                  data-vv-name="url"
                  type="text"
                  v-validate= "modelValidations.url"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('url')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('url')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('url') && touched.url">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              優先表示順
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('priorityPosition')},
                {'md-valid': !errors.has('priorityPosition') && touched.priorityPosition}]">
                <md-select
                  v-model="priorityPosition"
                  data-vv-name="priorityPosition"
                  v-validate= "modelValidations.priorityPosition"
                  required>
                  <md-option value="0">無効</md-option>
                  <md-option value="1">最優先</md-option>
                  <md-option value="2">準優先</md-option>
                  <md-option value="3">3番目優先</md-option>
                  <md-option value="4">4番目優先</md-option>
                  <md-option value="5">5番目優先</md-option>
                  <md-option value="6">6番目優先</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('priorityPosition')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('priorityPosition')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('priorityPosition') && touched.priorityPosition" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;">
              優先表示開始
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="priorityStart"
                :language="ja"
                data-vv-name="priorityStart"
                v-validate= "priorityPosition === 0 ? '' : modelValidations.priorityStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="priorityStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('priorityStart')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('priorityStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('priorityStart') && touched.priorityStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="priorityStartTime" v-model="priorityStartTime"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;">
              優先表示終了
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="priorityEnd"
                :language="ja"
                data-vv-name="priorityEnd"
                v-validate= "priorityPosition === 0 ? '' : modelValidations.priorityEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="priorityEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('priorityEnd')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('priorityEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('priorityEnd') && touched.priorityEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="priorityEndTime" v-model="priorityEndTime"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品カテゴリ
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
              <div class="categoryContainer">
                <div class="bigCategory" v-for="item, index in categories" :key="item.id">
                  <div class="categoryDetail">
                    <div class="checkArea">
                      <md-checkbox v-model="item.check" class="categoryCheck" v-on:change="checkCategoryItem(item)">{{item.name}}</md-checkbox>
                      <div class="toggleBox">
                        <md-button
                          class="md-just-icon md-simple"
                          @click.native="item.openFlag = !item.openFlag">
                          <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                          <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                        </md-button>
                      </div>
                    </div>
                  </div>
                  <slide-y-up-transition>
                    <div class="progenyCategory" v-show="item.openFlag">
                      <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                        <div class="categoryDetail">
                          <div class="checkArea">
                            <md-checkbox v-model="itemC.check" class="categoryCheck" v-on:change="checkCategoryItemC(item, itemC)">{{itemC.name}}</md-checkbox>
                            <div class="toggleBox">
                              <md-button
                                class="md-just-icon md-simple"
                                @click.native="itemC.openFlag = !itemC.openFlag">
                                <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                                <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                              </md-button>
                            </div>
                          </div>
                        </div>
                        <slide-y-up-transition>
                          <div class="progenyCategory" v-show="itemC.openFlag">
                            <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                              <div class="categoryDetail">
                                <div class="checkArea">
                                  <md-checkbox v-model="itemCC.check" class="categoryCheck"  v-on:change="checkCategoryItemCC(item, itemC, itemCC)">{{itemCC.name}}</md-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </slide-y-up-transition>
                      </div>
                    </div>
                  </slide-y-up-transition>
                </div>

              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pl33">
              <md-checkbox v-model="allCheck" class="categoryCheck"  v-on:change="checkCategoryAllCheck">すべて選択</md-checkbox>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開先
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="kind" value="subsc">サブスクキッチン</md-radio>
              <md-radio v-model="kind" value="sales">スタートキッチン</md-radio>
              <md-radio v-model="kind" value="both">両サイト公開</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              保守
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('maintenancePrice')},
                {'md-valid': !errors.has('maintenancePrice') && touched.maintenancePrice}]">
                <md-input
                  v-model="maintenancePrice"
                  data-vv-name="maintenancePrice"
                  v-validate= "modelValidations.maintenancePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('maintenancePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('maintenancePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('maintenancePrice') && touched.maintenancePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              取り付け
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('attachmentPrice')},
                {'md-valid': !errors.has('attachmentPrice') && touched.attachmentPrice}]">
                <md-input
                  v-model="attachmentPrice"
                  data-vv-name="attachmentPrice"
                  v-validate= "modelValidations.attachmentPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('attachmentPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('attachmentPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('attachmentPrice') && touched.attachmentPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション1
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionName1')},
                {'md-valid': !errors.has('optionName1') && touched.optionName1}]">
                <md-input
                  v-model="optionName1"
                  data-vv-name="optionName1"
                  type="text"
                  v-validate= "modelValidations.optionName1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName1')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName1') && touched.optionName1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション1価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionPrice1')},
                {'md-valid': !errors.has('optionPrice1') && touched.optionPrice1}]">
                <md-input
                  v-model="optionPrice1"
                  data-vv-name="optionPrice1"
                  v-validate= "optionName1 === '' ? '' : modelValidations.optionPrice1"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice1')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice1')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice1') && touched.optionPrice1" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション2
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionName2')},
                {'md-valid': !errors.has('optionName2') && touched.optionName2}]">
                <md-input
                  v-model="optionName2"
                  data-vv-name="optionName2"
                  type="text"
                  v-validate= "modelValidations.optionName2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName2')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName2') && touched.optionName2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション2価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionPrice2')},
                {'md-valid': !errors.has('optionPrice2') && touched.optionPrice2}]">
                <md-input
                  v-model="optionPrice2"
                  data-vv-name="optionPrice2"
                  v-validate= "optionName2 === '' ? '' : modelValidations.optionPrice2"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice2')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice2')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice2') && touched.optionPrice2" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション3
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionName3')},
                {'md-valid': !errors.has('optionName3') && touched.optionName3}]">
                <md-input
                  v-model="optionName3"
                  data-vv-name="optionName3"
                  type="text"
                  v-validate= "modelValidations.optionName3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionName3')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionName3')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionName3') && touched.optionName3">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              オプション3価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('optionPrice3')},
                {'md-valid': !errors.has('optionPrice3') && touched.optionPrice3}]">
                <md-input
                  v-model="optionPrice3"
                  data-vv-name="optionPrice3"
                  v-validate= "optionName3 === '' ? '' : modelValidations.optionPrice3"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('optionPrice3')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('optionPrice3')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('optionPrice3') && touched.optionPrice3" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              サブスク価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('subscPrice')},
                {'md-valid': !errors.has('subscPrice') && touched.subscPrice}]">
                <md-input
                  v-model="subscPrice"
                  data-vv-name="subscPrice"
                  v-validate= "modelValidations.subscPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subscPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subscPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subscPrice') && touched.subscPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'subsc' || kind === 'both'">
              サブスクタイムセール価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'subsc' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('subscTimeSalePrice')},
                {'md-valid': !errors.has('subscTimeSalePrice') && touched.subscTimeSalePrice}]">
                <md-input
                  v-model="subscTimeSalePrice"
                  data-vv-name="subscTimeSalePrice"
                  v-validate= "modelValidations.subscTimeSalePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subscTimeSalePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subscTimeSalePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subscTimeSalePrice') && touched.subscTimeSalePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'subsc' || kind === 'both'">
              サブスクタイムセール開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative" v-if="kind === 'subsc' || kind === 'both'">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="timeSaleStart"
                :language="ja"
                data-vv-name="timeSaleStart"
                v-validate= "subscTimeSalePrice === '' ? '' : modelValidations.timeSaleStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="timeSaleStart = '', subscStartTime = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('timeSaleStart')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('timeSaleStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('timeSaleStart') && touched.timeSaleStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="subscStartTimer" v-model="subscStartTime" v-if="kind === 'subsc' || kind === 'both'"></hour-select>
          </div>
          
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'subsc' || kind === 'both'">
              サブスクタイムセール終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative" v-if="kind === 'subsc' || kind === 'both'">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="timeSaleEnd"
                :language="ja"
                data-vv-name="timeSaleEnd"
                v-validate= "subscTimeSalePrice === '' ? '' : modelValidations.timeSaleEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="timeSaleEnd = '', subscEndTime = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('timeSaleEnd')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('timeSaleEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('timeSaleEnd') && touched.timeSaleEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="subscEndTimer" v-model="subscEndTime" v-if="kind === 'subsc' || kind === 'both'"></hour-select>
          </div>
   
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販保守オプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesMaintenancePrice')},
                {'md-valid': !errors.has('salesMaintenancePrice') && touched.salesMaintenancePrice}]">
                <md-input
                  v-model="salesMaintenancePrice"
                  data-vv-name="salesMaintenancePrice"
                  v-validate= "modelValidations.salesMaintenancePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesMaintenancePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesMaintenancePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesMaintenancePrice') && touched.salesMaintenancePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販取り付けオプション価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesAttachmentPrice')},
                {'md-valid': !errors.has('salesAttachmentPrice') && touched.salesAttachmentPrice}]">
                <md-input
                  v-model="salesAttachmentPrice"
                  data-vv-name="salesAttachmentPrice"
                  v-validate= "modelValidations.salesAttachmentPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesAttachmentPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesAttachmentPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesAttachmentPrice') && touched.salesAttachmentPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション1
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName1')},
                {'md-valid': !errors.has('salesOptionName1') && touched.salesOptionName1}]">
                <md-input
                  v-model="salesOptionName1"
                  data-vv-name="salesOptionName1"
                  type="text"
                  v-validate= "modelValidations.salesOptionName1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName1')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName1') && touched.salesOptionName1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション1価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice1')},
                {'md-valid': !errors.has('salesOptionPrice1') && touched.salesOptionPrice1}]">
                <md-input
                  v-model="salesOptionPrice1"
                  data-vv-name="salesOptionPrice1"
                  v-validate= "salesOptionName1 === '' ? '' : modelValidations.salesOptionPrice1"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice1')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice1')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice1') && touched.salesOptionPrice1" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション2
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName2')},
                {'md-valid': !errors.has('salesOptionName2') && touched.salesOptionName2}]">
                <md-input
                  v-model="salesOptionName2"
                  data-vv-name="salesOptionName2"
                  type="text"
                  v-validate= "modelValidations.salesOptionName2"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName2')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName2') && touched.salesOptionName2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション2価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice2')},
                {'md-valid': !errors.has('salesOptionPrice2') && touched.salesOptionPrice2}]">
                <md-input
                  v-model="salesOptionPrice2"
                  data-vv-name="salesOptionPrice2"
                  v-validate= "salesOptionName2 === '' ? '' : modelValidations.salesOptionPrice2"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice2')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice2')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice2') && touched.salesOptionPrice2" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション3
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionName3')},
                {'md-valid': !errors.has('salesOptionName3') && touched.salesOptionName3}]">
                <md-input
                  v-model="salesOptionName3"
                  data-vv-name="salesOptionName3"
                  type="text"
                  v-validate= "modelValidations.salesOptionName3"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionName3')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionName3')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionName3') && touched.salesOptionName3">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販オプション3価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesOptionPrice3')},
                {'md-valid': !errors.has('salesOptionPrice3') && touched.salesOptionPrice3}]">
                <md-input
                  v-model="salesOptionPrice3"
                  data-vv-name="salesOptionPrice3"
                  v-validate= "salesOptionName3 === '' ? '' : modelValidations.salesOptionPrice3"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesOptionPrice3')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesOptionPrice3')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesOptionPrice3') && touched.salesOptionPrice3" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              通販価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesPrice')},
                {'md-valid': !errors.has('salesPrice') && touched.salesPrice}]">
                <md-input
                  v-model="salesPrice"
                  data-vv-name="salesPrice"
                  v-validate= "modelValidations.salesPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesPrice') && touched.salesPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'sales' || kind === 'both'">
              通販タイムセール価格
            </label>
            <div class="md-layout-item md-xsmall-size-100" v-if="kind === 'sales' || kind === 'both'">
              <md-field :class="[
                {'md-error': errors.has('salesTimePrice')},
                {'md-valid': !errors.has('salesTimePrice') && touched.salesTimePrice}]">
                <md-input
                  v-model="salesTimePrice"
                  data-vv-name="salesTimePrice"
                  v-validate= "modelValidations.salesTimePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesTimePrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesTimePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesTimePrice') && touched.salesTimePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'sales' || kind === 'both'">
              通販タイムセール開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative" v-if="kind === 'sales' || kind === 'both'">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="salesTimesaleStart"
                :language="ja"
                data-vv-name="salesTimesaleStart"
                v-validate= "salesTimePrice === '' ? '' : modelValidations.salesTimesaleStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="salesTimesaleStart = '', salesStartTime = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('salesTimesaleStart')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('salesTimesaleStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('salesTimesaleStart') && touched.salesTimesaleStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="salesStartTimer" v-model="salesStartTime" v-if="kind === 'sales' || kind === 'both'"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;" v-if="kind === 'sales' || kind === 'both'">
              通販タイムセール終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative" v-if="kind === 'sales' || kind === 'both'">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="salesTimesaleEnd"
                :language="ja"
                data-vv-name="salesTimesaleEnd"
                v-validate= "salesTimePrice === '' ? '' : modelValidations.salesTimesaleEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="salesTimesaleEnd = '', salesEndTime = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('salesTimesaleEnd')">日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('salesTimesaleEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('salesTimesaleEnd') && touched.salesTimesaleEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="salesEndTimer" v-model="salesEndTime" v-if="kind === 'sales' || kind === 'both'"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              希望小売価格
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('suggestedRetailPrice')},
                {'md-valid': !errors.has('suggestedRetailPrice') && touched.suggestedRetailPrice}]">
                <md-input
                  v-model="suggestedRetailPrice"
                  data-vv-name="suggestedRetailPrice"
                  v-validate= "modelValidations.suggestedRetailPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('suggestedRetailPrice')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('suggestedRetailPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('suggestedRetailPrice') && touched.suggestedRetailPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              仕切率
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('partitionRate')},
                {'md-valid': !errors.has('partitionRate') && touched.partitionRate}]">
                <md-input
                  v-model="partitionRate"
                  data-vv-name="partitionRate"
                  v-validate= "modelValidations.partitionRate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('partitionRate')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('partitionRate')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('partitionRate') && touched.partitionRate" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              仕入値
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('purchasePrice')},
                {'md-valid': !errors.has('purchasePrice') && touched.purchasePrice}]">
                <md-input
                  v-model="purchasePrice"
                  data-vv-name="purchasePrice"
                  v-validate= "modelValidations.purchasePrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('purchasePrice')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('purchasePrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('purchasePrice') && touched.purchasePrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              サブ掛率
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subscRate')},
                {'md-valid': !errors.has('subscRate') && touched.subscRate}]">
                <md-input
                  v-model="subscRate"
                  data-vv-name="subscRate"
                  v-validate= "modelValidations.subscRate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subscRate')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subscRate')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subscRate') && touched.subscRate" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              通販掛率
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesRate')},
                {'md-valid': !errors.has('salesRate') && touched.salesRate}]">
                <md-input
                  v-model="salesRate"
                  data-vv-name="salesRate"
                  v-validate= "modelValidations.salesRate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesRate')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesRate')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesRate') && touched.salesRate" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品説明
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="productDetail" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              検索文章
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="searchTxt" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              発注先名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="supplierName" data-vv-name="supplierName" type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              FAX/TEL
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="supplierTel" data-vv-name="supplierTel" type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              郵便番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="supplierZip" data-vv-name="supplierZip" type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              住所
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="supplierPref" data-vv-name="supplierPref" type="text">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              メイン画像
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageMain">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageMain" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeMainImage" v-if="imageMain" data-id="imageMain"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageMain">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileMainChange" data-id="imageMain">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              サブ画像
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input imageSub" v-for="(item,index) in imgList" :key="index">
                <div v-if="!item.url">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="item.url" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeSubImage" v-if="item.url" :data-id="index"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!item.url">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @input="onFileSubChange" :data-id="index">
                  </md-button>
                </div>
              </div>

              <div v-show="buttonFlag" class="BtnAdd" @click="addImage"><md-icon>add</md-icon><span>サブ画像を追加する</span></div>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
          <md-button class="md-default md-lg mL40" @click.native.prevent="jumpSpecEdit" v-if="!this.newFlag">スペック表編集</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import MakerSelect from '../components/additional/MakerSelect.vue'
  import router from '../packs/router.js'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import HourSelect from '../components/additional/HourSelect.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      MakerSelect,
      HourSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      },
      productData: Object
    },
    data () {
      return {
        buttonWord: '登録',
        productNumber: '',
        productName: '',
        sku: '',
        url: '',
        optionName1: '',
        optionName2: '',
        optionName3: '',
        // makerId: '',
        status: '',
        productDetail: '',
        searchTxt: '',
        kind: 'both',
        maintenancePrice: '',
        attachmentPrice: '',
        optionPrice1: '',
        optionPrice2: '',
        optionPrice3: '',
        subscPrice: '',
        subscTimeSalePrice: '',
        timeSaleStart: null,
        timeSaleEnd: null,
        subscStartTime: '',
        subscEndTime: '',
        requiredTime: null,
        startTime: '',
        endTime: '',
        priorityStart: '',
        priorityEnd: '',
        priorityStartTime: '',
        priorityEndTime: '',
        imageMain: '',
        imgList: [],
        newFlag: false,
        buttonFlag: true,
        categories: [],
        parentId: null,
        makerName: '選択しない',
        supplierName: '',
        supplierTel: '',
        supplierZip: '',
        supplierPref: '',
        allCheck: false,
        salesMaintenancePrice: '',
        salesAttachmentPrice: '',
        salesOptionName1: '',
        salesOptionPrice1: '',
        salesOptionName2: '',
        salesOptionPrice2: '',
        salesOptionName3: '',
        salesOptionPrice3: '',
        salesPrice: '',
        salesTimePrice: '',
        salesTimesaleStart: null,
        salesTimesaleEnd: null,
        salesStartTime: '',
        salesEndTime: '',
        priorityPosition: '0',
        suggestedRetailPrice: '',
        partitionRate: '',
        purchasePrice: '',
        subscRate: '',
        salesRate: '',
        touched: {
          productNumber: false,
          productName: false,
          sku: false,
          url: false,
          optionName1: false,
          optionName2: false,
          optionName3: false,
          status: false,
          maintenancePrice: false,
          attachmentPrice: false,
          optionPrice1: false,
          optionPrice2: false,
          optionPrice3: false,
          subscPrice: false,
          subscTimeSalePrice: false,
          timeSaleStart: false,
          timeSaleEnd: false,
          requiredTime: false,
          salesMaintenancePrice: false,
          salesAttachmentPrice: false,
          salesOptionName1: false,
          salesOptionPrice1: false,
          salesOptionName2: false,
          salesOptionPrice2: false,
          salesOptionName3: false,
          salesOptionPrice3: false,
          salesPrice: false,
          salesTimePrice: false,
          salesTimesaleStart: false,
          salesTimesaleEnd: false,
          priorityStart: false,
          priorityEnd: false,
          suggestedRetailPrice: false,
          partitionRate: false,
          purchasePrice: false,
          subscRate: false,
          salesRate: false,
        },
        modelValidations: {
          productNumber: {
            max: 25,
            required: true
          },
          productName: {
            max: 255,
            required: true
          },
          sku: {
            max: 25,
            required: true
          },
          url: {
            max: 255
          },
          optionName1: {
            max: 255
          },
          optionName2: {
            max: 255
          },
          optionName3: {
            max: 255
          },
          // makerId: {
          //   required: true
          // },
          status:{
            required: true
          },
          maintenancePrice:{
            max: 8,
            numeric: true
          },
          attachmentPrice:{
            max: 8,
            required: true,
            numeric: true
          },
          optionPrice1:{
            max: 8,
            required: true,
            numeric: true
          },
          optionPrice2:{
            max: 8,
            required: true,
            numeric: true
          },
          optionPrice3:{
            max: 8,
            required: true,
            numeric: true
          },
          subscPrice:{
            max: 8,
            required: true,
            numeric: true
          },
          subscTimeSalePrice:{
            max: 8,
            numeric: true
          },
          timeSaleStart:{
            required: true
          },
          timeSaleEnd:{
            required: true
          },
          requiredTime:{
            required: true,
          },
          salesMaintenancePrice:{
            max: 8,
            numeric: true
          },
          salesAttachmentPrice:{
            max: 8, 
            required: true,
            numeric: true
          },
          salesOptionName1: {
            max: 255
          },
          salesOptionPrice1:{
            max: 8,
            required: true,
            numeric: true
          },
          salesOptionName2: {
            max: 255
          },
          salesOptionPrice2:{
            max: 8,
            required: true,
            numeric: true
          },
          salesOptionName3: {
            max: 255
          },
          salesOptionPrice3:{
            max: 8,
            required: true,
            numeric: true
          },
          salesPrice:{
            max: 8,
            required: true,
            numeric: true
          },
          salesTimePrice:{
            max: 8,
            numeric: true
          },
          salesTimesaleStart:{
            required: true
          },
          salesTimesaleEnd:{
            required: true
          },
          priorityStart:{
            required: true
          },
          priorityEnd:{
            required: true
          },
          suggestedRetailPrice:{
            numeric: true
          },
          purchasePrice:{
            numeric: true
          },
        },
        modalFlag: false,
        icons: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/product_list/' })
      },
      jumpSpecEdit () {
        router.push({ path: '/admin/product/spec_values_edit/' + this.$route.params.id })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      onFileMainChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createMainImage(files[0],ids);
      },
      createMainImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeMainImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      onFileSubChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createSubImage(files[0],ids);
      },
      createSubImage(file,fileId) {
        let reader = new FileReader()
        let $this = this

        reader.onload = (e) => {
          $this.imgList[fileId].url = e.target.result
        };
        reader.readAsDataURL(file)
      },
      removeSubImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id')
        this.imgList[ids].url = ''
      },
      closeEmoticon () {
        this.modalFlag = false
      },
      openEmoticon () {
        if (this.productDetail.length > this.TextAreaMaxLength-2 ) {this.warningMessage(`最大桁数(`+this.TextAreaMaxLength+`)を越えるため顔文字を挿入できません`); return;}
        this.modalFlag = true
        var doms = document.getElementById('txtArea')
        this.cursorPos = doms.selectionStart
      },
      insertEmoticon (e) {
        this.modalFlag = false
        var emoicons = e.target.getAttribute('data-code')
        var txt = this.productDetail
        this.productDetail = txt.substr(0, this.cursorPos) + emoicons + txt.substr(this.cursorPos, txt.length)
      },
      getItem () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/products/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.productNumber = response.data.code
          this.productName = response.data.name
          this.sku = response.data.sku
          this.url = response.data.url
          this.optionName1 = response.data.option_name1
          this.optionName2 = response.data.option_name2
          this.optionName3 = response.data.option_name3
          this.productDetail = response.data.description
          this.searchTxt = response.data.search_txt
          this.kind = response.data.kind
          this.maintenancePrice = Math.floor(response.data.maintenance_price)
          this.attachmentPrice = Math.floor(response.data.attachment_price)
          this.optionPrice1 = Math.floor(response.data.option_price1)
          this.optionPrice2 = Math.floor(response.data.option_price2)
          this.optionPrice3 = Math.floor(response.data.option_price3)
          this.subscPrice = Math.floor(response.data.subsc_price)
          response.data.subsc_time_price !== null ? this.subscTimeSalePrice = Math.floor(response.data.subsc_time_price) : this.subscTimeSalePrice = ''
          this.status = response.data.status
          this.requiredTime = response.data.required_time
          // response.data.maker_id === null ? this.makerId='' : this.makerId = response.data.maker_id
          response.data.maker_id === null ? this.$refs.maker.makerId='' : this.$refs.maker.makerId = response.data.maker_id
          if (response.data.subsc_timesale_start !== null) {
            this.timeSaleStart = this.dateDivider(response.data.subsc_timesale_start)[0]
            this.subscStartTime = this.dateDivider(response.data.subsc_timesale_start)[1]
            this.$refs.subscStartTimer.unlock()
          }
          if (response.data.subsc_timesale_end !== null) {
            this.timeSaleEnd = this.dateDivider(response.data.subsc_timesale_end)[0]
            this.subscEndTime = this.dateDivider(response.data.subsc_timesale_end)[1]
            this.$refs.subscEndTimer.unlock()
          }
          this.priorityPosition = response.data.priority_position
          if (response.data.priority_start !== null) {
            this.priorityStart = this.dateDivider(response.data.priority_start)[0]
            this.priorityStartTime = this.dateDivider(response.data.priority_start)[1]
            this.$refs.priorityStartTime.unlock()
          }
          if (response.data.priority_end !== null) {
            this.priorityEnd = this.dateDivider(response.data.priority_end)[0]
            this.priorityEndTime = this.dateDivider(response.data.priority_end)[1]
            this.$refs.priorityEndTime.unlock()
          }
          this.imageMain = response.data.main_image_url
          if (response.data.sub_image1_url !== '' && response.data.sub_image1_url !== null) {
            this.imgList.push({url:response.data.sub_image1_url})
          } else if ((response.data.sub_image2_url !== '' && response.data.sub_image2_url !== null) || (response.data.sub_image3_url !== '' && response.data.sub_image3_url !== null) || (response.data.sub_image4_url !== '' && response.data.sub_image4_url !== null) || (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null)) {
            this.imgList.push({url:''})
          }
          if (response.data.sub_image2_url !== '' && response.data.sub_image2_url !== null) {
            this.imgList.push({url:response.data.sub_image2_url})
          } else if ((response.data.sub_image3_url !== '' && response.data.sub_image3_url !== null) || (response.data.sub_image4_url !== '' && response.data.sub_image4_url !== null) || (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null)) {
            this.imgList.push({url:''})
          }
          if (response.data.sub_image3_url !== '' && response.data.sub_image3_url !== null) {
            this.imgList.push({url:response.data.sub_image3_url})
          } else if ((response.data.sub_image4_url !== '' && response.data.sub_image4_url !== null) || (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null)) {
            this.imgList.push({url:''})
          }
          if (response.data.sub_image4_url !== '' && response.data.sub_image4_url !== null) {
            this.imgList.push({url:response.data.sub_image4_url})
          } else if (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null) {
            this.imgList.push({url:''})
          }
          if (response.data.sub_image5_url !== '' && response.data.sub_image5_url !== null) {
            this.imgList.push({url:response.data.sub_image5_url})
          }
          this.makerChecker(response.data.maker_id)
          this.checkImgLength()
          this.createCategoryListLoop(response)
          this.parentId = response.data.parent_id
          this.supplierName = response.data.supplier_name
          this.supplierTel = response.data.supplier_tel
          this.supplierZip = response.data.supplier_zip
          this.supplierPref = response.data.supplier_pref
          this.salesMaintenancePrice = Math.floor(response.data.sales_maintenance_price)
          this.salesAttachmentPrice = Math.floor(response.data.sales_attachment_price)
          this.salesOptionName1 = response.data.sales_option_name1
          this.salesOptionPrice1 = Math.floor(response.data.sales_option_price1)
          this.salesOptionName2 = response.data.sales_option_name2
          this.salesOptionPrice2 = Math.floor(response.data.sales_option_price2)
          this.salesOptionName3 = response.data.sales_option_name3
          this.salesOptionPrice3 = Math.floor(response.data.sales_option_price3)
          this.salesPrice = Math.floor(response.data.sales_price)
          response.data.sales_time_price !== null ? this.salesTimePrice = Math.floor(response.data.sales_time_price) : this.salesTimePrice = ''
          if (response.data.sales_timesale_start !== null) {
            this.salesTimesaleStart = this.dateDivider(response.data.sales_timesale_start)[0]
            this.salesStartTime = this.dateDivider(response.data.sales_timesale_start)[1]
            this.$refs.salesStartTimer.unlock()
          }
          if (response.data.sales_timesale_end !== null) {
            this.salesTimesaleEnd = this.dateDivider(response.data.sales_timesale_end)[0]
            this.salesEndTime = this.dateDivider(response.data.sales_timesale_end)[1]
            this.$refs.salesEndTimer.unlock()
          }
          this.suggestedRetailPrice = Math.floor(response.data.suggested_retail_price)
          this.partitionRate = response.data.partition_rate
          this.purchasePrice = Math.floor(response.data.purchase_price)
          this.subscRate = response.data.subsc_rate
          this.salesRate = response.data.sales_rate
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      putItem () {
        var condition = {
          product: {
            code: this.productNumber,
            name: this.productName,
            sku: this.sku,
            url: this.url,
            option_name1: this.optionName1,
            option_name2: this.optionName2,
            option_name3: this.optionName3,
            maker_id: this.$refs.maker.makerId === '選択しない' ? '' : this.$refs.maker.makerId,
            status: this.status,
            kind: this.kind,
            description: this.productDetail,
            search_txt: this.searchTxt,
            subsc_time_price: this.subscTimeSalePrice,
            maintenance_price: this.maintenancePrice,
            attachment_price: this.attachmentPrice,
            option_price1: this.optionPrice1,
            option_price2: this.optionPrice2,
            option_price3: this.optionPrice3,
            subsc_price: this.subscPrice,
            subsc_timesale_start: this.timeSaleStart !== null ? this.initDate(this.timeSaleStart) + ' ' + this.subscStartTime : null,
            subsc_timesale_end: this.timeSaleEnd !== null ? this.initDate(this.timeSaleEnd) + ' ' + this.subscEndTime : null,
            required_time: this.requiredTime,
            categories: this.checkCategories(),
            supplier_name: this.supplierName,
            supplier_tel: this.supplierTel,
            supplier_zip: this.supplierZip,
            supplier_pref: this.supplierPref,
            sales_maintenance_price: this.salesMaintenancePrice,
            sales_attachment_price: this.salesAttachmentPrice,
            sales_option_name1: this.salesOptionName1,
            sales_option_price1: this.salesOptionPrice1,  
            sales_option_name2: this.salesOptionName2,
            sales_option_price2: this.salesOptionPrice2,
            sales_option_name3: this.salesOptionName3,
            sales_option_price3: this.salesOptionPrice3,
            sales_price: this.salesPrice,
            sales_time_price: this.salesTimePrice,
            sales_timesale_start: this.salesTimesaleStart !== null ? this.initDate(this.salesTimesaleStart) + ' ' + this.salesStartTime : null,
            sales_timesale_end: this.salesTimesaleEnd !== null ? this.initDate(this.salesTimesaleEnd) + ' ' + this.salesEndTime : null,
            priority_position: this.priorityPosition,
            priority_start: this.priorityStart !== null ? this.initDate(this.priorityStart) + ' ' + this.priorityStartTime : null,
            priority_end: this.priorityEnd !== null ? this.initDate(this.priorityEnd) + ' ' + this.priorityEndTime : null,
            suggested_retail_price: this.suggestedRetailPrice,
            partition_rate: this.partitionRate,
            purchase_price: this.purchasePrice,
            subsc_rate: this.subscRate,
            sales_rate: this.salesRate,
          }
        }

        if(this.imageMain === '' || this.imageMain === null){
          condition.product.main_image = ''
        } else if (this.imageMain.indexOf('/uploads/') == -1) {
          condition.product.main_image = this.imageMain
        }

        for (var i = 0; i < this.imgList.length; i++) {
          if(this.imgList[i].url === '' || this.imgList[i].url === null){
            condition.product['sub_image' + (i + 1)] = ''
          } else if (this.imgList[i].url.indexOf('/uploads/') == -1) {
            condition.product['sub_image' + (i + 1)] = this.imgList[i].url
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl+'/products/' : this.AdminApiUrl+'/products/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`商品情報を`+this.buttonWord+`しました`, '/admin/product_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.status === 403 && error.response.data.error.match(/undefined method `perform_later'/) ) {
            this.successAndBackPage(`商品情報を`+this.buttonWord+`しました`, '/admin/product_list/')
          } else {
            if (error.response.data && error.response.data.error  === "バリデーションに失敗しました。 商品コードはすでに登録されています。") {
              this.dangerNotify('既に同じ商品コードの商品が登録されています')
            } else if (error.response.data && error.response.data.error  === "バリデーションに失敗しました。 型式はすでに登録されています。") {
              this.dangerNotify('既に同じ型式の商品が登録されています')
            } else if (error.response.data && error.response.data.error  === "バリデーションに失敗しました。 商品コードはすでに登録されています。, 型式はすでに登録されています。") {
              this.dangerNotify('既に同じ商品コード・型式の商品が登録されています')
            } else {
              this.dangerNotify(`商品情報を正しく`+this.buttonWord+`できませんでした`)
            }
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      removeImage () {
        this.imgList.pop()
        this.checkImgLength()
      },
      addImage () {
        var image = {
          url: ''
        }
        this.imgList.push(image)
        this.checkImgLength()
      },
      checkImgLength () {
        if (this.imgList.length >= 5) {
          this.buttonFlag = false
        } else {
          this.buttonFlag = true
        }
      },

      resetData () {
        this.productNumber = ''
        this.productName = ''
        this.sku = ''
        this.url = ''
        this.optionName1 = ''
        this.optionName2 = ''
        this.optionName3 = ''
        // this.makerId = ''
        this.$refs.maker.makerId = ''
        this.status = ''
        this.productDetail = ''
        this.searchTxt = ''
        this.kind = 'both',
        this.maintenancePrice = ''
        this.attachmentPrice = ''
        this.optionPrice1 = ''
        this.optionPrice2 = ''
        this.optionPrice3 = ''
        this.subscPrice = ''
        this.requiredTime = null
        this.subscTimeSalePrice = ''
        this.timeSaleStart = null
        this.timeSaleEnd = null
        this.salesMaintenancePrice = ''
        this.salesAttachmentPrice = ''
        this.salesOptionName1 = ''
        this.salesOptionPrice1 = ''
        this.salesOptionName2 = ''
        this.salesOptionPrice2 = ''
        this.salesOptionName3 = ''
        this.salesOptionPrice3 = ''
        this.salesPrice = ''
        this.salesTimePrice = ''
        this.salesTimesaleStart = null
        this.subscStartTime = '00:00'
        this.$refs.subscStartTimer.lock()
        this.salesStartTime = '00:00'
        this.$refs.salesStartTimer.lock()
        this.salesTimesaleEnd = null
        this.subscEndTime = '00:00'
        this.$refs.subscEndTimer.lock()
        this.salesEndTime = '00:00'
        this.$refs.salesEndTimer.lock()
        this.imageMain = ''
        this.imgList = []
        this.allCheck = ''
        this.buttonFlag = true
        this.parentId = null
        this.priorityStart = ''
        this.priorityStartTime = '00:00'
        this.$refs.priorityStartTime.lock()
        this.priorityEnd = ''
        this.priorityEndTime = '00:00'
        this.$refs.priorityEndTime.lock()
        this.suggestedRetailPrice = ''
        this.partitionRate = ''
        this.purchasePrice = ''
        this.subscRate = ''
        this.salesRate = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
      getCategory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
          // params: condition
        }).then(response => {
          this.createNewCategoryList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createNewCategoryList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false,
            check: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
        this.sliderBgFlag = false
      },
      sleep (time) {
        return new Promise(resolve => {
          setTimeout(resolve, time)
        })
      },
      async createCategoryListLoop (response) {
        try {
          for (let i = 0; i < 10; i++) {
            if (this.categories) {
              this.createCategoryList(response.data.categories)
              break;
            } else if (i === 9) {
              throw "商品情報を正しく取得できませんでした";
            } else {
              await this.sleep(500)
            }
          }
        } catch (error) {
          this.dangerNotify(error)
        }
      },
      createCategoryList (obj) {
        var res = obj
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].name,
            children: [],
            openFlag: res[i].checked,
            check: res[i].checked
          }
          var child = res[i].children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: child[j].checked,
              check: child[j].checked
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: child[j].children[k].checked,
                check: child[j].children[k].checked
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
      },
      checkCategories () {
        var checkedCategories = []
        for (var i in this.categories) {
          if (this.categories[i].check) {
            checkedCategories.push(this.categories[i].id)
          }
          var child = this.categories[i].children
          for (var j in child) {
            if (child[j].check) {
              checkedCategories.push(child[j].id)
            }
            for (var k in child[j].children) {
              if (child[j].children[k].check) {
              checkedCategories.push(child[j].children[k].id)
              }
            }
          }
        }
        if(checkedCategories.length === 0){
          this.validateError("商品カテゴリを一つ以上チェックして下さい。");
          exit;
        }
        return checkedCategories.join()
      },
      makerChecker (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/makers/select_list',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          for (var i = 0;i < response.data.length ; i++) {
            if (id === response.data[i].id) {
              this.makerName = response.data[i].name
              break
            }
          }
        })
        .catch(error => {
          this.dangerNotify('店舗リストを取得できませんでした')
        })
      },
      checkCategoryItem (item) {
        if (!item.check) {
          this.allCheck = false
          item.openFlag = false
          for (var j in item.children) {
            item.children[j].openFlag = false
            item.children[j].check = false
            for (var k in item.children[j].children) {
              item.children[j].children[k].check = false
            }
          }
        }
      },
      checkCategoryItemC (item, itemC) {
        if (itemC.check) {
          item.check = true
        } else if (!itemC.check) {
          this.allCheck = false
          itemC.openFlag = false
          for (var k in itemC.children) {
            itemC.children[k].check = false
          }
        }
      },
      checkCategoryItemCC (item, itemC, itemCC) {
        if (itemCC.check) {
          item.check = true
          itemC.check = true
        } else if (!itemCC.check) {
          this.allCheck = false
        }
      },
      checkCategoryAllCheck () {
        if (this.allCheck) {
          for (var i in this.categories) {
            this.categories[i].check = true
            var child = this.categories[i].children
            for (var j in child) {
              child[j].check = true
              for (var k in child[j].children) {
                child[j].children[k].check = true
              }
            }
          }
        } else if (!this.allCheck) {
          for (var i in this.categories) {
            this.categories[i].openFlag = false
            this.categories[i].check = false
            var child = this.categories[i].children
            for (var j in child) {
              child[j].openFlag = false
              child[j].check = false
              for (var k in child[j].children) {
                child[j].children[k].openFlag = false
                child[j].children[k].check = false
              }
            }
          }
        }
      }
    },
    mounted () {
      // if (this.makerFlag) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
        this.resetData()
        this.getCategory()
      } else {
        this.buttonWord = '更新'
        this.getItem()
      }
      //Icon
      this.icons = this.TextIcons
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
          this.getCategory()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getItem()
        }
      },
      kind: function() {
        this.modelValidations.attachmentPrice.required = this.kind == 'subsc' || 'both' ? true : false
        this.modelValidations.subscPrice.required = this.kind == 'subsc' || 'both' ? true : false
        this.modelValidations.salesAttachmentPrice.required = this.kind == 'sales' || 'both' ? true : false
        this.modelValidations.salesPrice.required = this.kind == 'sales' || 'both' ? true : false
      },
      productNumber(){
        this.touched.productNumber = true
      },
      productName(){
        this.touched.productName = true
      },
      sku(){
        this.touched.sku = true
      },
      url(){
        this.touched.url = true
      },
      optionName1(){
        this.touched.optionName1 = true
      },
      optionName2(){
        this.touched.optionName2 = true
      },
      optionName3(){
        this.touched.optionName3 = true
      },
      status(){
        this.touched.status = true
      },
      maintenancePrice(){
        this.touched.maintenancePrice = true
      },
      attachmentPrice(){
        this.touched.attachmentPrice = true
      },
      optionPrice1(){
        this.touched.optionPrice1 = true
      },
      optionPrice2(){
        this.touched.optionPrice2 = true
      },
      optionPrice3(){
        this.touched.optionPrice3 = true
      },
      subscPrice(){
        this.touched.subscPrice = true
      },
      subscTimeSalePrice(){
        this.touched.subscTimeSalePrice = true
      },
      timeSaleStart(){
        this.$refs.subscStartTimer.unlock()
        this.touched.timeSaleStart = true
      },
      timeSaleEnd(){
        this.$refs.subscEndTimer.unlock()
        this.touched.timeSaleEnd = true
      },
      requiredTime(){
        this.touched.requiredTime = true
      },
      salesMaintenancePrice(){
        this.touched.salesMaintenancePrice = true
      },
      salesAttachmentPrice(){
        this.touched.salesAttachmentPrice = true
      },
      salesOptionName1(){
        this.touched.salesOptionName1 = true
      },
      salesOptionPrice1(){
        this.touched.salesOptionPrice1 = true
      },
      salesOptionName2(){
        this.touched.salesOptionName2 = true
      },
      salesOptionPrice2(){
        this.touched.salesOptionPrice2 = true
      },
      salesOptionName3(){
        this.touched.salesOptionName3 = true
      },
      salesOptionPrice3(){
        this.touched.salesOptionPrice3 = true
      },
      salesPrice(){
        this.touched.salesPrice = true
      },
      salesTimePrice(){
        this.touched.salesTimePrice = true
      },
      salesTimesaleStart(){
        this.$refs.salesStartTimer.unlock()
        this.touched.salesTimesaleStart = true
      },
      salesTimesaleEnd(){
        this.$refs.salesEndTimer.unlock()
        this.touched.salesTimesaleEnd = true
      },
      priorityStart(){
        this.touched.priorityStart = true
        this.$refs.priorityStartTime.unlock()
      },
      priorityEnd(){
        this.touched.priorityEnd = true
        this.$refs.priorityEndTime.unlock()
      },
      suggestedRetailPrice(){
        this.touched.suggestedRetailPrice = true
      },
      partitionRate(){
        this.touched.partitionRate = true
      },
      purchasePrice(){
        this.touched.purchasePrice = true
      },
      subscRate(){
        this.touched.subscRate = true
      },
      salesRate(){
        this.touched.salesRate = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}

.pl33{
  padding-left: 33px;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>
