<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">スペック絞り込み情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100" v-if="specKind == 'value'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              通常値
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValue')},
                {'md-valid': !errors.has('specValue') && touched.specValue}]" class="wth37p">
                <md-input
                  v-model="specValue"
                  data-vv-name="specValue"
                  type="text"
                  v-validate= "modelValidations.specValue"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValue')">【必須】255文字以内の全角半角英数記号かなで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValue')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValue') && touched.specValue">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="specKind == 'range_value'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              範囲値開始
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValueStart')},
                {'md-valid': !errors.has('specValueStart') && touched.specValueStart}]">
                <md-input
                  v-model="specValueStart"
                  data-vv-name="specValueStart"
                  type="text"
                  v-validate= "modelValidations.specValueStart"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValueStart')">255文字以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValueStart')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValueStart') && touched.specValueStart">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="specKind == 'range_value'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              範囲値終了
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValueEnd')},
                {'md-valid': !errors.has('specValueEnd') && touched.specValueEnd}]">
                <md-input
                  v-model="specValueEnd"
                  data-vv-name="specValueEnd"
                  type="text"
                  v-validate= "modelValidations.specValueEnd"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValueEnd')">255文字以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValueEnd')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValueEnd') && touched.specValueEnd">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.editBtn type="submit"></submit-button>
          </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
    },
    computed: {
    },
    data () {
      return {
        specKind: '',
        editBtn: "更新",
        number: null,
        status: '',
        specValue: '',
        specValueStart: '',
        specValueEnd: '',
        touched: {
          number: false,
          status: false,
          specValue: false,
          specValueStart: false,
          specValueEnd: false,
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true
          },
          status: {
            required: true
          },
          specValue: {
            required: this.isRequiredSpecValue,
          },
          specValueStart: {
            max: 255,
            numeric: true,
            required: this.isRequiredRangeSpecValue,
          },
          specValueEnd: {
            max: 255,
            numeric: true,
            required: this.isRequiredRangeSpecValue,
          },
        },
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/spec_search_settings/' + this.$route.params.spec_search_setting_id})
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getSpec () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/specs/' + this.$route.params.spec_search_setting_id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.specKind = response.data.kind
          this.getItem()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック情報が取得できませんでした')
          }
        })
      },
      getItem () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/spec_values/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.number = response.data.position
          this.status = response.data.status
          this.specValue = response.data.spec_value
          this.specValueStart = response.data.spec_value_start
          this.specValueEnd = response.data.spec_value_end
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック絞り込み情報が取得できませんでした')
          }
        })
      },
      updateItem() {
        var condition = {
          spec_values: {
            spec_id: this.$route.params.spec_search_setting_id,
            position: this.number,
            status: this.status,
            spec_value: this.specValue,
            spec_value_start: this.specValueStart,
            spec_value_end: this.specValueEnd,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/spec_values/' + this.$route.params.id,
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.newFlag = true
          this.successAndBackPage(`スペック情報を更新しました`, '/admin/spec_search_settings/' + this.$route.params.spec_search_setting_id)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スペック情報を正しく更新できませんでした`)
          }
        })
      },
      resetData () {
        this.specKind = ''
        this.number = null
        this.status = ''
        this.specValue = ''
        this.specValueStart = ''
        this.specValueEnd = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
    },
    mounted () {
      this.resetData()
      if (this.makerFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getSpec()
    },
    watch: {
      number () {
        this.touched.number = true
      },
      status () {
        this.touched.status = true
      },
      specValue () {
        this.touched.specValue = true
      },
      specValueStart () {
        this.touched.specValueStart = true
      },
      specValueEnd () {
        this.touched.specValueEnd = true
      },
    },
    computed: {
      isRequiredSpecValue: function() {
        return this.specKind ==  'value'
      },
      isRequiredSpecRangeValue: function() {
        return this.specKind ==  'range_value'
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}
.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}
.wth37p{
  width: 37%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

</style>