<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">決済詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{orderNumber}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{approveNo}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{orderDate | dateFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <md-field class="productStatus">
                <md-select v-model="orderStatus" name="status">
                  <md-option value="recieved">完了</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済店舗
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{shopName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済金額
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">
                <small>¥</small>
                {{orderPrice | numberWithDelimiter}}
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ポイント利用
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static" v-if="usePoint === 0">
                なし
              </p>
              <p class="form-control-static" v-else>
                {{usePoint | numberWithDelimiter}}
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              クーポン利用
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static" v-if="couponDiscount === 0">
                なし
              </p>
              <p class="form-control-static" v-else>
                <small>¥ -</small>
                {{couponDiscount | numberWithDelimiter}}
              </p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              コメント
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <md-field>
                <md-textarea v-model="comment"></md-textarea>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native.prevent="validate">更新</md-button>
        </md-card-actions>
      </md-card>

    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'

  export default {
    components: {
    },
    computed: {
      shoppingTotal() {
        return this.shoppingCartTable.reduce((accumulator, current) => {
          return accumulator + current.price * current.quantity
        }, 0)
      }
    },
    props: {
    },
    data () {
      return {
        approveNo: '',
        orderNumber: '',
        shopName: '',
        orderDate: '',
        orderPrice: '',
        usePoint: '',
        couponDiscount: '',
        comment: '',
        orderStatus: '',
        startDay: '',
        endDay: '',
        id: '',
        shoppingCartTable: []
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/shop_order_list/' })
      },
      getOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shop_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.orderNumber = response.data.order_number
          this.orderDate = response.data.created_at
          this.orderStatus = response.data.order_status
          this.approveNo = response.data.approve_no
          this.shopName = response.data.shop_name
          this.startDay = response.data.reserved_start_time
          this.comment = response.data.memo
          this.orderPrice = Math.floor(response.data.total_price),
          this.usePoint = response.data.use_point,
          this.couponDiscount = response.data.coupon_discount,
          this.createList(response.data.shop_order_details)
        })
        .catch(error => {
          this.dangerNotify('決済情報の取得に失敗しました')
          })
      },
      createList (obj) {
        for (var i in obj) {
          var item = {
            img: obj[i].main_image.url !== null ? obj[i].main_image.url : '/images/no_image.png',
            name: obj[i].product_name !== null ? obj[i].product_name : '商品名がありません',
            quantity: obj[i].quantity,
            price: obj[i].sales_price
          }
          this.shoppingCartTable.push(item)
        }
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateStatus()
          } else {
            this.validateError()
          }
        })
      },
      updateStatus () {
        var param = {
          order_status: this.orderStatus,
          memo: this.comment
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/shop_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            shop_order : param
          }
        }).then(response => {
          this.successAndBackPage(`決済情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('決済情報の更新に失敗しました')
          }
        })
      },
    },
    mounted () {
      this.getOrders()
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  max-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
