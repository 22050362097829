<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">注文検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="code"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              決済ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="payStatus">
                  <md-option value="unprocessed">未処理</md-option>
                  <md-option value="paid">決済済み</md-option>
                  <md-option value="processing">処理中</md-option>
                  <md-option value="refund">返金</md-option>
                  <md-option value="選択しない">選択しない</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(姓)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(名)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名(屋号)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="companyName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送先都道府県
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="gurstDeliveryPref"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送先住所<br />(都道府県以降)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="gurstDeliveryAddress"></md-input>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="md-alignment-top-center mx-auto">
          <md-button class="md-success md-lg" @click="getEcOrders(null, null, null, true, null, false, true)">検索</md-button>
          <md-button class="md-warning md-lg mL40" @click="getEcOrders(null, null, null, null, null, true)">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">注文一覧</h4>
          </md-card-header>
          <md-card-actions md-alignment="left" class="pT30">
            <md-button class="md-info md-lg" @click="getEcOrders(null, null, null, true, null)">全て</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, 'cancel')">キャンセル</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, 'ordered')">注文済</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, 'delivery_preparation')">配送準備中</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, 'delivery_completed')">配送完了</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, 'returned')">返品</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, null, true)">お気に入り</md-button>
            <md-button class="md-info md-lg mL20" @click="getEcOrders(null, null, null, null, null, null, null, true)">ゲスト注文</md-button>
          </md-card-actions>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th"></div>
                  <div class="tHead_th">メモ</div>
                  <div class="tHead_th">代表者氏名</div>
                  <div class="tHead_th">会社名(屋号)</div>
                  <div class="tHead_th">注文ステータス</div>
                  <div class="tHead_th">決済ステータス</div>
                  <div class="tHead_th">支払方法</div>
                  <div class="tHead_th">金額</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" :key="item.id" class="tItem">
                  <div class="tItem_td">
                    <md-label style="word-break:break-all;">
                      <md-icon v-if="item.favorite" @click.native="updateFavorite(item.id, false)">star</md-icon>
                      <md-icon v-else @click.native="updateFavorite(item.id, true)">star_border</md-icon>
                    </md-label>
                  </div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.memo }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.fullName }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.companyName }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.orderSalesStatus }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.payStatus }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.paymentType }}</md-label></div>
                  <div class="tItem_td">
                    <md-label style="word-break:break-all;">
                      {{ item.salesTotalPrice | numberWithDelimiter }}円
                    </md-label>
                  </div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getEcOrders">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        favorite: '',
        code: '',
        lastName: '',
        firstName: '',
        fullName: '',
        companyName: '',
        gurstDeliveryPref: '',
        gurstDeliveryAddress: '',
        tel: '',
        salesTotalPrice: null,
        orderSalesStatus: '',
        payStatus: '選択しない',
        userId: '',
        orderType: '',
        plain: '-------',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getEcOrders (page = 1, order_sales_status = '', favorite = '', is_reset = false, read = '', is_csv = false, is_user_reset = false, guest = false) {
        if (order_sales_status) {
          this.orderSalesStatus = order_sales_status
        }
        if (favorite) {
          this.favorite = favorite
        }
        if (guest) {
          this.orderType = 'guest'
        }
        if (is_reset) {
          this.orderSalesStatus = ''
          this.favorite = ''
          this.orderType = ''
        }
        if (is_user_reset) {
          this.userId = ''
        }
         var condition = {
          page:                   page,
          user_id:                this.userId,
          favorite:               this.favorite,
          code:                   this.code,
          last_name:              this.lastName,
          first_name:             this.firstName,
          company_name:           this.companyName,
          guest_delivery_pref:    this.gurstDeliveryPref,
          guest_delivery_address: this.gurstDeliveryAddress,
          guest_tel:              this.tel,
          pay_status:             this.payStatus === '選択しない' ? '' : this.payStatus,
          order_sales_status:     this.orderSalesStatus,
          order_type:             this.orderType,
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/ec_orders/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage   = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount  = response.data.meta['total-count']
            if (is_csv) {
              this.handleCsvDownload()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id:              res[i].id,
            memo:            res[i].attributes.memo,
            favorite:        res[i].attributes.favorite,
            fullName:        res[i].attributes['full-name'],
            companyName:     res[i].attributes['company-name'],
            salesTotalPrice: res[i].attributes['sales-total-price'],
          }
          // 注文ステータス(通販)
          switch (res[i].attributes['order-sales-status']) {
            case 'cancel':
              item.orderSalesStatus = 'キャンセル'
              break;
            case 'ordered':
              item.orderSalesStatus = '注文済'
              break;
            case 'delivery_preparation':
              item.orderSalesStatus = '配送準備中'
              break;
            case 'delivery_completed':
              item.orderSalesStatus = '配送完了'
              break;
            case 'returned':
              item.orderSalesStatus = '返品'
              break;
            default:
              item.orderSalesStatus = '識別不明'
          }
          // 決済ステータス
          switch (res[i].attributes['pay-status']) {
            case 'unprocessed':
              item.payStatus = '未処理'
              break;
            case 'paid':
              item.payStatus = '決済済み'
              break;
            case 'processing':
              item.payStatus = '処理中'
              break;
            case 'refund':
              item.payStatus = '返金'
              break;
            default:
              item.payStatus = '識別不明'
          }
          // 支払方法
          switch (res[i].attributes['payment-type']) {
            case 'credit_card':
              item.paymentType = 'クレジットカード'
              break;
            case 'bank':
              item.paymentType = '銀行振込'
              break;
            case 'b_to_b':
              item.paymentType = 'B2B売掛決済'
              break;
            default:
              item.paymentType = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateFavorite (id, favorite) {
        var condition = {
          ec_order: {
            favorite: favorite,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/ec_orders/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.getEcOrders(null, null, null, true, null)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お気に入りの更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        const url = '/admin/ec_order/edit/'+ item.id
        window.open(url, '_blank')
      },
      handleCsvDownload () {
        swal({
          title: `対象の${this.pageData.totalCount}件をCSV出力しますか？`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          favorite:               this.favorite,
          code:                   this.code,
          last_name:              this.lastName,
          first_name:             this.firstName,
          full_name:              this.fullName,
          company_name:           this.companyName,
          guest_delivery_pref:    this.gurstDeliveryPref,
          guest_delivery_address: this.gurstDeliveryAddress,
          order_sales_status:     this.orderSalesStatus,
          user_id:                this.userId
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/ec_orders/export_csv',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "AdminUser not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (this.$route.query.user_id) {
        this.userId = this.$route.query.user_id
        this.getEcOrders(1)
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.chat-blue {
  background: #0068FF !important;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
