<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <md-icon>today</md-icon>
          </div>
          <p class="category">今日の注文数</p>
          <h3 class="title">
            {{todayOrderNum}}
            <!-- <animated-number :value="todayOrderNum"></animated-number> -->
          </h3>
        </template>

        <template slot="footer">
          <p class="category" v-show="todayOrderNumPer !== ''">
            {{todayOrderNumPer | numberWithDelimiter}}%
            <!-- <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i> <animated-number :value="todayOrderNumPer"></animated-number>% </span> 昨日より増加 -->
          </p>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">今日の注文額</p>
          <h3 class="title">
            ¥{{todayOrderAmount | numberWithDelimiter}}
            <!-- <animated-number :formatValue="money" :duration="duration" :value="todayOrderAmount"></animated-number> -->
          </h3>
        </template>

        <template slot="footer">
          <p class="category" v-show="todayOrderAmountPer !== ''">
            {{todayOrderAmountPer}}%
            <!-- <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i> <animated-number :value="todayOrderAmountPer"></animated-number>% </span> 昨日より増加 -->
          </p>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
      <stats-card header-color="green">
        <template  slot="header">
          <div class="card-icon">
            <md-icon >store</md-icon>
          </div>
          <p class="category">今月の注文数</p>
          <h3 class="title">
            {{thismonthOrderNum | numberWithDelimiter}}
            <!-- <animated-number :value="thismonthOrderNum"></animated-number> -->
          </h3>
        </template>

        <template slot="footer">
          <p class="category" v-show="thismonthOrderNumPer !== ''">
            {{thismonthOrderNumPer}}%
            <!-- <span class="text-danger"><i class="fas fa-long-arrow-alt-down"></i> <animated-number :value="thismonthOrderNumPer"></animated-number>% </span> 先月より減少 -->
          </p>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>weekend</md-icon>
          </div>
          <p class="category">今月の注文額</p>
          <h3 class="title">
            ¥{{thismonthOrderAmount | numberWithDelimiter}}
            <!-- <animated-number :formatValue="money" :duration="duration" :value="thismonthOrderAmount"></animated-number> -->
          </h3>
        </template>

        <template slot="footer">
          <p class="category" v-show="thismonthOrderAmountPer !== ''">
            {{thismonthOrderAmountPer}}%
            <!-- <span class="text-danger"><i class="fas fa-long-arrow-alt-down"></i> <animated-number :value="thismonthOrderAmountPer"></animated-number>% </span> 先月より減少 -->
          </p>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<script>
  import StatsCard from '../components/Cards/StatsCard.vue'
  import AnimatedNumber from '../components/AnimatedNumber.vue'
  import router from '../packs/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      StatsCard,
      AnimatedNumber
    },
    props: {
    },
    data() {
      return {
        duration: 100,
        todayOrderNum: 0,
        todayOrderNumPer: 0,
        todayOrderAmount: 0,
        todayOrderAmountPer: 0,
        thismonthOrderNum: 0,
        thismonthOrderNumPer: 0,
        thismonthOrderAmount: 0,
        thismonthOrderAmountPer: 0,
        todayReserveNum: 0
      }
    },
    computed: {
    },
    methods: {
      confirmOrder () {
        router.push({ path: '/admin/pre_order_list/' })
      },
      getInfo () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/top/index/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.todayOrderNum = response.data.today_order_number
          this.todayOrderNumPer = response.data.today_number_rate_of_change
          this.todayOrderAmount = response.data.today_order_amount
          this.todayOrderAmountPer = response.data.today_amount_rate_of_change
          this.thismonthOrderNum = response.data.month_order_number
          this.thismonthOrderNumPer = response.data.month_number_rate_of_change
          this.thismonthOrderAmount = response.data.month_order_amount
          this.thismonthOrderAmountPer = response.data.month_amount_rate_of_change
          this.todayReserveNum = response.data.today_reserve_orders
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('トップ画面の情報を正しく取得できませんでした')
          }
        })
      },
      money (value) {
        return "¥" + value
      }
    },
    mounted() {
    },
    created() {
      this.getInfo()
    }
  }
</script>
<style lang="scss" scoped>
  .category{
    margin: 0;
  }
  .btnArea{
    width: 100%;
    max-width: 200px;
    height: 45px;
    margin: 0 auto;
    .btnConfirm{
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
</style>