<template>
  <md-field :class="[
                {'md-error': errors.has('userRankId')},
                {'md-valid': !errors.has('userRankId') && touched.userRankId}]" >
    <md-select v-model="userRankId"  
      data-vv-name="userRankId"
      v-validate= "avoidValidFlag == true ? '' : modelValidations.userRankId" multiple v-if="mutlipleFlag">
      <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
    </md-select>
    <md-select
      v-model="userRankId"
      data-vv-name="userRankId"
      v-validate= "avoidValidFlag == true ? '' : modelValidations.userRankId" v-if="!mutlipleFlag">
      <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
    </md-select>
    <slide-y-up-transition>
      <div class="vErrorText" v-show="errors.has('userRankId')">【必須】必ず一つ選択してください</div>
    </slide-y-up-transition>
    <slide-y-down-transition>
      <md-icon class="error" v-show="errors.has('userRankId')" style="margin-right:20px">close</md-icon>
    </slide-y-down-transition>
    <slide-y-down-transition>
      <md-icon class="success" v-show="!errors.has('userRankId') && touched.userRankId" style="margin-right:20px">done</md-icon>
    </slide-y-down-transition>
  </md-field>
</template>
<script>
  import NestedValidator from '../../mixins/nested-validator'
  export default {
    inject: ['$validator'],
    mixins: [NestedValidator],
    data() {
      return {
        listData: [],
        modelValidations: {
          userRankId: {
            required: true
          }
        },
        touched: {
          userRankId: false,
        },
      }
    },
    model: {
    },
    props:  ['value','noSelectFlag','touchedFlag', 'avoidValidFlag', 'mutlipleFlag'],    // undefinedはfalse扱い
    methods: {
      getUserRanks () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/user_ranks/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          this.dangerNotify('会員ランク情報を正しく取得できませんでした')
        })
      },
      createList (obj) {
        this.listData.splice(0)
        if (this.noSelectFlag) {
          var item = {
            id: '選択しない',
            name: '選択しない',
          }
          this.listData.push(item)
          this.userRankId = '選択しない'
        }
        for (var i in obj) {
          var item = {
            id: obj[i].id,
            name: obj[i].name
          }
          this.listData.push(item)
        }
      }
    },
    mounted () {
      this.getUserRanks()
    },watch: {
      userRankId(){
        this.touched.userRankId = this.touchedFlag
      },
      '$route.path'(value){
        if (value.match('new')) {
          this.$validator.reset()
          this.touched.userRankId = false 
        }
      }
    },computed: {
      userRankId: {
        get: function() {
          return this.value;
        },
        set: function(newValue) {
          this.$emit('input', newValue)
        }
      }
    }
  }
</script>