<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">商品検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="itemCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="itemName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              型式
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="sku"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メーカー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <maker-select ref="maker" :noSelectFlag="true"></maker-select>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              公開先
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="kind">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="subsc">サブスクキッチン</md-option>
                  <md-option value="sales">スタートキッチン</md-option>
                  <md-option value="both">両サイト公開</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              優先表示順
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="priorityPosition">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="0">無効</md-option>
                  <md-option value="1">最優先</md-option>
                  <md-option value="2">準優先</md-option>
                  <md-option value="3">3番目優先</md-option>
                  <md-option value="4">4番目優先</md-option>
                  <md-option value="5">5番目優先</md-option>
                  <md-option value="6">6番目優先</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              画像設定
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-select v-model="image">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="set">設定済み</md-option>
                  <md-option value="main_set">メイン画像のみ</md-option>
                  <md-option value="sub_set">サブ画像のみ</md-option>
                  <md-option value="unset">未設定</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;">
              優先表示開始
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="priorityStart"
                :language="ja"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="priorityStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="priorityStartTime" v-model="priorityStartTime"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" style="font-size: 13;">
              優先表示終了
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="priorityEnd"
                :language="ja"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="priorityEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <hour-select class="md-layout-item md-xsmall-size-100" ref="priorityEndTime" v-model="priorityEndTime"></hour-select>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品カテゴリ
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
              <div class="categoryContainer">
                <div class="bigCategory" v-for="item, index in categories" :key="item.id">
                  <div class="categoryDetail">
                    <div class="checkArea">
                      <md-checkbox v-model="item.check" class="categoryCheck" v-on:change="checkCategoryItem(item)">{{item.name}}</md-checkbox>
                      <div class="toggleBox">
                        <md-button
                          class="md-just-icon md-simple"
                          @click.native="item.openFlag = !item.openFlag">
                          <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                          <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                        </md-button>
                      </div>
                    </div>
                  </div>
                  <slide-y-up-transition>
                    <div class="progenyCategory" v-show="item.openFlag">
                      <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                        <div class="categoryDetail">
                          <div class="checkArea">
                            <md-checkbox v-model="itemC.check" class="categoryCheck" v-on:change="checkCategoryItemC(item, itemC)">{{itemC.name}}</md-checkbox>
                            <div class="toggleBox">
                              <md-button
                                class="md-just-icon md-simple"
                                @click.native="itemC.openFlag = !itemC.openFlag">
                                <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                                <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                              </md-button>
                            </div>
                          </div>
                        </div>
                        <slide-y-up-transition>
                          <div class="progenyCategory" v-show="itemC.openFlag">
                            <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                              <div class="categoryDetail">
                                <div class="checkArea">
                                  <md-checkbox v-model="itemCC.check" class="categoryCheck" v-on:change="checkCategoryItemCC(item, itemC, itemCC)">{{itemCC.name}}</md-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </slide-y-up-transition>
                      </div>
                    </div>
                  </slide-y-up-transition>
                </div>

              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pl33">
              <md-checkbox v-model="allCheck" class="categoryCheck"  v-on:change="checkCategoryAllCheck">すべて選択</md-checkbox>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="md-alignment-top-center mx-auto">
            <md-button class="md-success md-lg" @click="getItems">検索</md-button>
            <md-button class="md-warning md-lg mL40" @click="getItems(1, true)">CSVダウンロード</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">商品一覧</h4>
          </md-card-header>
          <md-card-actions md-alignment="left" class="pT30">
            <md-button class="md-info md-lg" @click="changePrioritySort()">{{ prioritySortFlag ? '解除' : '優先表示順' }}</md-button>
          </md-card-actions>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th txtNowrap">商品コード</div>
                  <div class="tHead_th txtNowrap">大カテゴリー</div>
                  <div class="tHead_th txtNowrap">中カテゴリー</div>
                  <div class="tHead_th txtNowrap">商品名</div>
                  <div class="tHead_th txtNowrap">公開先</div>
                  <div class="tHead_th txtNowrap">メーカー名</div>
                  <div class="tHead_th txtNowrap">ステータス</div>
                  <div class="tHead_th txtNowrap">優先表示順</div>
                  <div class="tHead_th txtNowrap">画像設定</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" :key="item.id" class="tItem">
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.code }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.bigCategoryName }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.middleCategoryName }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.kind }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.makerName }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.status }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label>{{ item.priorityPosition }}</md-label></div>
                  <div class="tItem_td commentBody200"><md-label style="word-break:break-all;">{{ item.image }}</md-label></div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="handleEdit(item.id)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      v-if="!shopFlag"
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getItems">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import MakerSelect from '../components/additional/MakerSelect.vue'
  import router from '../packs/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"
  import HourSelect from '../components/additional/HourSelect.vue'


  export default {
    mixins: [ role ],
    components: {
      Pagination,
      MakerSelect,
      HourSelect,
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 100 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 100 + 1
      }
    },
    props: {
      productData: Object
    },
    data () {
      return {
        currentSort: '',
        currentSortOrder: 'asc',
        prioritySortFlag: false,
        priorityPosition: '選択しない',
        priorityStart: null,
        priorityEnd: null,
        priorityStartTime: '',
        priorityEndTime: '',
        categories: [],
        allCheck: false,
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        resultFlag: false,
        status: '選択しない',
        kind: '選択しない',
        itemCode: '',
        itemName: '',
        sku: '',
        parentId: '',
        image: '選択しない'
      }
    },
    methods: {
      changePrioritySort () {
        this.prioritySortFlag = !this.prioritySortFlag
        this.getItems(this.pageData.currentPage, false)
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleLike (item) {
        this.successAndBackPage(`ステータスを更新しました`)
      },
      handleEdit (id) {
        const url = '/admin/product/edit/'+id
        window.open(url, '_blank')
      },
      handleDelete (item) {
        swal({
          title: 'この商品を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      getItems (page, is_csv = false) {
        var condition = {
          page: page,
          code: this.itemCode,
          name: this.itemName,
          sku: this.sku,
          maker_id: this.$refs.maker.makerId === '選択しない' ? '' : this.$refs.maker.makerId,
          status: this.status === '選択しない' ? '' : this.status,
          kind: this.kind === '選択しない' ? '' : this.kind,
          image: this.image === '選択しない' ? '' : this.image,
          categories: this.checkCategories(),
          priority_sort_flag: this.prioritySortFlag ? this.prioritySortFlag : '',
          priority_position: this.priorityPosition === '選択しない' ? '' : this.priorityPosition,
          priority_start: this.priorityStart !== null ? this.initDate(this.priorityStart) + ' ' + this.priorityStartTime : null,
          priority_end: this.priorityEnd !== null ? this.initDate(this.priorityEnd) + ' ' + this.priorityEndTime : null,
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/products/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
            if (is_csv) {
              this.handleCsvDownload()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var kind = ""
          if (res[i].attributes.kind === "subsc") {
            kind = "サブスクキッチン"
          } else if (res[i].attributes.kind === "sales") {
            kind = "スタートキッチン"
          } else if (res[i].attributes.kind === "both") {
            kind = "両サイト公開"
          }

          const imageStatus = res[i].attributes['has-main-image?']
            ? res[i].attributes['has-sub-images?'] ? '設定済み' : 'メイン画像のみ'
            : res[i].attributes['has-sub-images?'] ? 'サブ画像のみ' : '未設定';
          var item = {
            id: res[i].id,
            code: res[i].attributes.code,
            status: res[i].attributes.status === 'active' ? '有効' : '無効',
            kind: kind,
            name: res[i].attributes.name,
            sku: res[i].attributes.sku,
            makerName: res[i].attributes['maker-name'],
            bigCategoryName: res[i].attributes['big-category-name'],
            middleCategoryName: res[i].attributes['middle-category-name'],
            image: imageStatus,
            priorityPosition: this.changePriorityPositionJp(res[i].attributes['priority-position'])
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      getCategory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
          // params: condition
        }).then(response => {
          this.createNewCategoryList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createNewCategoryList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false,
            check: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
        this.sliderBgFlag = false
      },
      checkCategories () {
        var checkedCategories = []
        for (var i in this.categories) {
          if (this.categories[i].check) {
            checkedCategories.push(this.categories[i].id)
          }
          var child = this.categories[i].children
          for (var j in child) {
            if (child[j].check) {
              checkedCategories.push(child[j].id)
            }
            for (var k in child[j].children) {
              if (child[j].children[k].check) {
              checkedCategories.push(child[j].children[k].id)
              }
            }
          }
        }
        // if(checkedCategories.length === 0){
        //   this.validateError("商品カテゴリを一つ以上チェックして下さい。");
        //   exit;
        // }
        return checkedCategories.join()
      },
      // updateStatus (id, status) {
      //   var condition = {
      //     product: {
      //       'status': status,
      //     }
      //   }
      //   this.axios({
      //     method  : 'PUT',
      //     url     : this.AdminApiUrl+this.productData.apiUrl+id,
      //     timeout : 10000,
      //     headers : {'Authorization': this.$Cookies.get('accessToken')},
      //     data: condition
      //   }).then(response => {
      //     this.successAndBackPage(`ステータスを更新しました`)
      //   })
      //   .catch(error => {
      //     if (error.response.status === 401) {
      //       this.tokenError()
      //     } else {
      //       this.dangerNotify('ステータスの更新に失敗しました')
      //     }
      //   })
      // },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl+'/products/'+item.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item);
          this.successAndBackPage(`商品情報を削除しました`)
          this.getItems(1)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報を削除出来ませんでした')
          }
        })
      },
      checkCategoryItem (item) {
        if (item.check) {
          item.openFlag = true
          for (var j in item.children) {
            item.children[j].openFlag = true
            item.children[j].check = true
            for (var k in item.children[j].children) {
              item.children[j].children[k].check = true
            }
          }
        } else if (!item.check) {
          this.allCheck = false
          item.openFlag = false
          for (var j in item.children) {
            item.children[j].openFlag = false
            item.children[j].check = false
            for (var k in item.children[j].children) {
              item.children[j].children[k].check = false
            }
          }
        }
      },
      checkCategoryItemC (item, itemC) {
        if (itemC.check) {
          itemC.openFlag = true
          for (var k in itemC.children) {
            itemC.children[k].check = true
          }
        } else if (!itemC.check) {
          this.allCheck = false
          itemC.openFlag = false
          for (var k in item.children) {
            itemC.children[k].check = false
            if (!item.children[k].check) {
              item.check = false
            }
          }
        }
      },
      checkCategoryItemCC (item, itemC, itemCC) {
        if (!itemCC.check) {
          this.allCheck = false
          for (var k in itemC.children) {
            if (!itemC.children[k].check) {
              itemC.check = false
              item.check = false
            }
          }
        }
      },
      checkCategoryAllCheck () {
        if (this.allCheck) {
          for (var i in this.categories) {
            this.categories[i].openFlag = true
            this.categories[i].check = true
            var child = this.categories[i].children
            for (var j in child) {
              child[j].openFlag = true
              child[j].check = true
              for (var k in child[j].children) {
                child[j].children[k].check = true
              }
            }
          }
        } else if (!this.allCheck) {
          for (var i in this.categories) {
            this.categories[i].openFlag = false
            this.categories[i].check = false
            var child = this.categories[i].children
            for (var j in child) {
              child[j].openFlag = false
              child[j].check = false
              for (var k in child[j].children) {
                child[j].children[k].check = false
              }
            }
          }
        }
      },
      handleCsvDownload () {
        swal({
          title: `対象の${this.pageData.totalCount}件をCSV出力しますか？`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.getCsvDownload()
          }
        });
      },
      getCsvDownload () {
        var condition = {
          code: this.itemCode,
          name: this.itemName,
          sku: this.sku,
          maker_id: this.$refs.maker.makerId === '選択しない' ? '' : this.$refs.maker.makerId,
          status: this.status === '選択しない' ? '' : this.status,
          kind: this.kind === '選択しない' ? '' : this.kind,
          categories: this.checkCategories(),
          priority_position: this.priorityPosition === '選択しない' ? '' : this.priorityPosition,
          priority_start: this.priorityStart !== null ? this.initDate(this.priorityStart) + ' ' + this.priorityStartTime : null,
          priority_end: this.priorityEnd !== null ? this.initDate(this.priorityEnd) + ' ' + this.priorityEndTime : null,
        }
        this.successAndBackPage("CSVのアップロードを開始しました。完了後メールでお知らせします。")
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/products/export_csv',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data.error === "AdminUser not found")  {
            this.noSearchResults()
          } else {
            this.dangerNotify('CSVダウンロードに失敗しました')
          }
        })
      },
      changePriorityPositionJp (priorityPosition) {
        var priorityPositionJp
        switch (priorityPosition) {
          case 0:
            priorityPositionJp = '無効'
            break;
          case 1:
            priorityPositionJp = '最優先'
            break;
          case 2:
            priorityPositionJp = '準優先'
            break;
          case 3:
            priorityPositionJp = '3番目優先'
            break;
          case 4:
            priorityPositionJp = '4番目優先'
            break;
          case 5:
            priorityPositionJp = '5番目優先'
            break;
          case 6:
            priorityPositionJp = '6番目優先'
            break;
          default:
            priorityPositionJp = '識別不明'
        }
        return priorityPositionJp
      },
    },
    mounted () {
      // role check
      // if (this.shopFlag || !this.opt_preOrder) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
      this.getCategory()
    },
    watch: {
      priorityStart(){
        this.$refs.priorityStartTime.unlock()
      },
      priorityEnd(){
        this.$refs.priorityEndTime.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.pl33{
  padding-left: 33px;
}

.wth100{
  width: 100px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}

.txtNowrap {
  white-space: nowrap
}

</style>
