<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">CSV検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              処理日時
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click.native="getCsv">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">CSV一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">処理日時</div>
                  <div class="tHead_th">ファイル名</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.createdAt }}</div>
                  <div class="tItem_td">{{ item.fileName }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="handleDownload(item)">
                      <md-icon>download</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getCsv">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from './Pagination.vue'
  import swal from 'sweetalert2'

  export default {
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    props: {
      className: Object
    },
    data () {
      return {
        resultFlag: false,
        fileName: '',
        selectedStart: '',
        selectedEnd: '',
        currentSort: 'tel',
        currentSortOrder: 'asc',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
      }
    },
    methods: {
      getCsv (page) {
        var condition = {
          page: page,
          public_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          public_to: this.selectedEnd === '' ? '' : this.initDate(this.selectedEnd),
          class_name: this.className
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/export_logs/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('CSVデータを正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            fileName: res[i].attributes['file-name'],
            createdAt: res[i].attributes['created-at'],
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleDownload (item) {
        var condition = {
          file_name: item.fileName
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/export_logs/' + item.id + '/download',
          timeout : 30000,
          responseType: 'arraybuffer',
          headers : {'Authorization': this.$Cookies.get('accessToken'), 'Accept': 'application/zip'},
          params: condition
        }).then(response => {
          var blob = new Blob([response.data], { "type" : "application/x-zip-compressed" })
          var uri = URL.createObjectURL(blob)
          var link = document.createElement('a')
          link.download = response.config.params.file_name
          link.href = uri
          link.click()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('CSVデータのダウンロードに失敗しました')
          }
        })
      },
      handleDelete (item) {
        swal({
          title: `このCSVデータを削除してもよろしいですか?`,
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/export_logs/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          var $this = this
          swal({
            title: '削除完了',
            text: `CSVデータを削除しました`,
            type: 'success',
            confirmButtonClass: 'md-button md-success btn-fill',
            buttonsStyling: false
          }).then (function () {
            sessionStorage.removeItem('shopSelect')
            $this.getCsv(1)
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('CSVデータを削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      }
    },
    mounted () {
    },
    destroyed() {
      sessionStorage.removeItem('brandSelect') 
    },
    watch: {
      companyId () {
        this.$refs.brand.companyId = this.companyId
        this.$refs.brand.resetBrand()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.btnSpace{
  margin: 0 2px;
}

.readonlyBg{
  background-color: rgb(211, 211, 211);
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-switch{
  margin-top: 20px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
