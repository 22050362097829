<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">キャンペーン情報</h4>
        </md-card-header>
        <md-card-content>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field class="CampaignStatus">
                <md-select
                  v-model="status"
                  name="status"
                  @input="checkEmpty"
                  :language="ja"
                  v-validate= "modelValidations.status"
                >
                  <md-option value="not_active">無効</md-option>
                  <md-option value="active">有効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】いずれかを選択してください</div>
                </slide-y-up-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャンペーン期間
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="campaignStart"
                @input="checkEmpty"
                :language="ja"
                data-vv-name="campaignStart"
                v-validate= "modelValidations.campaignStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="campaignStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('campaignStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                  <md-icon class="error dateError" v-show="errors.has('campaignStart')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success dateSuccess" v-show="!errors.has('campaignStart') && touched.campaignStart">done</md-icon>
                </slide-y-down-transition>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                v-model="campaignEnd"
                @input="checkEmpty"
                :language="ja"
                data-vv-name="campaignEnd"
                v-validate= "modelValidations.campaignEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="campaignEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('campaignEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('campaignEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('campaignEnd') && touched.campaignEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              適用サイト
            </label>
            <div class="md-layout-item">
              <md-radio v-model="campaignPublic" value="subscription_site">サブスク用サイト</md-radio>
              <md-radio v-model="campaignPublic" value="mail_order_site" >通販用サイト</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャンペーン種別
            </label>
            <div class="md-layout-item">
              <md-radio v-model="kind" value="discount" >値引き</md-radio>
              <md-radio v-model="kind" value="cashback" >キャッシュバック</md-radio>
              <md-radio v-model="kind" value="discount_rate" >値引率</md-radio>
              <md-radio v-model="kind" value="present" >プレゼント</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100 pt20">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'discount'">
              値引き
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-if="kind === 'discount'">
              <md-field :class="[
                {'md-error': errors.has('discount')},
                {'md-valid': !errors.has('discount') && touched.discount}]">
                <md-input
                  v-model="discount"
                  data-vv-name="discount"
                  v-validate= "modelValidations.discount"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('discount')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('discount')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('discount') && touched.discount" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-size-100 pt20" v-if="kind === 'cashback'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャッシュバック
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <md-field :class="[
                {'md-error': errors.has('cashBackPrice')},
                {'md-valid': !errors.has('cashBackPrice') && touched.cashBackPrice}]">
                <md-input
                  v-model="cashBackContent"
                  data-vv-name="cashBackPrice"
                  v-validate= "modelValidations.cashBackPrice"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('cashBackPrice')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('cashBackPrice')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('cashBackPrice') && touched.cashBackPrice" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100 pt20">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100" v-if="kind === 'discount_rate'">
              値引率
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100" v-if="kind === 'discount_rate'">
              <md-field :class="[
                {'md-error': errors.has('discountRate')},
                {'md-valid': !errors.has('discountRate') && touched.discountRate}]">
                <md-input
                  v-model="discountRate"
                  data-vv-name="discountRate"
                  v-validate= "modelValidations.discountRate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('discountRate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('discountRate')" style="margin-right:30px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('discountRate') && touched.discountRate" style="margin-right:30px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">％</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100"  v-if="kind === 'present'">
              プレゼント内容
            </label>
            <div class="md-layout-item" v-if="kind === 'present'">
              <md-field>
                <md-textarea id="txtAreaProfile" class="txtArea" v-model="presentContent" md-counter="140" maxlength="140"
                  data-vv-name="presentContent"
                  type="text"
                  v-validate= "modelValidations.presentContent"
                  required>
                </md-textarea>
                <slide-y-up-transition>
                  <div class="vErrorText bt-15" v-show="errors.has('presentContent')">【必須】入力してください</div>
                </slide-y-up-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              タイトル
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field>
                <md-input
                  v-model="title"
                  name="title"
                  @input="checkEmpty"
                  :language="ja"
                  v-validate= "modelValidations.title"></md-input>
                <slide-y-up-transition>
                    <div class="vErrorText" v-show="errors.has('title')">【必須】入力してください</div>
                </slide-y-up-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              内容
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="content">
              </froala>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('content')">【必須】入力してください</div>
              </slide-y-up-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              商品カテゴリ
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
              <div class="categoryContainer">
                <div class="bigCategory" v-for="item, index in categories" :key="item.id">
                  <div class="categoryDetail">
                    <div class="checkArea">
                      <md-checkbox v-model="item.check" class="categoryCheck" v-on:change="checkCategoryItem(item)">{{item.name}}</md-checkbox>
                      <div class="toggleBox">
                        <md-button
                          class="md-just-icon md-simple"
                          @click.native="item.openFlag = !item.openFlag">
                          <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                          <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                        </md-button>
                      </div>
                    </div>
                  </div>
                  <slide-y-up-transition>
                    <div class="progenyCategory" v-show="item.openFlag">
                      <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                        <div class="categoryDetail">
                          <div class="checkArea">
                            <md-checkbox v-model="itemC.check" class="categoryCheck" v-on:change="checkCategoryItemC(item, itemC)">{{itemC.name}}</md-checkbox>
                            <div class="toggleBox">
                              <md-button
                                class="md-just-icon md-simple"
                                @click.native="itemC.openFlag = !itemC.openFlag">
                                <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                                <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                              </md-button>
                            </div>
                          </div>
                        </div>
                        <slide-y-up-transition>
                          <div class="progenyCategory" v-show="itemC.openFlag">
                            <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                              <div class="categoryDetail">
                                <div class="checkArea">
                                  <md-checkbox v-model="itemCC.check" class="categoryCheck"  v-on:change="checkCategoryItemCC(item, itemC, itemCC)">{{itemCC.name}}</md-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </slide-y-up-transition>
                      </div>
                    </div>
                  </slide-y-up-transition>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" v-show="kind !== 'discount_rate'">
              購入数
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100"  v-show="kind !== 'discount_rate'">
              <md-field :class="[
                {'md-error': errors.has('purchaseNumberMore')},
                {'md-valid': !errors.has('purchaseNumberMore') && touched.purchaseNumberMore}]">
                <md-input
                  v-model="purchaseNumberMore"
                  @input="checkEmpty"
                  data-vv-name="purchaseNumberMore"
                  v-validate= "modelValidations.purchaseNumberMore"
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('purchaseNumberMore')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <span class="md-suffix">個以上</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-size-100 pt20">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100" v-show="kind !== 'discount_rate'">
              購入金額
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" v-show="kind !== 'discount_rate'">
              <md-field :class="[
                {'md-error': errors.has('purchaseMoneyMore')},
                {'md-valid': !errors.has('purchaseMoneyMore') && touched.purchaseMoneyMore}]">
                <md-input
                  v-model="purchaseMoneyMore"
                  @input="checkEmpty"
                  data-vv-name="purchaseMoneyMore"
                  v-validate= "modelValidations.purchaseMoneyMore"
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('purchaseMoneyMore')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <span class="md-suffix">円以上</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-size-100 pt20" v-show="kind === 'discount_rate'">
            <div class="vErrorText" v-show="this.checkedCategoriesFlag() == false">【必須】商品カテゴリを一つ以上チェックして下さい。</div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              キャンペーン画像
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageInfo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageInfo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageInfo" data-id="imageInfo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageInfo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageInfo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">{{ buttonWord }}</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import CompanySelect from '../components/additional/CompanySelect.vue'
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import swal from 'sweetalert2'
  import EventBus from '../mixins/event-bus.js';
  import qs from 'qs'

  export default {
    mixins: [ role ],
    components: {
      CompanySelect
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        buttonWord: '登録',
        newFlag: false,
        status: '',
        campaignStart: '',
        campaignEnd: '',
        campaignPublic: 'subscription_site',
        kind: 'discount',
        discount: '',
        discountRate: '',
        title: '',
        content: '',
        purchaseNumberMore: '',
        purchaseNumberLess: '',
        purchaseMoneyMore: '',
        purchaseMoneyLess: '',
        cashBackContent: '',
        presentContent: '',
        categories: [],
        imageInfo: '',
        useLimitStart: '',

        touched: {
          status: false,
          title: false,
          discount: false,
          discountRate: false,
          purchaseNumberMore: false,
          purchaseMoneyMore: false,
          cashBackPrice: false,
          categories: false,
          content: false,
          campaignStart: false,
          campaignEnd: false,
          useLimitStart: false
        },
        validateUseLimitStart: false,

        modelValidations: {
          status: {
            required: true
          },
          title: {
            required: true
          },
          discount: {
            max: 8,
            required: true,
            numeric: true
          },
          discountRate: {
            max: 2,
            required: true,
            numeric: true
          },
          purchaseNumberMore: {
            max: 8,
            numeric: true
          },
          purchaseMoneyMore: {
            max: 8,
            numeric: true
          },
          categories: {
            required: true
          },
          cashBackPrice: {
            max: 8,
            required: true,
            numeric: true
          },
          campaignStart: {
            required: true
          },
          campaignEnd: {
            required: true
          },
          useLimitStart: {
            required: true
          }
        }
      }
    },
    methods: {
      checkEmpty () {
        if ((this.useLimitStart == '' && this.useLimitEnd == '') || (this.useLimitStart != '' && this.useLimitEnd != '')) {
          this.validateUseLimitStart = false
          this.validateUseLimitEnd = false
        } else if (this.useLimitStart != '' && this.useLimitEnd == ''){
          this.validateUseLimitStart = false
          this.validateUseLimitEnd = true
        } else if (this.useLimitStart == '' && this.useLimitEnd != ''){
          this.validateUseLimitStart = true
          this.validateUseLimitEnd = false
        } else {
          this.validateUseLimitStart = true
          this.validateUseLimitEnd = true
        }
      },
      returnBack () {
        router.push({ path: '/admin/campaign_list/' })
      },
      validate() {
        EventBus.$emit('validate', this.errors)
        this.$validator.validateAll().then(isValid => {
          if (this.errors.items.length == 0 ) {
            this.updateCampaign()
          } else {
            this.validateError()
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      
      getCategory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createNewCategoryList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createNewCategoryList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false,
            check: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false,
              check: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false,
                check: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
        this.sliderBgFlag = false
      },

      sleep (time) {
        return new Promise(resolve => {
          setTimeout(resolve, time)
        })
      },

      async createCategoryListLoop (response) {
        try {
          for (let i = 0; i < 10; i++) {
            if (this.categories) {
              this.createCategoryList(response.data.categories)
              break;
            } else if (i === 9) {
              throw "商品情報を正しく取得できませんでした";
            } else {
              await this.sleep(500)
            }
          }
        } catch (error) {
          this.dangerNotify(error)
        }
      },
      createCategoryList (obj) {
        var res = obj
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].name,
            children: [],
            openFlag: res[i].checked,
            check: res[i].checked
          }
          var child = res[i].children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: child[j].checked,
              check: child[j].checked
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: child[j].children[k].checked,
                check: child[j].children[k].checked
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
      },
      checkCategories () {
        var checkedCategories = []
        for (var i in this.categories) {
          checkedCategories.push(this.categories[i].check ? this.categories[i].id : "")
          var child = this.categories[i].children
          for (var j in child) {
            checkedCategories.push(child[j].check ? child[j].id : "")
            for (var k in child[j].children) {
              checkedCategories.push(child[j].children[k].check ? child[j].children[k].id : "")
            }
          }
        }
        return checkedCategories.join()
      },
      checkedCategoriesFlag() {
        var checkedCategories = []
        var unCheckedCategories = 0
        var categoryQuantity = 0
        for (var i in this.categories) {
          checkedCategories.push(this.categories[i].check ? this.categories[i].id : "")
          var child = this.categories[i].children
          for (var j in child) {
            checkedCategories.push(child[j].check ? child[j].id : "")
            for (var k in child[j].children) {
              checkedCategories.push(child[j].children[k].check ? child[j].children[k].id : "")
            }
          }
        }
        unCheckedCategories = checkedCategories.filter(el => el === "").length
        categoryQuantity = checkedCategories.length
        if (unCheckedCategories == categoryQuantity) {
          return false
        }
      },
      checkCategoryItem (item) {
        if (!item.check) {
          this.allCheck = false
          item.openFlag = false
          for (var j in item.children) {
            item.children[j].openFlag = false
            item.children[j].check = false
            for (var k in item.children[j].children) {
              item.children[j].children[k].check = false
            }
          }
        }
      },
      checkCategoryItemC (item, itemC) {
        if (itemC.check) {
          item.check = true
        } else if (!itemC.check) {
          this.allCheck = false
          itemC.openFlag = false
          for (var k in itemC.children) {
            itemC.children[k].check = false
          }
        }
      },
      checkCategoryItemCC (item, itemC, itemCC) {
        if (itemCC.check) {
          item.check = true
          itemC.check = true
        } else if (!itemCC.check) {
          this.allCheck = false
        }
      },

      getCampaign () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/campaigns/' + this.$route.params.id,
          timeout : 50000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.status = response.data.status
          this.campaignStart = response.data.campaign_start
          this.campaignEnd = response.data.campaign_end
          this.campaignPublic = response.data.public
          this.kind = response.data.kind
          this.discount = response.data.discount
          this.discountRate = response.data.discount_rate
          this.title = response.data.name
          this.content = response.data.txt
          this.purchaseNumberMore = (response.data.purchase_num_more == null) ? "" : response.data.purchase_num_more
          this.purchaseNumberLess = response.data.purchase_num_less
          this.purchaseMoneyMore = (response.data.purchase_money_more == null) ? "" : response.data.purchase_money_more
          this.purchaseMoneyLess = response.data.purchase_money_less
          this.cashBackContent = response.data.cashback
          this.presentContent = response.data.present_txt
          this.imageInfo = response.data.img_url
          this.createCategoryListLoop(response)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('キャンペーン情報を正しく取得できませんでした')
          }
        })
      },
      updateCampaign () {
        var condition = {
          campaign: {
            id: this.$route.params.id,
            status: this.status,
            campaign_start: this.campaignStart,
            campaign_end: this.campaignEnd,
            public: this.campaignPublic,
            kind: this.kind,
            discount: this.discount,
            discount_rate: this.discountRate,
            name: this.title,
            txt: this.content,
            purchase_num_more: this.purchaseNumberMore,
            purchase_num_less: this.purchaseNumberLess,
            purchase_money_more: this.purchaseMoneyMore,
            purchase_money_less: this.purchaseMoneyLess,
            present_txt: this.presentContent,
            cashback: this.cashBackContent,
            img: this.imageInfo,
            categories: this.checkCategories()
          }
        }
        // 画像がURLかどうか判別して追加
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.campaign.image = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.campaign.image = this.imageInfo
        }
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.campaign.sub_service_logo = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.campaign.sub_service_logo = this.imageInfo
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/campaigns/' : this.AdminApiUrl + '/campaigns/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        })
        .then(response => {
          this.successAndBackPage(`キャンペーン情報を`+this.buttonWord+`しました`,'/admin/campaign_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else if (error.response.data && error.response.data.error === "バリデーションに失敗しました。 Applicable conditionsを入力してください。") {
            this.dangerNotify('購入数、商品カテゴリ、購入金額のいずれかを選択してください')
          } else {
            this.dangerNotify(`キャンペーン情報を`+this.buttonWord+`できませんでした`)
          }
        })
      },
    },
    mounted () {
      // if (this.shopFlag) {
      //   router.push({ path: '/admin/login'})
      //   return false
      // }
      this.getCategory()


      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getCampaign()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getCampaign()
          
        }
      },
      imageInfo () {
        this.touched.imageInfo = true
      },
      campaignStart () {
        this.touched.campaignStart = true
      },
      campaignEnd () {
        this.touched.campaignEnd = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}

.pl33{
  padding-left: 33px;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.bt-15 {
  bottom: -15px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

.icons{
  display: inline-block;
  font-size: 14px;
  width: 25px;
  text-align: center;
  margin: 2px;
}

.icons:hover{
  background-color: #ccccff;
  cursor: pointer;
}

</style>
