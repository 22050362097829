<template>
  <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <div class="sheets" id="sheets">
          <div v-for="item in tableData" :key="item.id" class="tItem">
            <div class="sheet" id="sheet">
              <md-card-header style="text-align: center;">
                <h2>発注書</h2>
              </md-card-header>
              <hr width="50%">

              <md-card-content style="display: flex; flex-wrap: wrap;">
                <div style="display: flex; width: 45%; padding: 5px 5px; align-items: center;">
                  <div style="text-align: left; width: 100%;">
                    <p class="form-control-static">
                      {{ item.supplierName }}　様<br>
                      FAX/TEL: {{ item.supplierZip }}<br>
                      〒{{ item.supplierZip }}<br>
                      {{ item.supplierPref }}
                    </p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 5px; align-items: center;">
                  <label style="text-align: left; padding-right: 5px; width: 35%;">
                    日付<br>
                    取引(注文)番号<br>
                    設置日(配送日)
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">
                      {{ today | dateFormatJapanese }}<br>
                      {{ item.orderCode }}<br>
                      {{ item.deliveryDate }}
                    </p>
                  </div>
                </div>

                <div style="display: flex; width: 28%; padding: 5px 15px; align-items: center;">
                </div>

                <div style="display: flex; width: 30%; padding: 5px 15px; align-items: center;">
                  <div style="text-align: left; width: 100%;">
                    <p class="form-control-static">
                      株式会社富士工業所<br>
                      〒116-0011<br>
                      東京都荒川区西尾久4-8-10<br>
                      電話: 03-3893-7658<br>
                      担当: 侍　太郎
                    </p>
                  </div>
                </div>

                <div style="display: flex; width: 20%; padding: 5px 15px; align-items: center;">
                  <div style="width: 100%; padding: 0 10px;">
                    <img src="../../../assets/images/stamp.jpg" alt="電子印">
                  </div>
                </div>

                <div style="display: flex; width: 100%; padding: 5px 15px;">
                  <div style="text-align: left; width: 100%;">
                    <p class="form-control-static">
                      下記通りご請求申し上げます。
                    </p>
                  </div>
                </div>

              </md-card-content>

              <md-card-header style="display: flex; flex-wrap: wrap; margin-bottom: 10mm;">
                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <div style="text-align: left; padding-right: 5px; width: 100%;">
                    <h4 style="border-bottom: 1px solid #A9A9A9;">総合計金額　￥　　{{ item.totalPrice | numberWithDelimiter }}　　　円</h4>
                  </div>
                </div>
              </md-card-header>

              <md-card-content style="display: flex; flex-wrap: wrap; margin-bottom: 10mm;">
                <div style="display: flex; width: 100%; padding: 5px 15px; align-items: center;">
                  <table style="border-bottom: 1px solid #A9A9A9; border-collapse: collapse; table-layout: fixed; text-align: center;">
                    <tr>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">商品コード</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">商品名</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">型式</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">数量</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">単価</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">税</th>
                      <th style="border-bottom: 1px solid #A9A9A9; padding: 0 5px;">金額</th>
                    </tr>
                    <tr>
                      <td style="height: 60px; padding: 0 5px;">{{ item.productCode }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.productName }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.productSku }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.productNum }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.orderPrice | numberWithDelimiter }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.tax | numberWithDelimiter }}</td>
                      <td style="height: 60px; padding: 0 5px;">{{ item.totalOrderPriceTax | numberWithDelimiter }}</td>
                    </tr>
                  </table>
                </div>
              </md-card-content>

              <md-card-content style="display: flex; flex-wrap: wrap; margin-bottom: 67.5mm;">
                <div style="width: 100%; padding: 5px 15px;">
                  <label style="text-align: left;">
                    備考欄
                  </label>
                  <div style="width: 100%;">              
                    <md-field style="width: 100%;">
                      <md-textarea id="txtAreaProfile" style="width: 100%;" v-model="item.remarks" v-html="item.remarks"></md-textarea>
                    </md-field>
                  </div>
                </div>
              </md-card-content>
            </div>
          </div>
        </div>

        <md-card-actions md-alignment="center" class="mx-auto center-btn no-display">
          <md-button class="md-success md-lg mL40 center-btn" type="primary" @click="handlePrint">印刷</md-button>
        </md-card-actions>
      </div>
    </transition>
  </md-card>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      productCode: '',
      productSku: '',
      productNum: '',
      remarks: '',
      tax: '',
      supplierName: '',
      supplierTel: '',
      supplierZip: '',
      supplierPref: '',
      today: new Date(),
      deliveryDate: '',
      totalPrice: '',
      orderPrice: '',
      totalOrderPriceTax: '',
      detailBgFlag: false,
      detailFlag: false,
      tableData: []
    }
  },
  methods: {
    getOrderForms(condition) {
      this.axios({
        method  : 'POST',
        url     : this.AdminApiUrl + '/order_details/purchase_orders',
        timeout : 10000,
        headers : {'Authorization': this.$Cookies.get('accessToken')},
        data: condition
      })
      .then(response => {
        this.createList(response)
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.tokenError()
        } else {
          this.dangerNotify('発注書用情報の取得に失敗しました')
        }
      })
    },
    createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            supplierName: res[i].product && res[i].product.supplier_name,
            supplierTel: res[i].product && res[i].product.supplier_tel,
            supplierZip: res[i].product && res[i].product.supplier_zip,
            supplierPref: res[i].product && res[i].product.supplier_pref,
            deliveryDate: res[i].delivery_date,
            productCode: res[i].product_code,
            productName: res[i].product_name,
            productSku: res[i].product_sku,
            productNum: res[i].product_num,
            orderPrice: res[i].order_price,
            totalOrderPriceTax: res[i].total_order_price_tax,
            remarks: res[i].remarks,
            totalPrice: res[i].total_price,
            tax: res[i].tax,
            orderCode: res[i].order_code,
          }
          this.detailBgFlag = true
          this.detailFlag =  true
          this.tableData.push(item)
        }
        this.resultFlag = true
      },

    handlePrint() {
      window.document.body.innerHTML = document.getElementById(
        'sheets'
      ).innerHTML
      window.print()
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    dateDivider (dates) {
      var dateArray = dates.split('T')
      return dateArray
    },
    closeModal () {
      this.detailBgFlag = false
      this.detailFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.formLayout{
  flex-basis: auto;
}

.mb8 {
  margin-bottom: 12px !important;
}

.md-form-label {
  padding: 5px 5px 0 0 !important;
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.md-card .md-card-header {
  padding: 0 1.25rem;
}

.md-card .md-card-content {
  padding: 0 30px;
  margin-top: 40px;
}

.under {
  border-bottom: 1px solid #A9A9A9;
}

.right {
  text-align: right;
}

h2 {
  margin-top: 5px;
  margin-bottom: 3px;
}

table, th {
  border-bottom: 1px solid #A9A9A9;
}

td {
  height: 60px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  body {
    width: 210mm;
  }


  .sheet {
    padding: 0 5mm;
    margin: 0 5mm;
  }

  .no-display {
    display: none;
  }

  .md-layout,
  .md-layout-item{
    width: 100%;
  }

  .center {
    text-align: center;
  }
}

@media screen {
  .sheet {
    width: 210mm;
    margin: 5mm;
    padding: 5mm;
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
  }
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>