<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">基本情報設定</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントコード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spCode')},
                {'md-valid': !errors.has('spCode') && touched.spCode}]">
                <md-input
                  v-model="spCode"
                  data-vv-name="spCode"
                  type="text"
                  v-validate= "modelValidations.spCode">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spCode')">255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spCode')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spCode') && touched.spCode">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div> -->

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントパスワード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spPassword')},
                {'md-valid': !errors.has('spPassword') && touched.spPassword}]">
                <md-input
                  v-model="spPassword"
                  data-vv-name="spPassword"
                  type="text"
                  v-validate= "modelValidations.spPassword">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spPassword')">255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spPassword')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spPassword') && touched.spPassword">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div> -->

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ソニーペイメントトークン
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('spToken')},
                {'md-valid': !errors.has('spToken') && touched.spToken}]">
                <md-input
                  v-model="spToken"
                  data-vv-name="spToken"
                  type="text"
                  v-validate= "modelValidations.spToken">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('spToken')">255文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('spToken')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('spToken') && touched.spToken">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div> -->

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              e-SCOTT パスワード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('escottPassword')},
                {'md-valid': !errors.has('escottPassword') && touched.escottPassword}]">
                <md-input
                  v-model="escottPassword"
                  data-vv-name="escottPassword"
                  type="text"
                  v-validate= "modelValidations.escottPassword">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('escottPassword')">6文字以上12文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('escottPassword')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('escottPassword') && touched.escottPassword">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div> -->

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              消費税
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('tax')},
                {'md-valid': !errors.has('tax') && touched.tax}]">
                <md-input
                  v-model="tax"
                  data-vv-name="tax"
                  type="text"
                  v-validate= "modelValidations.tax">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tax')">3文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tax')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tax') && touched.tax">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">%</div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              配送料無料価格
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('freeShippingPrice')},
                {'md-valid': !errors.has('freeShippingPrice') && touched.freeShippingPrice}]">
                <md-input
                  v-model="freeShippingPrice"
                  data-vv-name="freeShippingPrice"
                  type="text"
                  v-validate= "modelValidations.freeShippingPrice">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('freeShippingPrice')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('freeShippingPrice')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('freeShippingPrice') && touched.freeShippingPrice">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>

          <!-- <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会社ロゴ
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
              <div class="file-input">
                <div v-if="!imageCompany">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageCompany" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageCompany" data-id="imageCompany"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageCompany">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageCompany">
                  </md-button>
                </div>
              </div>
            </div>
          </div> -->

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特定商取引法<br>(サブスクサイト)
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="subscLaw"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              特定商取引法<br>(通販サイト)
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="shoppingLaw"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              プライバシーポリシー
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="servicePolicy"></froala>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              利用規約
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="serviceTerms"></froala>
            </div>
          </div>


        </md-card-content>

        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>

    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        buttonWord: '更新',
        imageCompany: '',
        subscLaw: '',
        shoppingLaw: '',
        tax: 0,
        freeShippingPrice: 0,
        serviceTerms: '',
        servicePolicy: '',
        basicLock: false,
        spCode: '',
        spPassword: '',
        spToken: '',
        escottPassword: '',
        touched: {
          spCode: false,
          spPassword: false,
          spToken: false,
          escottPassword: false,
          tax: false,
          freeShippingPrice: false,
        },
        modelValidations: {
          spCode: {
            max: 255,
            alpha_num: true
          },
          spPassword: {
            max: 255,
            alpha_num: true
          },
          spToken: {
            max: 255,
            alpha_num: true
          },
          escottPassword: {
            alpha_num: true,
            min: 6,
            max: 12
          },
          tax: {
            alpha_num: true,
            max: 3
          },
          freeShippingPrice: {
            numeric: true,
            max: 8
          }
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateService()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      getService () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.imageCompany = response.data.logo_url
          this.subscLaw = response.data.subsc_law
          this.shoppingLaw = response.data.shopping_law
          this.tax = response.data.tax
          this.freeShippingPrice = Math.floor(response.data.free_shipping_price)
          this.serviceTerms = response.data.terms_of_service
          this.servicePolicy = response.data.privacy_policy
          this.getInfo()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('基本情報を正しく取得できませんでした')
          }
        })
      },
      updateService(){
        var condition = {
          service_setting: {
            company_id: this.$route.params.id,
            subsc_law: this.subscLaw,
            shopping_law: this.shoppingLaw,
            tax: this.tax,
            free_shipping_price: this.freeShippingPrice,
            terms_of_service: this.serviceTerms,
            privacy_policy: this.servicePolicy,
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageCompany === '' || this.imageCompany === null){
          condition.service_setting.logo = ''
        } else if (this.imageCompany.indexOf('/uploads/') == -1) {
          condition.service_setting.logo = this.imageCompany
        }

        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/service_settings/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.updateInfo()
          this.successAndBackPage(`基本情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('基本情報を正しく更新できませんでした')
          }
        })
      },
      getInfo () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.spCode = response.data.sps_code
          this.spPassword = response.data.sps_password
          this.spToken = response.data.sps_token
          this.escottPassword = response.data.escott_password
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ログインIDかパスワードが違います')
          }
        })
      },
      updateInfo(){
        var condition = {
          company: {
            sps_code : this.spCode,
            sps_password : this.spPassword,
            sps_token : this.spToken,
            escott_password : this.escottPassword,
          }
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/companies/' : this.AdminApiUrl + '/companies/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('企業情報を正しく登録できませんでした')
          }
        })
      },
    },
    mounted () {
      // role check
      if (this.companyFlag) {
        this.basicLock = true
        var pt = this.$route.path
        pt = pt.split('/edit/')
        if (pt[1] !== this.$Cookies.get('companyId')) {
          router.push({ path: '/admin/login'})
          return false
        }
      } else if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getService()
    },
    watch: {
      spCode() {
        this.touched.spCode = true
      },
      spPassword() {
        this.touched.spPassword = true
      },
      spToken() {
        this.touched.spToken = true
      },
      escottPassword() {
        this.touched.escottPassword = true
      },
      tax() {
        this.touched.tax = true
      },
      freeShippingPrice() {
        this.touched.freeShippingPrice = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.pt20{
  padding-top: 20px;
}

.is-l-none{
  display: none;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
