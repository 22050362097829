<template>
  <div>
  <transition name="fade">
    <div class="sliderBg" v-show='snippetBgFlag' @click="closeModal"></div>
  </transition>
  <transition name="fade">
    <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="snippetFlag">
      <div class="btnClose" @click="closeModal">
        <md-icon>close</md-icon>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>search</md-icon>
              </div>
              <h4 class="title">スニペット</h4>
            </md-card-header>
            <md-card-content class="formLayout">
              <div class="md-layout md-layout-item md-size-100"> <label class="md-layout-item md-size-10 md-form-label md-xsmall-size-100">
                タイトル
              </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="snippetTitle" data-vv-name="snippetTitle" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-layout-item md-size-100"> <label class="md-layout-item md-size-10 md-form-label md-xsmall-size-100">
                内容
              </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-textarea v-model="snippetContent" data-vv-name="snippetContent" type="text"> </md-textarea>
                  </md-field>
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between" style="border-top: 0;" class="mx-auto">
              <md-button class="md-success md-lg btnCenter" :disabled="(!(this.snippetTitle && this.snippetContent))" @click.native="createSnippet(snippetTitle, snippetContent)"> 登録 </md-button>
            </md-card-actions>
          </md-card>
        </div>
        <transition name="fade">
          <div class="md-layout-item md-size-100" v-if="resultFlag">
            <md-card>
              <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                  <md-icon>assignment</md-icon>
                </div>
                <h4 class="title">スニペット一覧</h4>
              </md-card-header>
              <md-card-content>
                <div class="dataTableWrapper">
                  <div class="dataTable">
                    <div class="tHead">
                      <div class="tHead_th">タイトル</div>
                      <div class="tHead_th">内容</div>
                      <div class="tHead_th taR">アクション</div>
                    </div>
                    <div v-for="item in tableData" :key="item.id" class="tItem">
                      <div class="tItem_td commentBody">
                        <md-field class="z-index-0">
                          <md-input v-model="item.title"></md-input>
                        </md-field>
                      </div>
                      <div class="tItem_td commentBody">
                        <md-field>
                          <md-textarea v-model="item.content"></md-textarea>
                        </md-field>
                      </div>
                      <div class="tItem_td taR">
                        <md-button class="md-just-icon md-success mg0" @click.native="updateSnippet(item.id, item.title, item.content)">
                          <md-icon>autorenew</md-icon>
                        </md-button>
                        <md-button class="md-just-icon md-warning mg0" @click.native="useSnippet(item.content)">
                          <md-icon>dvr</md-icon>
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </transition>
      </div>
    </div>
  </transition>
  </div>
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  export default {
    components: {
    },
    data() {
      return {
        snippetTitle: '',
        snippetContent: '',
        resultFlag: false,
        tableData:[],
        snippetBgFlag: false,
        snippetFlag: false,
      }
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    computed: {
    },
    methods: {
      getSnippets () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/snippets',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createList(response.data)
          this.snippetBgFlag = true
          this.snippetFlag = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スニペット情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            title: res[i].attributes.title,
            content: res[i].attributes.content,
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateSnippet (id, title, content) {
        var condition = {
          snippet: {
            'title': title,
            'content': content
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/snippets/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`スニペットを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スニペットの更新に失敗しました')
            this.$router.go({path: this.$router.currentRoute.path, force: true})
          }
        })
      },
      createSnippet (title, content) {
        var condition = {
          snippet: {
            'title': title,
            'content': content
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/snippets',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`スニペット情報を登録しました`)
          this.snippetTitle = ''
          this.snippetContent = ''
          this.getSnippets()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スニペット情報の登録に失敗しました')
          }
        })
      },
      useSnippet (content) {
        this.$emit("use-snippet-click", content)
        this.closeModal()
      },
      closeModal () {
        this.snippetBgFlag = false
        this.snippetFlag = false
        this.snippetTitle = ''
        this.snippetContent = ''
      },
    },
  }
</script>
<style scoped>

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.formLayout{
  flex-basis: auto;
}

.pt20{
  padding-top: 20px;
}

.btnModal{
  text-align: center;
  margin-top: 20px;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.imgContainer{
  width: 200px;
}

@media screen and (max-width:600px) {
  .btnArea{
    padding-left: 5px;
    padding-right: 5px;
  }

  .btnArea button{
    width: 35px;
    height: 40px;
    margin: 0;
    padding: 0;
  }

  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }

  .min-width-0 {
    min-width: 0;
  }
}
</style>
