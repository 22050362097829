<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">設置場所(配送先)詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              適用サイト
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{siteKind}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="siteKind === 'サブスクキッチン'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('shopName')},
                {'md-valid': !errors.has('shopName') && touched.shopName}]">
                <md-input
                  v-model="shopName"
                  data-vv-name="shopName"
                  type="text"
                  v-validate= "modelValidations.shopName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('shopName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('shopName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('shopName') && touched.shopName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ユーザー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static"><a href="#"><u @click.prevent="handleUserEdit(userId)">{{userName}}</u></a></p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="siteKind === 'サブスクキッチン'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              担当者名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{managerName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              設置場所(日中連絡がつく)電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('tel')},
                {'md-valid': !errors.has('tel') && touched.tel}]">
                <md-input
                  v-model="tel"
                  data-vv-name="tel"
                  type="number"
                  v-validate= "modelValidations.tel"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel')">【必須】それぞれ11桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel') && touched.tel" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              固定電話(店舗電話番号)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('fixTel')},
                {'md-valid': !errors.has('fixTel') && touched.fixTel}]">
                <md-input
                  v-model="fixTel"
                  data-vv-name="fixTel"
                  type="number"
                  v-validate= "modelValidations.fixTel"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('fixTel')">【必須】それぞれ11桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('fixTel')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('fixTel') && touched.fixTel" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              郵便番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('zip')},
                {'md-valid': !errors.has('zip') && touched.zip}]">
                <md-input
                  v-model="zip"
                  data-vv-name="zip"
                  type="number"
                  v-validate= "modelValidations.zip"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('zip')">【必須】それぞれ7桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zip')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zip') && touched.zip" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              都道府県
            </label>
            <div class="md-layout-item md-size-15">
              <md-field :class="[
                {'md-error': errors.has('prefecture')},
                {'md-valid': !errors.has('prefecture') && touched.prefecture}]">
                <md-select
                  v-model="prefecture"
                  data-vv-name="prefecture"
                  v-validate= "modelValidations.prefecture"
                  required>
                  <md-option value="北海道">北海道</md-option>
                  <md-option value="青森県">青森県</md-option>
                  <md-option value="岩手県">岩手県</md-option>
                  <md-option value="宮城県">宮城県</md-option>
                  <md-option value="秋田県">秋田県</md-option>
                  <md-option value="山形県">山形県</md-option>
                  <md-option value="福島県">福島県</md-option>
                  <md-option value="茨城県">茨城県</md-option>
                  <md-option value="栃木県">栃木県</md-option>
                  <md-option value="群馬県">群馬県</md-option>
                  <md-option value="埼玉県">埼玉県</md-option>
                  <md-option value="千葉県">千葉県</md-option>
                  <md-option value="東京都">東京都</md-option>
                  <md-option value="神奈川県">神奈川県</md-option>
                  <md-option value="新潟県">新潟県</md-option>
                  <md-option value="富山県">富山県</md-option>
                  <md-option value="石川県">石川県</md-option>
                  <md-option value="福井県">福井県</md-option>
                  <md-option value="山梨県">山梨県</md-option>
                  <md-option value="長野県">長野県</md-option>
                  <md-option value="岐阜県">岐阜県</md-option>
                  <md-option value="静岡県">静岡県</md-option>
                  <md-option value="愛知県">愛知県</md-option>
                  <md-option value="三重県">三重県</md-option>
                  <md-option value="滋賀県">滋賀県</md-option>
                  <md-option value="京都府">京都府</md-option>
                  <md-option value="大阪府">大阪府</md-option>
                  <md-option value="兵庫県">兵庫県</md-option>
                  <md-option value="奈良県">奈良県</md-option>
                  <md-option value="和歌山県">和歌山県</md-option>
                  <md-option value="鳥取県">鳥取県</md-option>
                  <md-option value="島根県">島根県</md-option>
                  <md-option value="岡山県">岡山県</md-option>
                  <md-option value="広島県">広島県</md-option>
                  <md-option value="山口県">山口県</md-option>
                  <md-option value="徳島県">徳島県</md-option>
                  <md-option value="香川県">香川県</md-option>
                  <md-option value="愛媛県">愛媛県</md-option>
                  <md-option value="高知県">高知県</md-option>
                  <md-option value="福岡県">福岡県</md-option>
                  <md-option value="佐賀県">佐賀県</md-option>
                  <md-option value="長崎県">長崎県</md-option>
                  <md-option value="熊本県">熊本県</md-option>
                  <md-option value="大分県">大分県</md-option>
                  <md-option value="宮崎県">宮崎県</md-option>
                  <md-option value="鹿児島県">鹿児島県</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('prefecture')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('prefecture')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('prefecture') && touched.prefecture" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-xsmall-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              住所
            </label>
            <div class="md-layout-item md-size-60 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('address')},
                {'md-valid': !errors.has('address') && touched.address}]">
                <md-input
                  v-model="address"
                  data-vv-name="address"
                  type="text"
                  v-validate= "modelValidations.address"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('address')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('address')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('address') && touched.address">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>  
  </div>
</template>
<script>
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        userId: '',
        userName: '',
        managerName: '',
        shopName: '',
        fixTel: '',
        zip: '',
        tel: '',
        prefecture: '',
        address: '',
        siteKind: '',
        touched: {
          shopName: false,
          fixTel: false,
          zip: false,
          tel: false,
          prefecture: false,
          address: false
        },
        modelValidations: {
          shopName: {
            max: 255,
            required: true
          },
          fixTel:{
            max: 11
          },
          zip:{
            max: 7,
            required: true,
          },
          tel:{
            max: 11,
            required: true,
          },
          prefecture:{
            required: true,
          },
          address:{
            max: 50,
            required: true,
          },
        }
      }
    },
    computed: {
    },
    methods: {
      handleUserEdit (id) {
        router.push({ path: '/admin/user/edit/' + id })
      },
      returnBack () {
        router.push({ path: '/admin/shop_list/' })
      },
      getShops () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.userId = response.data.user_id
          this.userName = response.data.user_name
          this.managerName = response.data.manager_name
          this.shopName = response.data.name
          this.fixTel = response.data.fix_tel
          this.zip = response.data.zip
          this.tel = response.data.tel
          this.prefecture = response.data.pref
          this.address = response.data.address
          switch (response.data.site_kind) {
            case 'subsc':
              this.siteKind = 'サブスクキッチン'
              break;
            case 'sales':
              this.siteKind = 'スタートキッチン'
              break;
            default:
              this.siteKind = '識別不明'
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('設置場所(配送先)情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          shop: {
            name: this.shopName,
            fix_tel: this.fixTel,
            zip: this.zip,
            tel: this.tel,
            pref: this.prefecture,
            address: this.address
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/shops/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`設置場所(配送先)情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('設置場所(配送先)情報の更新に失敗しました')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
    },
    mounted () {
      this.getShops()
    },
    watch: {
      shopName(){
        this.touched.shopName = true
      },
      fixTel(){
        this.touched.fixTel = true
      },
      zip(){
        this.touched.zip = true
      },
      tel(){
        this.touched.tel = true
      },
      prefecture(){
        this.touched.prefecture = true
      },
      address(){
        this.touched.address = true
      },
      siteKind(){
        if(this.siteKind === 'スタートキッチン') {this.modelValidations.shopName.required = false}
      },
    }
  }
</script>
<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

.pt5{
  padding-top: 5px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
