<template>
  <div class="md-layout">
  <div class="md-layout-item md-size-100">
    <div class="md-card md-theme-default">
      <div data-v-596b2add="" class="md-card-header md-card-header-icon md-card-header-green">
        <div data-v-596b2add="" class="card-icon"><i data-v-596b2add="" class="md-icon md-icon-font md-theme-default">assignment</i></div>

        <h4 data-v-596b2add="" class="title">配送料設定</h4>
        <md-card-content class="md-layout formLayout">
            <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
              <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
                適応先
              </label>
              <div class="md-layout-item md-xsmall-size-100">
                <md-field class="kind">
                  <md-select v-model="kind" name="kind">
                    <md-option value="subsc">サブスクサイト</md-option>
                    <md-option value="sales">通販サイト</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </md-card-content>
      </div>

      <div class="md-card-content">
        <div class="md-content md-table table-striped md-theme-default" value="[object Object],[object Object],[object Object],[object Object],[object Object]">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
              <thead>
                <tr>
                  <th class="md-table-head md-table-cell-selection">
                    <div class="md-table-head-container">
                      <div class="md-table-head-label">
                        <div class="md-table-cell-container"> </div>
                      </div>
                    </div>
                  </th>
                  <th class="md-table-head" v-for="head in headers">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label"> {{ head }} </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="md-table-row md-has-selection">
                  <td class="md-table-cell md-table-cell-selection">
                    <!-- <div class="md-table-cell-container">#</div> -->
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">立米数</div>
                  </td>
                  <td class="md-table-cell"  v-for="n in 7" :key="n">
                    <div class="flex-container"><input class="md-table-cell-container flex-item sel-item" v-model="tableData[0][`start${n}`]" maxlength="16" /><span> ㎤ </span><span> 〜 </span><input class="md-table-cell-container flex-item sel-item" v-model="tableData[0][`end${n}`]" maxlength="16" /><span> ㎤ </span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="md-layout-item md-size-100">
    <div class="md-card md-theme-default">
      <div class="md-card-content">
        <div class="md-content md-table table-striped md-theme-default" value="[object Object],[object Object],[object Object],[object Object],[object Object]">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
              <thead>
                <tr>
                  <th class="md-table-head md-table-cell-selection">
                    <div class="md-table-head-container">
                      <div class="md-table-head-label">
                        <div class="md-table-cell-container"> </div>
                      </div>
                    </div>
                  </th>
                  <th class="md-table-head" v-for="head in prefPriceHeaders">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label"> {{ head }} </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-for="item in tableData" :key="item.id">
                <tr class="md-table-row md-has-selection">
                  <td class="md-table-cell md-table-cell-selection">
                    <div class="md-table-cell-container"> </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">{{ item.pref }}</div>
                  </td>
                  <td class="md-table-cell" v-for="n in 7" :key="n">
                    <div class="flex-container"><input class="md-table-cell-container flex-item sel-price-item" v-model="item[`price${n}`]" maxlength="8" /><span>円</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg" @click.native="updateDeliveries(tableData)" v-show="superFlag">更新</md-button>
        </md-card-actions>
    </div>
  </div>

</div>
</template>

<script>
  import router from '../packs/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    data () {
      return {
        resultFlag: false,
        tableData:[],
        buttonWord: "更新" ,
        kind: "subsc",
        headers: ["#", "条件1", "条件2", "条件3", "条件4", "条件5", "条件6", "条件7"],
        prefPriceHeaders: ["地域", "条件1", "条件2", "条件3", "条件4", "条件5", "条件6", "条件7"]
      }
    },
    methods: {
      getDeliveries () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/deliveries/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: {
            kind: this.kind
          }
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('配送情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            pref: res[i].attributes.pref,
            start1: res[i].attributes.start1,
            end1: res[i].attributes.end1,
            price1: res[i].attributes.price1,
            start2: res[i].attributes.start2,
            end2: res[i].attributes.end2,
            price2: res[i].attributes.price2,
            start3: res[i].attributes.start3,
            end3: res[i].attributes.end3,
            price3: res[i].attributes.price3,
            start4: res[i].attributes.start4,
            end4: res[i].attributes.end4,
            price4: res[i].attributes.price4,
            start5: res[i].attributes.start5,
            end5: res[i].attributes.end5,
            price5: res[i].attributes.price5,
            start6: res[i].attributes.start6,
            end6: res[i].attributes.end6,
            price6: res[i].attributes.price6,
            start7: res[i].attributes.start7,
            end7: res[i].attributes.end7,
            price7: res[i].attributes.price7,
            kind: res[i].attributes.kind,
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateDeliveries (updateTableData) {
        this.copySquareMeters()
        var condition = {
          'deliveries': updateTableData
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/deliveries/create_list',
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`配送情報を更新しました`)
          this.getDeliveries()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('配送情報を正しく更新できませんでした')
          }
        })
      },
      copySquareMeters() {
        this.tableData.forEach(e => {
          if (!(e.id === this.tableData[0].id)) {
            e.start1 = this.tableData[0].start1
            e.end1 = this.tableData[0].end1
            e.start2 = this.tableData[0].start2
            e.end2 = this.tableData[0].end2
            e.start3 = this.tableData[0].start3
            e.end3 = this.tableData[0].end3
            e.start4 = this.tableData[0].start4
            e.end4 = this.tableData[0].end4
            e.start5 = this.tableData[0].start5
            e.end5 = this.tableData[0].end5
            e.start6 = this.tableData[0].start6
            e.end6 = this.tableData[0].end6
            e.start7 = this.tableData[0].start7
            e.end7 = this.tableData[0].end7
          }
        })
      }
    },
    mounted () {
      // role check & search items
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      } else {
        this.getDeliveries()
      }
    },
    watch: {
      kind() {
        this.getDeliveries()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-table-row:first-child .dropup .dropdown-toggle + .dropdown-menu{
  bottom: -100%;
}

.flex-container {
  display: flex;
}

.flex-item {
  flex: 1; 
}

.sel-item {
  width: 3em;
}

.sel-price-item {
  width: 4em;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

}
</style>
