<template>
  <!-- <div> -->
    <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              キャッシュバック
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('cashback')},
                {'md-valid': !errors.has('cashback') && touched.cashback}]">
                <md-input
                  v-model="cashback"
                  data-vv-name="cashback"
                  type="number"
                  v-validate= "modelValidations.cashback"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('cashback')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('cashback')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('cashback') && touched.cashback" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-for="item in presentData" :data-id="item.id" :key="item.id">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              プレゼント
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <p class="form-control-static">{{item.presentName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              発送方法
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('shippingMethod')},
                {'md-valid': !errors.has('shippingMethod') && touched.shippingMethod}]">
                <md-select
                  v-model="shippingMethod"
                  data-vv-name="shippingMethod"
                  v-validate= "modelValidations.shippingMethod"
                  required>
                  <md-option value="carry">搬入</md-option>
                  <md-option value="carry_tran">搬入注文</md-option>
                  <md-option value="mail">郵送</md-option>
                  <md-option value="under_eaves">軒下渡し</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('shippingMethod')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('shippingMethod')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('shippingMethod') && touched.shippingMethod" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              配送日
            </label>
            <div class="md-layout-item md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="deliveryDate"
                :language="ja"
                data-vv-name="deliveryDate"
                v-validate= "modelValidations.deliveryDate"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="deliveryDate = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('deliveryDate')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('deliveryDate')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('deliveryDate') && touched.deliveryDate">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ユーザーからの質問
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="question" md-counter="255" maxlength="255"></md-textarea>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(姓)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastName')},
                {'md-valid': !errors.has('lastName') && touched.lastName}]">
                <md-input
                  v-model="lastName"
                  data-vv-name="lastName"
                  type="text"
                  v-validate= "modelValidations.lastName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastName')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(名)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstName')},
                {'md-valid': !errors.has('firstName') && touched.firstName}]">
                <md-input
                  v-model="firstName"
                  data-vv-name="firstName"
                  type="text"
                  v-validate= "modelValidations.firstName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstName')">【必須】16文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(セイ)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastNameKana')},
                {'md-valid': !errors.has('lastNameKana') && touched.lastNameKana}]">
                <md-input
                  v-model="lastNameKana"
                  data-vv-name="lastNameKana"
                  type="text"
                  v-validate= "modelValidations.lastNameKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastNameKana')">【必須】16文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastNameKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastNameKana') && touched.lastNameKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者氏名(メイ)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstNameKana')},
                {'md-valid': !errors.has('firstNameKana') && touched.firstNameKana}]">
                <md-input
                  v-model="firstNameKana"
                  data-vv-name="firstNameKana"
                  type="text"
                  v-validate= "modelValidations.firstNameKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstNameKana')">【必須】16文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstNameKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstNameKana') && touched.firstNameKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者性別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('representativeSex')},
                {'md-valid': !errors.has('representativeSex') && touched.representativeSex}]">
                <md-select
                  v-model="representativeSex"
                  data-vv-name="representativeSex"
                  v-validate= "modelValidations.representativeSex"
                  required>
                  <md-option value="man">男性</md-option>
                  <md-option value="woman">女性</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('representativeSex')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('representativeSex')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('representativeSex') && touched.representativeSex" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('tel')},
                {'md-valid': !errors.has('tel') && touched.tel}]">
                <md-input
                  v-model="tel"
                  data-vv-name="tel"
                  type="number"
                  v-validate= "modelValidations.tel"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel')">【必須】それぞれ11桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel') && touched.tel" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者生年月日
            </label>
            <div class="md-layout-item md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="representativeBirth"
                :language="ja"
                data-vv-name="representativeBirth"
                v-validate= "modelValidations.representativeBirth"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="representativeBirth = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('representativeBirth')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('representativeBirth')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('representativeBirth') && touched.representativeBirth">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者郵便番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('representativeZip')},
                {'md-valid': !errors.has('representativeZip') && touched.representativeZip}]">
                <md-input
                  v-model="representativeZip"
                  data-vv-name="representativeZip"
                  type="number"
                  v-validate= "modelValidations.representativeZip"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('representativeZip')">【必須】それぞれ7桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('representativeZip')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('representativeZip') && touched.representativeZip" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者住所
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('representativeAddress')},
                {'md-valid': !errors.has('representativeAddress') && touched.representativeAddress}]">
                <md-input
                  v-model="representativeAddress"
                  data-vv-name="representativeAddress"
                  type="text"
                  v-validate= "modelValidations.representativeAddress"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('representativeAddress')">【必須】16文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('representativeAddress')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('representativeAddress') && touched.representativeAddress">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者家族構成
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('representativeFamilyStructure')},
                {'md-valid': !errors.has('representativeFamilyStructure') && touched.representativeFamilyStructure}]">
                <md-select
                  v-model="representativeFamilyStructure"
                  data-vv-name="representativeFamilyStructure"
                  v-validate= "modelValidations.representativeFamilyStructure"
                  required>
                  <md-option value="no_spouse">配偶者なし</md-option>
                  <md-option value="spouse">配偶者あり</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('representativeFamilyStructure')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('representativeFamilyStructure')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('representativeFamilyStructure') && touched.representativeFamilyStructure" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              代表者住居所有権
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('representativeHomeOwnership')},
                {'md-valid': !errors.has('representativeHomeOwnership') && touched.representativeHomeOwnership}]">
                <md-select
                  v-model="representativeHomeOwnership"
                  data-vv-name="representativeHomeOwnership"
                  v-validate= "modelValidations.representativeHomeOwnership"
                  required>
                  <md-option value="owned">持ち家</md-option>
                  <md-option value="rent">借家</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('representativeHomeOwnership')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('representativeHomeOwnership')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('representativeHomeOwnership') && touched.representativeHomeOwnership" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              売上高(前年)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesPreviousYear')},
                {'md-valid': !errors.has('salesPreviousYear') && touched.salesPreviousYear}]">
                <md-input
                  v-model="salesPreviousYear"
                  data-vv-name="salesPreviousYear"
                  type="number"
                  v-validate= "modelValidations.salesPreviousYear"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesPreviousYear')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesPreviousYear')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesPreviousYear') && touched.salesPreviousYear" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="mt8 md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              売上高(前々年)
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('salesTwoYearsAgo')},
                {'md-valid': !errors.has('salesTwoYearsAgo') && touched.salesTwoYearsAgo}]">
                <md-input
                  v-model="salesTwoYearsAgo"
                  data-vv-name="salesTwoYearsAgo"
                  type="number"
                  v-validate= "modelValidations.salesTwoYearsAgo"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('salesTwoYearsAgo')">【必須】8桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('salesTwoYearsAgo')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('salesTwoYearsAgo') && touched.salesTwoYearsAgo" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
                <span class="md-suffix">円</span>
              </md-field>
            </div>
          </div>

          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <md-button class="md-success md-lg mL40" @click="validate">更新</md-button>
          </md-card-actions>

        </md-card-content>
        
      </div>
    </transition>
    </md-card>
  <!-- </div> -->
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import EventBus from '../../mixins/event-bus.js';
  export default {
    components: {
    },
    data() {
      return {
        id: '',
        cashback: '',
        shippingMethod: '',
        deliveryDate: '',
        question: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        representativeSex: '',
        tel: '',
        representativeBirth: '',
        representativeZip: '',
        representativeAddress: '',
        representativeFamilyStructure: '',
        representativeHomeOwnership: '',
        salesPreviousYear: '',
        salesTwoYearsAgo: '',
        presentData: [],
        touched: {
          cashback: false,
          shippingMethod: false,
          deliveryDate: false,
          question: false,
          lastName: false,
          firstName: false,
          lastNameKana: false,
          firstNameKana: false,
          representativeSex: false,
          tel: false,
          representativeBirth: false,
          representativeZip: false,
          representativeAddress: false,
          representativeFamilyStructure: false,
          representativeHomeOwnership: false,
          salesPreviousYear: false,
          salesTwoYearsAgo: false,
        },
        modelValidations: {
          cashback:{
            max: 8,
            numeric: true
          },
          question:{
            max: 120
          },
          lastName:{
            max: 16,
            required: true,
          },
          firstName:{
            max: 16,
            required: true,
          },
          lastNameKana:{
            max: 16,
            required: true,
          },
          firstNameKana:{
            max: 16,
            required: true,
          },
          tel:{
            max: 11,
            required: true
          },
          representativeZip:{
            max: 7
          },
          representativeAddress:{
            max: 255
          },
          salesPreviousYear:{
            max: 10,
            numeric: true
          },
          salesTwoYearsAgo:{
            max: 10,
            numeric: true
          }
        },
        detailBgFlag: false,
        detailFlag: false
      }
    },
    computed: {
    },
    methods: {
      getEcOrderDetails () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/ec_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id                               = response.data.id
          this.cashback                         = Math.floor(response.data.cashback)
          this.shippingMethod                   = response.data.shipping_method
          this.deliveryDate                     = response.data.delivery_date
          this.question                         = response.data.question
          this.lastName                         = response.data.last_name
          this.firstName                        = response.data.first_name
          this.lastNameKana                     = response.data.last_name_kana
          this.firstNameKana                    = response.data.first_name_kana
          this.representativeSex                = response.data.representative_sex
          this.tel                              = response.data.tel
          this.representativeBirth              = response.data.representative_birth
          this.representativeZip                = response.data.representative_zip
          this.representativeAddress            = response.data.representative_address
          this.representativeFamilyStructure    = response.data.representative_family_structure
          this.representativeHomeOwnership      = response.data.representative_home_ownership
          this.salesPreviousYear                = Math.floor(response.data.sales_previous_year)
          this.salesTwoYearsAgo                 = Math.floor(response.data.sales_two_years_ago)
          this.detailBgFlag                     = true
          this.detailFlag                       = true
          var res = response.data.presents
          this.presentData = []
          for (var i in res) {
            var item = {
              presentName: res[i].present_name
            }
            this.presentData.push(item)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報の取得に失敗しました')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      putItem () {
        var condition = {
          ec_order: {
            id:                                  this.id,
            cashback:                            this.cashback,
            shipping_method:                     this.shippingMethod,
            delivery_date:                       this.deliveryDate,
            question:                            this.question,
            last_name:                           this.lastName,
            first_name:                          this.firstName,
            last_name_kana:                      this.lastNameKana,
            first_name_kana:                     this.firstNameKana,
            representative_sex:                  this.representativeSex,
            tel:                                 this.tel,
            representative_birth:                this.representativeBirth,
            representative_zip:                  this.representativeZip,
            representative_address:              this.representativeAddress,
            representative_family_structure:     this.representativeFamilyStructure,
            representative_home_ownership:       this.representativeHomeOwnership,
            sales_previous_year:                 this.salesPreviousYear,
            sales_two_years_ago:                 this.salesTwoYearsAgo,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/ec_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`注文情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報の更新に失敗しました')
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      closeModal () {
        this.detailBgFlag = false
        this.detailFlag = false
      }
    },
    watch: {
      cashback(){
        this.touched.cashback = true
      },
      shippingMethod(){
        this.touched.shippingMethod = true
      },
      deliveryDate(){
        this.touched.deliveryDate = true
      },
      question(){
        this.touched.question = true
      },
      lastName(){
        this.touched.lastName = true
      },
      firstName(){
        this.touched.firstName = true
      },
      lastNameKana(){
        this.touched.lastNameKana = true
      },
      firstNameKana(){
        this.touched.firstNameKana = true
      },
      representativeSex(){
        this.touched.representativeSex = true
      },
      tel(){
        this.touched.tel = true
      },
      representativeBirth(){
        this.touched.representativeBirth = true
      },
      representativeZip(){
        this.touched.representativeZip = true
      },
      representativeAddress(){
        this.touched.representativeAddress = true
      },
      representativeFamilyStructure(){
        this.touched.representativeFamilyStructure = true
      },
      representativeHomeOwnership(){
        this.touched.representativeHomeOwnership = true
      },
      salesPreviousYear(){
        this.touched.salesPreviousYear = true
      },
      salesTwoYearsAgo(){
        this.touched.salesTwoYearsAgo = true
      },
    }
  }
</script>
<style scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.formLayout{
  flex-basis: auto;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 12px !important;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.md-form-label {
  padding: 10px 5px 0 0 !important;
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.md-select-value {
  text-align: center;
}

.center-btn {
  justify-content: center;
}

.mL80 {
  margin-left: 110px !important;
}

.mt60{
  margin-top: 60px;
}

.betweenTime{
  line-height: 59px;
}

@media screen and (max-width:600px) {
  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>