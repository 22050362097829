<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">メルマガ配信情報</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              配信日時
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{deliveryDay | timeFormat2}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{status}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{shopName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{userRank}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              差出人アドレス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{email_from}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              差出人名
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{email_sender}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              件名
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{subject}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              種別
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{mime_type}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              内容
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{content}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              予定配信数
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{deliveryEstimate | numberWithDelimiter}}件</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              完了配信数
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{deliveryComplete | numberWithDelimiter}}件</p>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  export default {
    components: {
    },
    computed: {
 
    },
    props: {
    },
    data () {
      return {
        deliveryDay: '',
        status: '',
        email_from: '',
        email_sender: '',
        subject: '',
        mime_type: '',
        shopName: '未設定',
        userRank: '未設定',
        content: '',
        deliveryEstimate: 0,
        deliveryComplete: 0
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/mail_magazine_list/' })
      },
      getNotice () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/mail_magazines/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deliveryDay = response.data.delivery_at
          this.status = this.statusChecker(response.data.status)
          this.shopChecker(response.data.shop_id)
          this.userRankChecker(response.data.user_rank_id)
          this.email_from = response.data.email_from
          this.email_sender = response.data.email_sender
          this.subject = response.data.subject
          this.mime_type = response.data.mime_type.toUpperCase();
          this.content = response.data.body
          this.deliveryEstimate = response.data.total_count
          this.deliveryComplete = response.data.send_count
        })
        .catch(error => {
          this.dangerNotify('メルマガ配信情報の取得に失敗しました')
        })
      },
      statusChecker (status) {
        var pushStatus
        switch (status) {
          case 'not_sent':
            pushStatus = '配信前'
            break;
          case 'sending':
            pushStatus = '配信中'
            break;
          case 'error':
            pushStatus = 'エラー'
            break;
          case 'sent':
            pushStatus = '配信済'
            break;
          case 'pending':
            pushStatus = '保留'
            break;
          default:
            pushStatus = '識別不明'
        }
        return pushStatus
      },
      shopChecker (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          for (var i = 0;i < response.data.length ; i++) {
            if (id == response.data[i].id) {
              this.shopName = response.data[i].name
              break
            }
          }
        })
        .catch(error => {
          this.dangerNotify('店舗リストを取得できませんでした')
        })
      },
      userRankChecker (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/user_ranks/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          for (var i = 0;i < response.data.length ; i++) {
            if (id === response.data[i].id) {
              this.userRank = response.data[i].name
              break
            }
          }
        })
        .catch(error => {
          this.dangerNotify('会員ランクを取得できませんでした')
        })
      }
    },
    mounted () {
      this.getNotice()
    },
    watch: {
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right /deep/ .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  min-width: 150px;
}

.table-shopping /deep/ .md-table-head:nth-child(5),
.table-shopping /deep/ .md-table-head:nth-child(7),
.table-shopping /deep/ .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
