import { render, staticRenderFns } from "./RateDetail.vue?vue&type=template&id=1e35c1c4&scoped=true&"
import script from "./RateDetail.vue?vue&type=script&lang=js&"
export * from "./RateDetail.vue?vue&type=script&lang=js&"
import style0 from "./RateDetail.vue?vue&type=style&index=0&id=1e35c1c4&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e35c1c4",
  null
  
)

export default component.exports