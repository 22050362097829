<template>
  <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green chat-header">
            <h4 class="title">取引チャット</h4>
            <hr>
            <div class="chat-header-item">
              <h4 class="title">{{ this.$route.query.company_name }} {{ this.$route.query.shop_name }}</h4>
              <md-button class="md-lg" :disabled="true">{{ orderStatus }}</md-button>
            </div>
            <hr>
          </md-card-header>
          <chat :messages="orderMessages" :deleteMessage="deleteMessage" class="md-layout-item"></chat>
          <div class="chat-bottom">
            <div class="chat-message-area">
              <md-field class="chat-message-field">
                <md-textarea v-model="msgBox" placeholder="メッセージ" class="chat-input-message"></md-textarea>
              </md-field>
            </div>
            <div class="chat-bar">
              <md-button class="md-success" @click.prevent="getSnippet">スニペット</md-button>
              <div class="chat-bar-item">
                <div class="clip">
                  <md-icon class="fa fa-paperclip pointer" @click.native="chooseFile">
                    <input type="file" @change="onFileChange" id="chatFile" data-id="chatFile" accept=".pdf, .jpg, .jpeg, .png" style="display: none;">
                  </md-icon>
                </div>
                <slide-y-up-transition v-for="(e, index) in errorMessage" :key="index">
                  <span class="errorText" v-if="e.file">{{ e.file }}</span>
                  <span class="errorText" v-if="e.file_size">{{ e.file_size }}</span>
                </slide-y-up-transition>
                <span v-html="fileName"></span>
                <span class="errorText" v-html="fileErrorMessage"></span>
                <md-button class="md-danger" v-if="roomChannel" :disabled="(!this.msgBox)" @click="speak()">送信</md-button>
                <snippet ref="snippet" @use-snippet-click='useSnippet'></snippet>
              </div>
            </div>
          </div>
        </md-card>
        <div class="linkCont">
          <div class="bottomLink" @click="returnBack">戻る</div>
      </div>
      </div>
    </div>
</template>

<script>
import Chat from '../components/additional/Chat.vue'
import Snippet from '../components/additional/Snippet.vue'
import router from '../packs/router.js'
export default {
components: {
  Chat,
  Snippet,
},
data () {
  return {
    msgBox: '',
    chatFile: null,
    fileName: "",
    orderMessages: [],
    roomChannel: null,
    orderStatus: this.$route.query.order_status,
    currentUser: {},
    adminFlag: false,
    readMessageType: "AdminUser",
    read: "not_active",
    showChild: true,
    errorMessage: '',
    fileErrorMessage: '',
  };
},
created() {
  this.changeStatusJp()
  this.roomChannel = this.$cable.subscriptions.create( {channel: "RoomChannel", room: this.$route.query.room_id}, {
    connected: () => {
      this.updateMessage();
    },
    received: (data) => {
      if (data.current_user) {
        this.currentUser = data.current_user
      }
      if (data.orderMessages) {
        this.orderMessages = []
        data.orderMessages.forEach(e => this.orderMessages.push(e))
      }
      if (data.errorMessage) {
        this.errorMessage = data.errorMessage
      }
      this.msgBox = ""
      this.fileName = ""
      this.chatFile = null
    },
  })
},
methods: {
  returnBack () {
    router.push({ path: '/admin/order/edit/' + this.$route.query.order_id })
  },
  speak() {
    this.roomChannel.perform('speak', { 
      message: this.msgBox.replace(/\n/g,'<br>'), 
      current_user_id: this.currentUser.id,
      room_id: this.$route.query.room_id, 
      admin_flag: true, 
      file: this.chatFile,
    });
  },
  changeStatusJp () {
    switch (this.orderStatus) {
      case 'ng':
        this.orderStatus = 'NG'
        break;
      case 'estimate_mid':
        this.orderStatus = '見積中'
        break;
      case 'estimate_after':
        this.orderStatus = '見積後'
        break;
      case 'estimate_finish':
        this.orderStatus = '見積終了'
        break;
      case 'application':
        this.orderStatus = '正式申込'
        break;
      case 'contract':
        this.orderStatus = '契約中'
        break;
      case 'contract_finish':
        this.orderStatus = '契約終了'
        break;
      case 'delivery_preparation':
        this.orderStatus = '配送準備中'
        break;
      case 'ng':
        this.orderStatus = '識別不明'
    }
  },
  updateMessage () {
    this.roomChannel.perform('update_messages', {
      message_type: 'User',
      room_id: this.$route.query.room_id
    });
  },
  deleteMessage (messageId) {
    alert('本当によろしいですか？')
    this.roomChannel.perform('delete_message', { 
      delete_message_id: messageId,
      room_id: this.$route.query.room_id
    });
  },
  getSnippet () {
    this.$refs.snippet.getSnippets()
  },
  useSnippet (content) {
    this.msgBox = content
  },
  chooseFile () {
    document.getElementById("chatFile").click();
  },
  onFileChange(e) {
    this.fileErrorMessage = ''
    this.fileName = '&nbsp;' + e.target.files[0].name
    let files = e.target.files || e.dataTransfer.files;
    let ids = e.currentTarget.getAttribute('data-id');
    if (!files.length)
    return;
    if (files[0].size > 5000000) {
      this.fileErrorMessage = "ファイルの上限サイズ5MBを超えています"
      this.chatFile = null
      this.fileName = ""
    } else {
      this.createImage(files[0],ids);
    }
  },
  createImage(file,fileId) {
      let reader = new FileReader();
      let $this = this;

    reader.onload = (e) => {
      $this[fileId] = e.target.result;
    };
    reader.readAsDataURL(file);
  },
},
mounted() {
  this.updateMessage();
}
}
</script>
<style scoped>
.chat-header {
  position: sticky;
  top: 0;
  background-color: white;
  margin: 0;
  z-index: 101;
  padding: 1.5em;
}
.chat-header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-bottom {
  position: sticky;
  bottom: 0;
  margin: 0;
  background-color: white;
  z-index: 101;
}
.chat-message-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-message-field {
  width: 95%;
  margin: 1em;
}
.chat-bar {
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
}
.chat-input-message {
  width: 100%;
  padding: 0.5em !important;
}
.chat-bar-item {
  display: flex;
  justify-content: end;
  align-items: center;
}
.clip {
  margin-right: 2em;
}
.pointer {
  cursor: pointer;
  transition: 0.5s;
}
.pointer:hover {
  opacity: 0.5;
}
.errorText {
  color: #f44336;
  white-space: nowrap;
}
</style>