<template>
  <md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='detailBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="detailFlag">
        <div class="btnClose" @click="closeModal"><md-icon class="mb8">close</md-icon></div>

          <div class="sheets">
            <div class="sheet" id="sheet">
              <md-card-header style="text-align: center;">
                <h3>見積もり審査用情報</h3>
              </md-card-header>

              <md-card-content style="display: flex; flex-wrap: wrap;">
                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    ユーザーID
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{userId}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    取引ID
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{id}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    設置場所ID
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{shopId}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    会社名(屋号)
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{companyName}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    本社(事業所)<br>郵便番号
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{companyZip}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    本社(事業所)<br>都道府県
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{companyPrefecture}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    本社(事業所)<br>住所
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{companyAddress}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    営業内容
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{businessCont}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者氏名
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{fullName}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    売上高前年度
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{salesPreviousYear | numberWithDelimiter}} 円</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    売上高前々年度
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{salesTwoYearsAgo | numberWithDelimiter}} 円</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    設置場所
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{shopName}}</p>
                  </div>
                </div>
              </md-card-content>

              <md-card-header v-show="corporateClassification == 'individual'" style="text-align: center;">
                <h4>個人事業主向け</h4>
              </md-card-header>

              <md-card-content v-show="corporateClassification == 'individual'" style="display: flex; flex-wrap: wrap;">
                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者性別
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{representativeSex}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者<br>生年月日
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{representativeBirth}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者住所
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{representativeAddress}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者<br>家族構成
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{representativeFamilyStructure}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    代表者<br>住居所有権
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{representativeHomeOwnership}}</p>
                  </div>
                </div>
              </md-card-content>

              <md-card-header v-show="joinGuarantor == 'join_true'" style="text-align: center;">
                <h4>連帯保証人向け</h4>
              </md-card-header>

              <md-card-content v-show="joinGuarantor == 'join_true'" style="display: flex; flex-wrap: wrap;">
                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    連帯保証人<br>性別
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{joinGuarantorSex}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    連帯保証人<br>生年月日
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{joinGuarantorBirth}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    連帯保証人<br>住所
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{joinGuarantorAddress}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    連帯保証人<br>家族構成
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{joinGuarantorFamilyStructure}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 35%; color: #aaa;">
                    連帯保証人<br>住居所有権
                  </label>
                  <div style="width: 65%; padding: 0 10px;">
                    <p style="margin: 0;">{{joinGuarantorHomeOwnership}}</p>
                  </div>
                </div>

                <div style="display: flex; width: 45%; padding: 5px 15px; align-items: center;">
                </div>

                <div style="display: flex; width: 90%; padding: 5px 20px; align-items: center;">
                  <label style="text-align: right; padding-right: 5px; width: 15%; color: #aaa;">
                    備考・質問
                  </label>
                  <div style="width: 85%; padding: 0 10px;">              
                    <p style="margin: 0;">{{question}}</p>
                  </div>
                </div>
              </md-card-content>
            </div>
          </div>

        <md-card-actions md-alignment="center" class="mx-auto center-btn no-display">
          <md-button class="md-success md-lg mL40 center-btn" type="primary" @click="handlePrint">印刷</md-button>
        </md-card-actions>
      </div>
    </transition>
  </md-card>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      userId: '',
      shopId: '',
      companyName: '',
      companyZip: '',
      companyPrefecture: '',
      companyAddress: '',
      businessCont: '',
      fullName: '',
      salesPreviousYear: '',
      salesTwoYearsAgo: '',
      shopName: '',
      representativeSex: '',
      representativeBirth: '',
      representativeAddress: '',
      representativeFamilyStructure: '',
      representativeHomeOwnership: '',
      joinGuarantorSex: '',
      joinGuarantorBirth: '',
      joinGuarantorAddress: '',
      joinGuarantorFamilyStructure: '',
      joinGuarantorHomeOwnership: '',
      question: '',
      corporateClassification: '',
      joinGuarantor: '',
      detailBgFlag: false,
      detailFlag: false
    }
  },
  methods: {
    getOrderPdfTmp (id) {
      this.axios({
        method  : 'GET',
        url     : this.AdminApiUrl + '/orders/' + id,
        timeout : 10000,
        headers : {'Authorization': this.$Cookies.get('accessToken')}
      }).then(response => {
        this.id                            = response.data.id
        this.userId                        = response.data.user_id
        this.shopId                        = response.data.shop_id
        this.companyName                   = response.data.company_name
        this.companyZip                    = response.data.company_zip
        this.companyPrefecture             = response.data.company_prefecture
        this.companyAddress                = response.data.company_address
        this.businessCont                  = response.data.business_cont
        this.fullName                      = response.data.full_name
        this.salesPreviousYear             = response.data.sales_previous_year
        this.salesTwoYearsAgo              = response.data.sales_two_years_ago
        this.shopName                      = response.data.shop_name
        this.representativeBirth           = response.data.representative_birth
        this.representativeAddress         = response.data.representative_address
        this.joinGuarantorBirth            = response.data.join_guarantor_birth
        this.joinGuarantorAddress          = response.data.join_guarantor_address
        this.question                      = response.data.question
        this.corporateClassification       = response.data.corporate_classification
        this.joinGuarantor                 = response.data.join_guarantor
        console.log(response.data.join_guarantor);
        switch (response.data.representative_sex) {
            case 'man':
              this.representativeSex = '男性'
              break;
            case 'woman':
              this.representativeSex = '女性'
              break;
            default:
              this.representativeSex = ''
        }
        switch (response.data.representative_family_structure) {
          case 'no_spouse':
            this.representativeFamilyStructure = '配偶者なし'
            break;
          case 'spouse':
            this.representativeFamilyStructure = '配偶者あり'
            break;
          default:
            this.representativeFamilyStructure = ''
        }
        switch (response.data.representative_home_ownership) {
          case 'owned':
            this.representativeHomeOwnership = '持ち家'
            break;
          case 'rent':
            this.representativeHomeOwnership = '借家'
            break;
          default:
            this.representativeHomeOwnership = ''
        }
        switch (response.data.join_guarantor_sex) {
          case 'join_man':
            this.joinGuarantorSex = '男性'
            break;
          case 'join_woman':
            this.joinGuarantorSex = '女性'
            break;
          default:
            this.joinGuarantorSex = ''
        }
        switch (response.data.join_guarantor_family_structure) {
          case 'join_no_spouse':
            this.joinGuarantorFamilyStructure = '配偶者なし'
            break;
          case 'join_spouse':
            this.joinGuarantorFamilyStructure = '配偶者あり'
            break;
          default:
            this.joinGuarantorFamilyStructure = ''
        }
        switch (response.data.join_guarantor_home_ownership) {
          case 'join_owned':
            this.joinGuarantorHomeOwnership = '持ち家'
            break;
          case 'join_rent':
            this.joinGuarantorHomeOwnership = '借家'
            break;
          default:
            this.joinGuarantorHomeOwnership = ''
        }
        this.detailBgFlag         = true
        this.detailFlag           = true
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.tokenError()
        } else {
          this.dangerNotify('見積もり審査用情報の取得に失敗しました')
        }
      })
    },
    handlePrint() {
      window.document.body.innerHTML = document.getElementById(
        'sheet'
      ).innerHTML
      window.print()
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    dateDivider (dates) {
      var dateArray = dates.split('T')
      return dateArray
    },
    closeModal () {
      this.detailBgFlag = false
      this.detailFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 850px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.center-btn {
  justify-content: center;
}

.md-card .md-card-header {
  padding: 0 1.25rem;
}

h3 {
  margin-top: 0;
}

@page {
  size: A4;
  margin: 0;
}

@media screen {
  .sheet {
    width: 210mm;
    margin: 5mm;
    padding: 5mm;
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
  }
}

</style>