export default {
  data() {
    return {
      config: {
        key: 'SH3B14B10A7jC5D4C3E3J2A7A8C4C4D2A-9MFIZJNKLDXIREJI==',
        placeholderText: 'ここを編集してください',
        charCounterCount: true,
        toolbarButtons: [
          'fullscreen',
          'insertImage',
          'bold',
          'italic',
          'underline','|',
          'fontFamily',
          'fontSize',
          'color',
          'lineHeight', '|',
          'paragraphFormat',
          'align',
          'formatOL',
          'formatUL',
          'embedly',
          'insertTable', '|',
          'emoticons',
          'fontAwesome',
          'specialCharacters',
          'insertHR',
          'clearFormatting', '|',
          'spellChecker', 'help', 'html', '|', 'undo', 'redo'],
        toolbarSticky: false,
        quickInsertButtons: ['embedly', 'table', 'ul', 'ol', 'hr'],
        imageResizeWithPercent: true,
        imageDefaultWidth: 100,
        language: 'ja',
        theme: 'gray',
        imageUploadURL: '/admin/api/v1/upload_image',
        imageManagerLoadURL: '/admin/api/v1/load_images',
        imageUploadParams: {
          id: 'my_editor'
        },
        imageManagerDeleteURL: '/admin//api/v1/delete_image',
        requestHeaders: {'Authorization': this.$Cookies.get('accessToken')}
      }
    };
  }
}