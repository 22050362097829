<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">スペック情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-40 md-small-size-100">
            <label class="md-layout-item md-size-40 md-form-label md-xsmall-size-100">
              スペック絞り込み種別
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <md-field>
                <md-select v-model="specKind">
                  <md-option value="range_value">範囲値</md-option>
                  <md-option value="value">通常値</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-60 md-small-size-100">
            <label class="md-layout-item md-size-60 md-form-label md-xsmall-size-100">
              絞り込みステータス<br />
              (フロントのスペック絞り込み表示・非表示設定)
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field>
                <md-select v-model="specSearchStatus">
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <submit-button class="md-success md-lg mL40" :onclick="updateInfo" :buttonWord=this.editBtn type="submit"></submit-button>
          </md-card-actions>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">スペック絞り込み登録</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-small-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100" v-if="specKind == 'value'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              通常値
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValue')},
                {'md-valid': !errors.has('specValue') && touched.specValue}]" class="wth37p">
                <md-input
                  v-model="specValue"
                  data-vv-name="specValue"
                  type="text"
                  v-validate= "modelValidations.specValue"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValue')">【必須】255文字以内の全角半角英数記号かなで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValue')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValue') && touched.specValue">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="specKind == 'range_value'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              範囲値開始
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValueStart')},
                {'md-valid': !errors.has('specValueStart') && touched.specValueStart}]">
                <md-input
                  v-model="specValueStart"
                  data-vv-name="specValueStart"
                  type="text"
                  v-validate= "modelValidations.specValueStart"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValueStart')">255文字以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValueStart')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValueStart') && touched.specValueStart">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="specKind == 'range_value'">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              範囲値終了
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('specValueEnd')},
                {'md-valid': !errors.has('specValueEnd') && touched.specValueEnd}]">
                <md-input
                  v-model="specValueEnd"
                  data-vv-name="specValueEnd"
                  type="text"
                  v-validate= "modelValidations.specValueEnd"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('specValueEnd')">255文字以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('specValueEnd')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('specValueEnd') && touched.specValueEnd">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.registerBtn type="submit"></submit-button>
          </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">スペック絞り込み一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
                <div class="tHead">
                  <div class="tHead_th">並び替え</div>
                  <div class="tHead_th">スペック絞り込み種別</div>
                  <div class="tHead_th">スペック絞り込み値</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" :key="item.id" class="tItem">
                  <template v-if="specKind == item.kind">
                    <div class="tItem_td cursorGrab"><md-icon class="dragHandle">reorder</md-icon></div>
                    <div class="tItem_td"><md-label style="word-break:break-all;">{{ SwitchKind(item.kind) }}</md-label></div>
                    <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.specValue }}</md-label></div>
                    <div class="tItem_td"><md-label style="word-break:break-all;">{{ SwitchStatus(item.status) }}</md-label></div>
                    <div class="tItem_td taR td_actionTh">
                      <md-button
                        class="md-just-icon md-warning mg0"
                        @click.native="jumpDetail(item)">
                        <md-icon>dvr</md-icon>
                      </md-button>
                      <md-button
                        class="md-just-icon md-danger mg0"
                        @click.native="handleDelete(item)">
                        <md-icon>close</md-icon>
                      </md-button>
                    </div>
                  </template>
                </div>
              </draggable>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="left" class="mt30 mx-auto">
            <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import router from '../packs/router.js'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import Draggable from 'vuedraggable'
  import swal from 'sweetalert2'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      Draggable,
    },
    computed: {
    },
    data () {
      return {
        resultFlag: false,
        editBtn: "更新",
        tableData: [],
        registerBtn: "登録",
        specKind: '',
        specSearchStatus: '',
        number: null,
        status: '',
        specValue: '',
        specValueStart: '',
        specValueEnd: '',
        touched: {
          number: false,
          status: false,
          specValue: false,
          specValueStart: false,
          specValueEnd: false,
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true,
          },
          status: {
            required: true,
          },
          specValue: {
            required: this.isRequiredSpecValue,
          },
          specValueStart: {
            max: 255,
            numeric: true,
            required: this.isRequiredRangeSpecValue,
          },
          specValueEnd: {
            max: 255,
            numeric: true,
            required: this.isRequiredRangeSpecValue,
          },
        },
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/spec_label_edit/' })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.createItem()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getItems () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/specs/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          // スペック絞り込みステータス
          this.specSearchStatus = response.data.search_status
          // スペック絞り込み種別
          this.specKind = response.data.kind
          this.createList(response.data.spec_values)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック絞り込み情報が取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            number: res[i].position,
            kind: res[i].kind,
            status: res[i].status,
          }
          if (res[i].kind == "value") {
            item.specValue = !res[i].spec_value ? '' : res[i].spec_value
          } else if (res[i].kind == "range_value") {
            var start = res[i].spec_value_start
            var end = res[i].spec_value_end
            item.specValue = !start && !end ? '' : (start + ' 〜 ' + end)
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      SwitchKind(kind) {
        switch (kind) {
          case 'value':
            return '通常値'
          case 'range_value':
            return '範囲値'
          default:
            return '識別不明'
        }
      },
      SwitchStatus(status) {
        switch (status) {
          case 'active':
            return '有効'
          case 'not_active':
            return '無効'
          default:
            return '識別不明'
        }
      },
      updateInfo() {
        return new Promise((resolve) => {
          setTimeout(() => {
            this.updateSearchInfo()
            resolve();
          }, this.ResolveTime);
        });
      },
      updateSearchInfo() {
        var condition = {
          spec: {
            kind: this.specKind,
            search_status: this.specSearchStatus,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl +'/specs/' + this.$route.params.id,
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スペック情報を更新しました`)
          this.resetData()
          this.getItems()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スペック情報を正しく更新できませんでした`)
          }
        })
      },
      createItem() {
        var condition = {
          spec_values: {
            spec_id: this.$route.params.id,
            position: this.number,
            status: this.status,
            kind: this.specKind,
            spec_value: this.specValue,
            spec_value_start: this.specValueStart,
            spec_value_end: this.specValueEnd,
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl +'/spec_values/',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`スペック絞り込み情報情報を更新しました`)
          this.resetData()
          this.getItems()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`スペック絞り込み情報を正しく更新できませんでした`)
          }
        })
      },
      updateList (event) {
        var id = event.item.getAttribute('data-id')
        this.updateStatus(id, event.newIndex + 1)
      },
      updateStatus (id, number) {
        var condition = {
          spec_values: {
            position: number - 1,
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/spec_values/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック絞り込み情報の表示順の更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/spec_search_settings/' + this.$route.params.id + '/edit/' + item.id })
      },
      handleDelete (item) {
        swal({
          title: `このスペック絞り込み情報を削除してもよろしいですか?`,
          text: `削除してしまうともとに戻すことは出来ません！`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'md-button md-success btn-fill',
          cancelButtonClass: 'md-button md-danger btn-fill',
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/spec_values/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          var $this = this
          swal({
            title: '削除完了',
            text: `スペック絞り込み情報を削除しました`,
            type: 'success',
            confirmButtonClass: 'md-button md-success btn-fill',
            buttonsStyling: false
          }).then (function () {
            this.getItems()
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('スペック絞り込み情報を削除出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      resetData () {
        this.number = null
        this.status = ''
        this.specValue = ''
        this.specValueStart = ''
        this.specValueEnd = ''
        this.resultFlag = false
        this.specKind = ''
        this.specSearchStatus = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      },
    },
    mounted () {
      if (this.makerFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getItems()
    },
    watch: {
      number () {
        this.touched.number = true
      },
      status () {
        this.touched.status = true
      },
      specValue () {
        this.touched.specValue = true
      },
      specValueStart () {
        this.touched.specValueStart = true
      },
      specValueEnd () {
        this.touched.specValueEnd = true
      },
    },
    computed: {
      isRequired: function() {
        return this.specKind ==  'value';
      },
    },
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt5{
  padding-top: 5px;
}

.pt20{
  padding-top: 20px;
}
.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.padTb{
  padding-top: 20px;
  padding-bottom: 20px;
}

.betweenTime{
  line-height: 59px;
}

.itemImage{
  margin-bottom: 20px;
}

.is-l-none{
  display: none;
}

.imageSub{
  margin-bottom: 20px;
}

.image-container{
  max-width: none !important;
}

.BtnAdd{
  color: #666666;
  padding: 5px 0;
  line-height: 24px;
}

.BtnAdd span{
  display: inline-block;
}

.BtnAdd i{
  margin-bottom: 3px;
}

.categoryContainer{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  padding: 10px 17px;
  box-sizing: border-box;
}

.checkArea{
  position: relative;
  padding-right: 40px;
  display: inline-block;
}

.smallCategory .checkArea{
  padding: 0;
}

.checkArea .toggleBox {
  position: absolute;
  top: 0;
  right: 0;
}

.checkArea .toggleBox .md-button{
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.progenyCategory{
  padding-left: 20px;
}

.md-checkbox{
  margin: 10px 16px 10px 0;
}

.middleCategory,
.smallCategory{
  position: relative;
}

.middleCategory:before,
.smallCategory:before{
  content: '';
  width: 8px;
  height: 8px;
  border-left: solid 2px #d2d2d2;
  border-bottom: solid 2px #d2d2d2;
  position: absolute;
  top: 13px;
  left: -12px;
}
.wth37p{
  width: 37%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .checkArea{
    width: 100%;
  }

  .categoryCheck{
    width: 100%;
  }
}
.iconWrapper{
  position: relative;
}

.iconTrigger{
  font-size: 12px;
  color: #666666;
  padding: 5px 0;
}

.iconModal{
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 250px;
  padding: 5px;
  font-size: 0;
  z-index: 1050;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
}

</style>