<template>
  <rate-detail :rateData="rateData"></rate-detail>
</template>
<script>
import RateDetail from '../components/additional/RateDetail.vue'

export default {
  components: {
    RateDetail,
  },
  data () {
    return { 
      rateData: {
        title: "スタンプ",
        returnBack: '/admin/stamp_rate_list/',
        apiUrl: '/stamp_rates/'
      }}
  }
}
</script>
