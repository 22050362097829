<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">注文明細検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
            <label class="md-layout-item md-size-10 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              商品コード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="productCode"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              型式
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="productSku"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メーカー名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="makerName"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              正式契約日時
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getDetails(1)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">注文明細情報</h4>
          </md-card-header>
          <md-card-actions md-alignment="left" class="pT30">
            <md-button class="md-info md-lg" @click="getDetails(null, 'no_order')">未発注</md-button>
            <md-button class="md-info md-lg mL20" @click="getDetails(null, 'order')">発注</md-button>
            <md-button class="md-info md-lg mL20" @click="getDetails(null, 'complete_del')">納品済み</md-button>
            <md-button class="md-info md-lg mL20" @click="getDetails(null, null, true)">全て</md-button>
          </md-card-actions>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <!-- checkbox -->
                  <div class="tHead_th"></div>
                  <div class="tHead_th">姓</div>
                  <div class="tHead_th">名</div>
                  <div class="tHead_th">商品コード</div>
                  <div class="tHead_th">型式</div>
                  <div class="tHead_th">発注ステータス</div>
                  <div class="tHead_th">メーカー名</div>
                  <div class="tHead_th">正式契約日時</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :key="item.id" class="tItem">
                  <!-- checkbox -->
                  <md-checkbox v-model="item.check" class="checkedItem" v-on:change="checkOrderItems(item)"></md-checkbox>
                  <!-- 姓 -->
                  <div class="tItem_td">{{ item.lastName }}</div>
                  <!-- 名 -->
                  <div class="tItem_td">{{ item.firstName }}</div>
                  <!-- 商品コード -->
                  <div class="tItem_td">{{ item.productCode }}</div>
                  <!-- 型式 -->
                  <div class="tItem_td">{{ item.productSku }}</div>
                  <!-- 発注ステータス -->
                  <div class="tItem_td">
                    <md-field class="wth100">
                      <md-select v-model="item.status">
                        <md-option value="no_order">未発注</md-option>
                        <md-option value="order">発注</md-option>
                        <md-option value="complete_del">納品済み</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <!-- メーカー名 -->
                  <div class="tItem_td">{{ item.makerName }}</div>
                  <!-- 正式契約日時 -->
                  <div class="tItem_td">{{item.contractDateStart }}</div>
                  <!-- アクション -->
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-success mg2"
                      @click.native="updateStatus(item.id, item.status)">
                      <md-icon>autorenew</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getDetails">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
    <md-card-actions md-alignment="left" class="mt60 mx-auto">
      <md-button class="md-warning md-lg" @click.prevent="purchaseOrders()">発注書一括作成</md-button>
      <md-button class="md-success md-lg mL40" @click.prevent="updateStatuses()">一括更新</md-button>
    </md-card-actions>

    <order-form-tmp
      ref="orderFormTmp"
    ></order-form-tmp>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"
  import OrderFormTmp from "../components/additional/OrderFormTmp.vue"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect,
      OrderFormTmp
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        lastName: '',
        firstName: '',
        productCode: '',
        productSku: '',
        makerName: '',
        status: '',
        contractDateStart: '',
        selectedStart: '',
        selectedEnd: '',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getDetails(page = 1, status = '', is_reset = false) {
        if (status) {
          this.status = status
        }
        if (is_reset) {
          this.status = ''
        }
        var condition = {
          page: page,
          last_name: this.lastName,
          first_name: this.firstName,
          product_code: this.productCode,
          product_sku: this.productSku,
          maker_name: this.makerName,
          status: this.status,
          contract_date_start: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          contract_date_end: this.selectedEnd === '' ? '' :  this.initDate(this.selectedEnd)
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/order_details/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文明細情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            lastName: res[i].attributes.order.last_name,
            firstName: res[i].attributes.order.first_name,
            productCode: res[i].attributes['product-code'],
            productSku: res[i].attributes['product-sku'],
            makerName: res[i].attributes['maker-name'],
            status: res[i].attributes.status,
            contractDateStart: res[i].attributes.order.contract_date_start
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      updateStatus (id, status) {
        var condition = {
          ec_order_detail: {
            'status': status
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/order_details/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },

      // checked
      checkOrderItems (item) {
        if (item.check) {
          item.openFlag = true
        } else if (!item.check) {
          this.allCheck = false
          item.openFlag = false
        }
      },

      // 一括更新
      checkItems() {
        var checkedItems = []
        for (var i in this.tableData) {
          if (this.tableData[i].check) {
            checkedItems.push(this.tableData[i])
          }
        }
        return checkedItems
      },
      updateStatuses() {
        var condition = {
          order_detail: this.checkItems()
        }
        this.axios({
          method: 'POST',
          url     : this.AdminApiUrl + '/order_details/update_statuses',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        })
        .then(response => {
          this.successAndBackPage(`ステータスを更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ステータスの更新に失敗しました')
          }
        })
      },
      // 一括作成
      purchaseOrders() {
        var condition = {
          order_detail: this.checkItems()
        }
        this.$refs.orderFormTmp.getOrderForms(condition)
      },

      getOrderFormTmp(id) {
        this.$refs.orderFormTmp.getOrderFormTmp(id)
      },
      jumpDetail (item) {
        router.push({ path: '/admin/user/edit/' + item.id })
      },
      jumpRoom (id, companyName, shopName, orderStatus) {
        router.push({ path: '/admin/orders/' + id + '/chat', query: {room_id: id, company_name: companyName, shop_name: shopName, order_status: orderStatus}})
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
    },
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>