<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">会員検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会社名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="companyName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="email"></md-input>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="getUsers(null, null, null, true)">検索</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">会員一覧</h4>
          </md-card-header>
          <md-card-actions md-alignment="left" class="pT30">
            <md-button class="md-info md-lg" @click="getUsers(null, null, null, true)">全て</md-button>
            <md-button class="md-info md-lg mL20" @click="getUsers(null, 'member')">会員</md-button>
            <md-button class="md-info md-lg mL20" @click="getUsers(null, 'guest')">非会員</md-button>
            <md-button class="md-info md-lg mL20" @click="getUsers(null, 'unsubscribed')">退会</md-button>
            <md-button class="md-warning md-lg mL40" @click="getUsers(null, null, 'circle')"><md-icon>radio_button_unchecked</md-icon></md-button>
            <md-button class="md-warning md-lg mL20" @click="getUsers(null, null, 'triangle')"><md-icon>change_history</md-icon></md-button>
            <md-button class="md-warning md-lg mL20" @click="getUsers(null, null, 'square')"><md-icon>crop_square</md-icon></md-button>
            <md-button class="md-warning md-lg mL20" @click="getUsers(null, null, 'close')"><md-icon>close</md-icon></md-button>
          </md-card-actions>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">氏名</div>
                  <div class="tHead_th">会社名</div>
                  <div class="tHead_th">メールアドレス</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th">種別</div>
                  <div class="tHead_th">登録日</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.companyName }}</md-label></div>
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.email }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.status }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;"><md-icon>{{ item.kind }}</md-icon></md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.createdAt | timeFormat2 }}</md-label></div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click="jumpShopIndex(item.id)">
                      <md-icon>store</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-rose mg0"
                      @click="jumpOrderIndex(item.id)">
                      <md-icon>receipt_long</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getUsers">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../packs/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        lastName: '',
        firstName: '',
        companyName: '',
        email: '',
        status: '',
        kind: '',
        plain: '-------',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getUsers (page = 1, status = '', kind = '', is_reset = false) {
        if (status) {
          this.status = status
        }
        if (kind) {
          this.kind = kind
        }
        if (is_reset) {
          this.status = ''
          this.kind = ''
        }
         var condition = {
          page: page,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          last_name: this.lastName,
          first_name: this.firstName,
          status: this.status,
          kind: this.kind,
          company_name: this.companyName,
          email: this.email
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes['user-name'],
            companyName: res[i].attributes['company-name'],
            email: res[i].attributes.email,
            createdAt: res[i].attributes['created-at']
          }
          switch (res[i].attributes.status) {
            case 'guest':
              item.status = '非会員'
              break;
            case 'member':
              item.status = '会員'
              break;
            case 'unsubscribed':
              item.status = '退会'
              break;
            default:
              item.status = '識別不明'
          }
          switch (res[i].attributes.kind) {
            case 'circle':
              item.kind = '◯'
              break;
            case 'triangle':
              item.kind = '△'
              break;
            case 'square':
              item.kind = '□'
              break;
            case 'close':
              item.kind = '×'
              break;
            default:
              item.kind = ''
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      jumpDetail (item) {
        const url = '/admin/user/edit/'+ item.id
        window.open(url, '_blank')
      },
      jumpShopIndex (id) {
        const url = `/admin/shop_list/?user_id=${id}`
        window.open(url, '_blank')
      },
      jumpOrderIndex (id) {
        const url = `/admin/order_list/?user_id=${id}`
        window.open(url, '_blank')
      },
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getShop()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
