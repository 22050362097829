import { render, staticRenderFns } from "./SpecSearchSettings.vue?vue&type=template&id=340417bd&scoped=true&"
import script from "./SpecSearchSettings.vue?vue&type=script&lang=js&"
export * from "./SpecSearchSettings.vue?vue&type=script&lang=js&"
import style0 from "./SpecSearchSettings.vue?vue&type=style&index=0&id=340417bd&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340417bd",
  null
  
)

export default component.exports